export const AirtelAgentHeaders = [
  { label: "Agent_Number", key: "Agent_Number" },
  { label: "Agent_Name", key: "Agent_Name" },
  { label: "Inbound Calls", key: "inboundCalls" },
  { label: "Inbound Duration", key: "inboundDuration" },
  { label: "Outbound Calls", key: "outboundCalls" },
  { label: "Outbound Duration", key: "outboundDuration" },
  { label: "Total Calls", key: "totalCalls" },
  { label: "Total Duration", key: "totalDuration" },
];
