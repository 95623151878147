import React, { useEffect, useState } from "react";
import { getDialerReport } from "../../../api/dialer";
import moment from "moment";

function Dialer() {
  const [dialerData, setDialerData] = useState([]);
  const [totalAgents, setTotalAgents] = useState([]);
  const [incomingCalls, setIncomingCalls] = useState([]);
  const [grandTotalAgents, setGrandTotalAgents] = useState([]);

  const getDialerReports = async () => {
    try {
      const { data } = await getDialerReport();
      setDialerData(Object.values(data.data));
      setTotalAgents(data.totalUniqueAgents);
      setGrandTotalAgents(data.grandTotalAgents);
      setIncomingCalls(data.incomingData[0]);
    } catch (error) {
      console.log("error fetching data", error);
    }
  };

  useEffect(() => {
    getDialerReports();
  }, []);

  // Calculate totals for each team
  const teamTotals = dialerData.reduce(
    (acc, item) => {
      acc["Team A"] += item.Team_A || 0;
      acc["Team B"] += item.Team_B || 0;
      acc["Team France"] += item.Team_France || 0;
      return acc;
    },
    { "Team A": 0, "Team B": 0, "Team France": 0 }
  );

  // Calculate total interested for each team
  const totalInterestedTotals = dialerData
    .filter(
      (item) =>
        item.Disposition === "Interested" || item.Disposition === "300 Sec"
    )
    .reduce(
      (acc, item) => {
        acc["Team A"] += item.Team_A || 0;
        acc["Team B"] += item.Team_B || 0;
        acc["Team France"] += item.Team_France || 0;
        return acc;
      },
      { "Team A": 0, "Team B": 0, "Team France": 0 }
    );

  return (
    <div className="max-w-4xl mx-auto mt-8 my-10">
      <div className="overflow-x-auto">
        <table className="table-auto w-[800px] border-collapse border border-gray-400">
          <thead>
            <tr className="text-center">
              <th
                className="text-center pb-3 border-black border bg-[#92D14b] font-bold"
                colSpan={7}
              >
                {grandTotalAgents} Agents - AutoDialer Disposition Report{" "}
                {moment().format("DD-MM-YYYY")}
              </th>
            </tr>
            <tr>
              <th
                className="border pb-3 text-center border-black p-2 bg-[#92D14b]"
                key={"sr no"}
              >
                Sr no.
              </th>
              <th className="border pb-3 text-center border-black p-2 bg-[#92D14b]">
                DISPOSITION
              </th>
              <th className="border pb-3 text-center border-black p-2 pr-3 bg-[#92D14b]">
                TEAM A
              </th>
              <th className="border pb-3 text-center border-black p-2 bg-[#92D14b]">
                TEAM B
              </th>
              <th className="border pb-3 text-center border-black p-2 bg-[#92D14b]">
                TEAM FRANCE
              </th>
              <th className="border pb-3 text-center border-black p-2 bg-[#92D14b]">
                Grand Total
              </th>
            </tr>
          </thead>
          <tbody>
            <>
              {dialerData.map((item, index) => (
                <tr key={index}>
                  <td
                    className={`border text-center ${
                      item.Disposition === "Interested" ||
                      item.Disposition === null
                        ? "bg-[#92D14b]"
                        : ""
                    } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } 
                  font-bold border-black`}
                  >
                    {index + 1}
                  </td>

                  <td
                    className={`border border-black
                ${
                  item.Disposition === "Interested" || item.Disposition === null
                    ? "bg-[#92D14b]"
                    : ""
                } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } text-center font-bold  py-2`}
                  >
                    {item.Disposition
                      ? item.Disposition.toUpperCase()
                      : "GRAND TOTAL"}
                  </td>
                  <td
                    className={`border border-black
                ${
                  item.Disposition === "Interested" || item.Disposition === null
                    ? "bg-[#92D14b]"
                    : ""
                } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } text-center font-bold  py-2`}
                  >
                    {item.Team_A}
                  </td>
                  <td
                    className={`border border-black
                ${
                  item.Disposition === "Interested" || item.Disposition === null
                    ? "bg-[#92D14b]"
                    : ""
                } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } text-center font-bold  py-2`}
                  >
                    {item.Team_B}
                  </td>
                  <td
                    className={`border border-black
                ${
                  item.Disposition === "Interested" || item.Disposition === null
                    ? "bg-[#92D14b]"
                    : ""
                } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } text-center font-bold  py-2`}
                  >
                    {item.Team_France}
                  </td>
                  <td
                    className={`border border-black
                ${
                  item.Disposition === "Interested" || item.Disposition === null
                    ? "bg-[#92D14b]"
                    : ""
                } 
                  ${
                    item.Disposition === "not interested" ? "text-red-500" : ""
                  } text-center font-bold  py-2`}
                  >
                    {item.Total}
                  </td>
                </tr>
              ))}
            </>
            <tr className="bg-[#92D14b]">
              <td colSpan={2} className="border border-black p-2 font-bold">
                Grand Total
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {teamTotals["Team A"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {teamTotals["Team B"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {teamTotals["Team France"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {teamTotals["Team A"] +
                  teamTotals["Team B"] +
                  teamTotals["Team France"]}
              </td>
            </tr>
            <tr className="bg-[#FED767]">
              <td colSpan={2} className="border border-black p-2 font-bold">
                Total Interested
              </td>

              <td className="border border-black p-2 text-center font-bold">
                {totalInterestedTotals["Team A"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {totalInterestedTotals["Team B"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {totalInterestedTotals["Team France"]}
              </td>
              <td className="border border-black p-2 text-center font-bold">
                {totalInterestedTotals["Team A"] +
                  totalInterestedTotals["Team B"] +
                  totalInterestedTotals["Team France"]}
              </td>
            </tr>

            <tr className="bg-[#F5B182]">
              <td colSpan={2} className="border border-black p-2 font-bold">
                Total Agent
              </td>
              {totalAgents.map((item, index) => (
                <td
                  key={index}
                  className="border border-black p-2 text-center font-bold"
                >
                  {item.UniqueAgents}
                </td>
              ))}
              <td className="border border-black p-2 text-center font-bold">
                {grandTotalAgents}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="bg-[#F5B182] border-b border-l border-black p-2 font-bold w-[275px]"
              >
                Incoming Calls
              </td>
              <td colSpan={4} className="border-x border-black  w-[300px]">
                <tr>
                  <table>
                    <thead className="bg-[#92D14b]">
                      <th className="border-r border-b border-black p-2 font-bold px-7">
                        Agents
                      </th>
                      <th
                        className="border-r pb-3 pr-3 text-center border-black p-2 bg-[#92D14b]"
                        key={"sr no"}
                      >
                        Answerd
                      </th>
                      <th className="border-r pb-3 text-center border-black p-2 bg-[#92D14b]">
                        Missed
                      </th>
                      <th className="border-r pb-3 text-center border-black p-2 bg-[#92D14b]">
                        Total
                      </th>
                      <th className="border-r pb-3 text-center border-black p-2 bg-[#92D14b]">
                        Total Call Duration
                      </th>
                    </thead>
                    <tbody className="border-b border-black">
                      <tr>
                        <td className="border-r  border-black p-2 text-center font-bold">
                          {incomingCalls.total_unique_agents}
                        </td>
                        <td className="border border-black p-2 text-center font-bold">
                          {incomingCalls.total_answered_calls}
                        </td>
                        <td className="border border-black p-2 text-center font-bold">
                          {incomingCalls.total_missed_calls}
                        </td>
                        <td className="border border-black p-2 text-center font-bold">
                          {incomingCalls.total_answered_calls +
                            incomingCalls.total_missed_calls}
                        </td>
                        <td className="border border-black p-2 text-center font-bold">
                          {incomingCalls.total_call_duration}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dialer;
