import { Table } from "antd";
import moment from "moment-timezone";

const CanadaTable = ({ data }) => {
  const phoneNumberMap = {
    "+16473703550": "Egniol canada -01",
    "+16474900555": "Egniol canada",
    "+16479519115": "Vansh",
    "+16474933360": "Pankti",
    "+16475597941": "Tanay",
    "+15315354934": "Darko  Bernard",
  };

  const headerStyle = {
    // Orange background color
    color: "orange", // White text color
    textAlign: "center", // Center align text
    fontWeight: "semibold",
    fontSize: "16px",
    backgroundColor: "#1f2937",
  };

  const titleStyle = { ...headerStyle };
  // Define columns
  const columns =
    data.length > 0
      ? [
          {
            title: <span style={headerStyle}>Sr. No.</span>,
            dataIndex: "sr_no", // Replace "specific_key" with the key you want
            key: "sr_no", // Replace "specific_key" with the key you want
            width: 110,
            align: "center",
            className:
              "text-center font-semibold border border-slate-300 header-orange",
            onHeaderCell: () => ({
              style: headerStyle, // Apply header cell style
            }),
          },
          ...Object.keys(data[0])
            .filter(
              (key) =>
                key !== "call_id" &&
                key !== "conversationId" &&
                key !== "userId" &&
                key !== "phoneNumberId" &&
                key !== "call_from_name" &&
                key !== "sr_no" &&
                key !== "call_to_name"
            )
            .map((key) => {
              let column = {
                title: (
                  <span style={headerStyle}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </span>
                ),
                dataIndex: key,
                key: key,
                width: 110,
                align: "center",
                className:
                  "text-center font-semibold border border-slate-300 header-orange",
                onHeaderCell: () => ({
                  style: headerStyle, // Apply header cell style
                }),
                filters:
                  key === "call_from"
                    ? Object.keys(phoneNumberMap).map((phoneNumber) => ({
                        text: phoneNumberMap[phoneNumber],
                        value: phoneNumber,
                      }))
                    : null,
                onFilter: (value, record) => record[key] === value,
                render: (text, record, index) => {
                  if ((key === "call_from" || key === "call_to") && text) {
                    const name = phoneNumberMap[text] || text;
                    return <span>{name}</span>;
                  }
                  // Customize rendering for the date-time fields
                  if (
                    (key === "call_createdAt" ||
                      key === "call_completedAt" ||
                      key === "call_answeredAt") &&
                    text
                  ) {
                    return (
                      <span className="w-full">
                        <div>
                          Date:
                          {moment(text).tz("Asia/Kolkata").format("DD-MM-YYYY")}
                        </div>
                        <div>Time: {text?.split("T")[1]?.split(".")[0]}</div>
                      </span>
                    );
                  }
                  if (key === "call_answeredBy" && text) {
                    return (
                      <span className="w-full">
                        <div>
                          Date:
                          {moment(text).tz("Asia/Kolkata").format("DD-MM-YYYY")}
                        </div>
                        <div>
                          Time:{" "}
                          {moment(text).tz("Asia/Kolkata").format("HH:mm:ss")}
                        </div>
                      </span>
                    );
                  }
                  if (key === "duration") return data[index].duration;
                  return text;
                },
              };

              // Add filters for specific columns
              if (key === "call_status") {
                column.filters = [
                  { text: "Ringing", value: "ringing" },
                  { text: "Completed", value: "completed" },
                  { text: "Received", value: "received" },
                  { text: "Delivered", value: "delivered" },
                  { text: "In-Progress", value: "in-progress" },
                ];
                column.onFilter = (value, record) =>
                  record.call_status === value;
              }

              if (key === "call_direction") {
                column.filters = [
                  { text: "Incoming", value: "incoming" },
                  { text: "Outgoing", value: "outgoing" },
                ];
                column.onFilter = (value, record) =>
                  record.Call_direction === value;
              }

              if (key === "call_object") {
                column.filters = [
                  { text: "Call", value: "call" },
                  { text: "Message", value: "message" },
                ];
                column.onFilter = (value, record) =>
                  record.call_object === value;
              }

              if (key === "duration") {
                column.filters = [
                  { text: ">15", value: 15 },
                  { text: ">100", value: 100 },
                ];
                column.onFilter = (value, record) => record.duration > value;
              }

              return column;
            }),
        ]
      : [];
  return (
    <Table
      dataSource={data}
      columns={columns}
      className="w-full"
      scroll={{ x: true }}
    />
  );
};

export default CanadaTable;
