import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  DatePicker,
  Dropdown,
  Menu,
  Spin,
  ConfigProvider,
  Space,
  Skeleton,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  LoadingOutlined,
  CopyOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import {
  getDataDateWise,
  getDateWiseAggregatedData,
  getTodaysAggregatedRecords,
  refreshIVRData,
  sendMediaInWhatsapp,
} from "../../api/ivr";
import {
  formatDateRange,
  handleCopyImage,
  handleWhatsappSend,
} from "../../helper";
import Stats from "./Stats/Stats";
import BarChart from "./Stats/Graphs/BarChart";
import Loader from "../General/Loader";
import IvrForm from "./IvrForm";
import IvrHeader from "./IvrHeader";

const CustomDropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div className="relative inline-block text-left">
      <div className="flex">
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md  shadow-sm px-4 bg-white text-sm  text-black font-bold hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          {value}
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map((option, index) => (
              <div
                key={index}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"
                onClick={() => handleSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const IVR = () => {
  const { auth } = useParams();
  const [ivrData, setIvrData] = useState([]);
  const [ivrSummaryData, setIvrSummaryData] = useState([]);

  const [total, setTotal] = useState({});
  const [totalEllision, setTotalEllision] = useState({});

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Select Type");
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const [lodder, setLodder] = useState(false);

  let ivrObj = [
    {
      applcationId: "TACN5318",
      ivrdid: "92",
      channal: "30",
      service: "MSME Funding",
      source: "PT DATA",
    },
    {
      applcationId: "TACN5339",
      ivrdid: "92",
      channal: "630",
      service: "MSME Funding",
      source: "PT DATA",
    },
  ];
  const [ivrdidObj, setIvrdidObj] = useState(ivrObj);
  const [updatedIvrdidObj, setUpdatedIvrdidObj] = useState(ivrObj);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleServiceChange = (index, value) => {
    const updatedObj = [...updatedIvrdidObj];
    updatedObj[index].service = value;
    setUpdatedIvrdidObj(updatedObj);
    localStorage.setItem("ivrObj", JSON.stringify(updatedObj));
  };

  const handleSourceChange = (index, value) => {
    const updatedObj = [...updatedIvrdidObj];
    updatedObj[index].source = value;
    setUpdatedIvrdidObj(updatedObj);
    localStorage.setItem("ivrObj", JSON.stringify(updatedObj));
  };

  const captureAndDownload = () => {
    const element = document.getElementById("captureIVR"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `IVR-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleSearch = async () => {
    if (selectedOption === "All Record") {
      setLodder(true);
      const { data } = await getDateWiseAggregatedData(startDate, endDate);
      console.log(data, "kallly");

      calculateDayWiseTotalForAccountEllision(data.previousDateResults);

      calculateDayWiseTotalForAccount([
        {
          call_date: "2024-02-25T00:00:00.000Z",
          day_wise_data: data.summedData,
        },
      ]);
      setLodder(false);
    } else if (selectedOption === "Today") {
      setLodder(true);
      await getTodaysDataFromBk(startDate, endDate);
      setLodder(false);
    } else if (selectedOption === "Day wise") {
      setLodder(true);
      const { data } = await getDataDateWise(startDate, endDate);
      const jsonData = data?.map((item) => {
        return {
          call_date: item.call_date,
          day_wise_data: JSON.parse(item.day_wise_data),
        };
      });
      calculateDayWiseTotalForAccount(jsonData);
      setLodder(false);
    }
  };

  const calculateDayWiseTotalForAccountEllision = (data) => {
    let dayWiseTotal = {};

    data.forEach(({ call_date, day_wise_data }) => {
      // Initialize totalData for the current date
      let totalData = {
        dtmf_frequency: 0,
        total_missed_calls: 0,
        total_answered_calls: 0,
        average_time_period: 0,
        count: 0,
      };

      // Iterate through each hour in the day's data
      Object.values(day_wise_data).forEach((transactions) => {
        totalData.dtmf_frequency += transactions.dtmf_frequency || 0;
        totalData.total_missed_calls += transactions.total_missed_calls || 0;
        totalData.total_answered_calls +=
          transactions.total_answered_calls || 0;
        totalData.average_time_period += transactions.average_time_period || 0;
        totalData.count += 1;
      });
      // Calculate the average time period if count is greater than 0
      if (totalData.count > 0) {
        totalData.average_time_period /= totalData.count;
      }
      // Add the aggregated data for the day to dayWiseTotal
      dayWiseTotal[call_date] = { ...totalData };
    });
    console.log("Aggregated dayWiseTotal:", dayWiseTotal);

    let totalData = {
      dtmf_frequency: 0,
      total_missed_calls: 0,
      total_answered_calls: 0,
      average_time_period: 0,
      count: 0,
    };
    let dayWiseTotalEllision = {};
    Object.values(dayWiseTotal).forEach((transactions) => {
      totalData.dtmf_frequency += transactions.dtmf_frequency || 0;
      totalData.total_missed_calls += transactions.total_missed_calls || 0;
      totalData.total_answered_calls += transactions.total_answered_calls || 0;
      totalData.average_time_period += transactions.average_time_period || 0;
      totalData.count += 1;
    });
    dayWiseTotalEllision["2024-02-25T00:00:00.000Z"] = {
      Ellision: { ...totalData },
    };

    // Set the aggregated data
    setTotalEllision(dayWiseTotalEllision);
  };

  const calculateDayWiseTotalForAccount = (data) => {
    let dayWiseTotal = {};

    // Iterate through each day
    Object.entries(data)?.map(([call_date, hoursData]) => {
      let totalData = {
        dtmf_frequency: 0,
        total_missed_calls: 0,
        total_answered_calls: 0,
        average_time_period: 0,
      };
      // Iterate through each hour
      Object.keys(hoursData).map((hours) => {
        dayWiseTotal[data[call_date]?.call_date] = {};
        let tsx;
        Object.keys(hoursData[hours]).map((transactions) => {
          Object.keys(hoursData[hours][transactions])?.map((tx) => {
            tsx = tx;
            if (hoursData[hours][transactions][tsx]["dtmf_frequency"])
              totalData.dtmf_frequency += parseInt(
                hoursData[hours][transactions][tsx]["dtmf_frequency"]
              );
            if (hoursData[hours][transactions][tsx]["total_missed_calls"])
              totalData.total_missed_calls += parseInt(
                hoursData[hours][transactions][tsx]["total_missed_calls"]
              );
            if (hoursData[hours][transactions][tsx]["total_answered_calls"])
              totalData.total_answered_calls += parseInt(
                hoursData[hours][transactions][tsx]["total_answered_calls"]
              );
            if (
              hoursData[hours][transactions][tsx]["average_time_period"] !==
              null
            )
              totalData.average_time_period += parseInt(
                hoursData[hours][transactions][tsx]["average_time_period"]
              );

            if (dayWiseTotal[data[call_date].call_date][tsx]) {
              dayWiseTotal[data[call_date].call_date][tsx] = {
                dtmf_frequency:
                  dayWiseTotal[data[call_date].call_date][tsx].dtmf_frequency +
                  totalData.dtmf_frequency,
                total_missed_calls:
                  dayWiseTotal[data[call_date].call_date][tsx]
                    .total_missed_calls + totalData.total_missed_calls,
                total_answered_calls:
                  dayWiseTotal[data[call_date].call_date][tsx]
                    .total_answered_calls + totalData.total_answered_calls,
                average_time_period:
                  dayWiseTotal[data[call_date].call_date][tsx]
                    .average_time_period + totalData.average_time_period,
              };
            } else {
              dayWiseTotal[data[call_date].call_date][tsx] = {
                dtmf_frequency: totalData.dtmf_frequency,
                total_missed_calls: totalData.total_missed_calls,
                total_answered_calls: totalData.total_answered_calls,
                average_time_period: totalData.average_time_period,
              };
            }

            totalData.dtmf_frequency = 0;
            totalData.total_missed_calls = 0;
            totalData.total_answered_calls = 0;
            totalData.average_time_period = 0;
          });
        });
      });
    });
    setTotal(dayWiseTotal);
  };

  // console.log("total", total);
  // console.log("totalEllision", totalEllision);

  const calculateTotal = (transactions, property) => {
    // console.log(transactions, "transactions-aby-sale");
    let total = 0;
    for (const [, transactionData] of Object.entries(transactions)) {
      if (!isNaN(transactionData[property])) {
        total += parseFloat(transactionData[property]);
      }
    }
    return total;
  };

  const calculateTotalChannal = (transactions) => {
    let totalChannal = 0;
    // Get unique application IDs from transaction data
    const applicationIds = [
      ...new Set(
        Object.entries(transactions).map((transaction) => transaction[0])
      ),
    ];
    // Filter ivrdidObj based on application IDs present in transactions
    const filteredIvrdidObj = ivrdidObj.filter((item) =>
      applicationIds.includes(item.applcationId)
    );

    // Calculate total channel from filtered ivrdidObj
    filteredIvrdidObj.forEach((item) => {
      totalChannal += parseInt(item.channal);
    });

    return totalChannal;
  };

  const IVRDID_LOOKUP = ivrdidObj.reduce((acc, obj) => {
    acc[obj.applcationId] = obj.ivrdid;
    return acc;
  }, {});

  const CHANNAL_LOOKUP = ivrdidObj.reduce((acc, obj) => {
    acc[obj.applcationId] = obj.channal;
    return acc;
  }, {});

  const getTodaysDataFromBk = async (startDateTime, endDateTime) => {
    setLodder(true);

    const { data } = await getTodaysAggregatedRecords(
      startDateTime,
      endDateTime
    );
    // console.log(data, "data-he");

    const jsonData = data?.results?.map((item) => {
      return {
        call_date: item.call_date,
        day_wise_data: JSON.parse(item.day_wise_data),
      };
    });

    setIvrData(jsonData);
    setIvrSummaryData(JSON.parse(data?.results[0].day_wise_data));
    calculateDayWiseTotalForAccount(jsonData);
    setLodder(false);
  };

  useEffect(() => {
    if (auth === "harshil") {
      setTimeout(() => {
        handleWhatsappSend(tableRef);
      }, 6000);
    }
  }, [ivrData]);

  const handleRefreshData = async () => {
    setLoading(true);
    const data = await refreshIVRData();
    if (!data?.success) {
      toast.error(data.error);
    }
    await getTodaysDataFromBk(
      moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    setLoading(false);
  };

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("ivrObj"))) {
      setUpdatedIvrdidObj(JSON.parse(localStorage.getItem("ivrObj")));
    }
  }, []);

  const menu = (
    <Menu onClick={handleMenuClick} style={{ width: "150px" }}>
      <Menu.Item key="All Record" className="text-orange-500">
        All Record
      </Menu.Item>
      <Menu.Item key="Day wise" className="text-orange-500">
        Day wise
      </Menu.Item>
      <Menu.Item key="Today" className="text-orange-500">
        Today
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    getTodaysDataFromBk(
      moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
  }, []);

  const calculateEllisionTotal = (transactions, property) => {
    // console.log(transactions, "transactions-aby-sale");
    let total = 0;
    for (const [, transactionData] of Object.entries(transactions)) {
      if (!isNaN(transactionData[property])) {
        total += parseFloat(transactionData[property]);
      }
    }
    return total;
  };

  const calculateObjectSum = (obj) => {
    const sums = {};
    Object.values(obj).forEach((transactions) => {
      Object.entries(transactions).forEach(([_, transactionData]) => {
        Object.entries(transactionData).forEach(([key, value]) => {
          sums[key] = (sums[key] || 0) + value;
        });
      });
    });
    return sums;
  };
  const totalSum = calculateObjectSum(total);
  const totalEllisionSum = calculateObjectSum(totalEllision);

  let totalIndex = 0; // Counter for total

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">IVR Data</h1>

      <div className="flex flex-row my-5">
        <div>
          <span className="mx-2">From</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  placeholder="Select start date"
                  showTime
                  onChange={(_, datetime) => {
                    setStartDate(datetime);
                  }}
                />
              </Space>
            </ConfigProvider>
          </Space>
          <span className="mx-2">to</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  showTime
                  onChange={(_, datetime) => {
                    setEndDate(datetime);
                  }}
                  placeholder="Select end date"
                />
              </Space>
            </ConfigProvider>
          </Space>

          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="border-2 p-1 rounded-md mx-2 border-orange-500"
          >
            <a
              className="ant-dropdown-link text-orange-500"
              onClick={(e) => e.preventDefault()}
            >
              {selectedOption === "Select Type" ? (
                "Select Type"
              ) : selectedOption === "Today" ? (
                <>Today</>
              ) : selectedOption === "All Record" ? (
                <>All Record</>
              ) : (
                <>Day wise</>
              )}
            </a>
          </Dropdown>
          <button
            className=" border-2 border-orange-500 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <div className="ml-auto">
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={900}
          >
            <IvrHeader setIsModalVisible={setIsModalVisible} />
          </Modal>
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4 mr-4 hover:scale-105 duration-150
               transition"
            onClick={showModal}
          >
            IVR Form
          </button>
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4 mr-4 hover:scale-105 duration-150
               transition"
            onClick={() => {
              getTodaysDataFromBk(
                moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
          >
            Today's Records
          </button>
        </div>
      </div>

      {/* IVR TABLE */}
      <div className="overflow-x-auto relative flex flex-col items-center">
        <div className="flex mr-[57rem] pb-1 space-x-4">
          <button onClick={captureAndDownload} className="">
            <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
          </button>
          <button onClick={() => handleCopyImage(tableRef)} className="">
            <CopyOutlined style={{ fontSize: "20px", color: "#08c" }} />
          </button>
          <button
            onClick={async () =>
              await handleWhatsappSend(
                tableRef,
                `*IVR REPORT ${await formatDateRange(startDate, endDate)}*`
              )
            }
            className=""
          >
            <WhatsAppOutlined style={{ fontSize: "20px", color: "#08c" }} />
          </button>
        </div>
        <Spin spinning={lodder}>
          <table
            className="shadow-md text-sm text-left text-gray-500 w-[1000px]"
            id="captureIVR"
            ref={tableRef}
          >
            <thead className="text-xs text-black uppercase bg-[#FEFF00] border border-black ">
              <tr>
                <th
                  colSpan="11"
                  scope="col"
                  className="text-center font-bold text-lg pb-2"
                >
                  {selectedOption === "All Record" &&
                    `IVR REPORT ${moment(startDate).format(
                      "DD-MM-YYYY"
                    )} to ${moment(endDate).format("DD-MM-YYYY")}`}

                  {selectedOption === "Select Type" &&
                    ` IVR REPORT ${moment().format("DD-MM-YYYY")}`}
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="pb-2 text-center  border font-bold border-black"
                >
                  Sr. No.
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold text-center border border-black"
                >
                  Account
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  DTMF
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Answered Calls
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Missed Calls
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold text-center border border-black"
                >
                  Average Time (Sec)
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Channal
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Percentage
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Service
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  Source
                </th>
                <th
                  scope="col"
                  className="pb-2 font-bold  text-center border border-black"
                >
                  IVR DID
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(total).map(([call_date, transactions]) => (
                <React.Fragment key={call_date}>
                  {selectedOption === "Day wise" && (
                    <tr className="bg-[#FEFF00] border-b text-black ">
                      <td
                        colSpan="11"
                        scope="col"
                        className="text-center font-bold border border-black text-lg pb-2"
                      >
                        {`IVR REPORT ${moment(call_date).format("DD-MM-YYYY")}`}
                      </td>
                    </tr>
                  )}
                  {Object.entries(transactions).map(
                    ([transactionId, transactionData], innerIndex) =>
                      !isNaN(transactionData.average_time_period) && (
                        <tr key={transactionId} className="">
                          <td className=" pb-2 font-bold text-black  text-center border border-black">
                            {/* {innerIndex + 1} */}
                            {++totalIndex}
                          </td>
                          <td className=" pb-2 font-bold text-black  text-center border border-black">
                            {transactionId}
                          </td>
                          <td className=" pb-2 font-bold text-black  text-center border border-black">
                            {transactionData.dtmf_frequency}
                          </td>
                          <td className=" pb-2 font-bold text-black  text-center border border-black">
                            {transactionData.total_answered_calls}
                          </td>
                          <td className=" pb-2 font-bold text-black  text-center border border-black">
                            {transactionData.total_missed_calls}
                          </td>
                          <td className="text-center pb-2 font-bold text-black  border border-black">
                            {transactionData.average_time_period}
                          </td>
                          <td className=" text-center pb-2 font-bold text-black  border border-black">
                            {CHANNAL_LOOKUP[transactionId]}
                          </td>
                          <td className=" text-center pb-2 font-bold text-black  border border-black">
                            {(
                              (transactionData.dtmf_frequency /
                                transactionData.total_answered_calls) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                          <td className=" text-center pb-2 font-bold text-black border border-black">
                            <CustomDropdown
                              options={[
                                "MSME Funding",
                                "GST SAHAY",
                                "SEED FUND",
                              ]}
                              value={updatedIvrdidObj[innerIndex].service}
                              onChange={(e) =>
                                handleServiceChange(innerIndex, e)
                              }
                            />
                          </td>
                          <td className="  text-center  pb-2 font-bold text-black border border-black">
                            <CustomDropdown
                              options={["PT DATA", "GT DATA", "DIN"]}
                              value={updatedIvrdidObj[innerIndex].source}
                              onChange={(e) =>
                                handleSourceChange(innerIndex, e)
                              }
                            />
                          </td>
                          <td className=" text-center pb-2 font-bold text-black border border-black">
                            {IVRDID_LOOKUP[transactionId]}
                          </td>
                        </tr>
                      )
                  )}
                </React.Fragment>
              ))}
              {Object.entries(totalEllision).map(
                ([call_date, transactions]) => (
                  <React.Fragment key={call_date}>
                    {selectedOption === "Day wise" && (
                      <tr className="bg-[#FEFF00] border-b text-black ">
                        <td
                          colSpan="11"
                          scope="col"
                          className="text-center font-bold border border-black text-lg pb-2"
                        >
                          {`IVR REPORT ${moment(call_date).format(
                            "DD-MM-YYYY"
                          )}`}
                        </td>
                      </tr>
                    )}
                    {Object.entries(transactions).map(
                      ([transactionId, transactionData], innerIndex) =>
                        !isNaN(transactionData.average_time_period) && (
                          <tr key={transactionId} className="">
                            <td className=" pb-2 font-bold text-black  text-center border border-black">
                              {++totalIndex}
                            </td>
                            <td className=" pb-2 font-bold text-black  text-center border border-black">
                              {transactionId}
                            </td>
                            <td className=" pb-2 font-bold text-black  text-center border border-black">
                              {transactionData.dtmf_frequency}
                            </td>
                            <td className=" pb-2 font-bold text-black  text-center border border-black">
                              {transactionData.total_answered_calls}
                            </td>
                            <td className=" pb-2 font-bold text-black  text-center border border-black">
                              {transactionData.total_missed_calls}
                            </td>
                            <td className="text-center pb-2 font-bold text-black  border border-black">
                              {Math.round(transactionData.average_time_period)}
                            </td>
                            <td className=" text-center pb-2 font-bold text-black  border border-black">
                              {/* {CHANNAL_LOOKUP[transactionId]} */}
                              500
                            </td>
                            <td className=" text-center pb-2 font-bold text-black  border border-black">
                              {(
                                (transactionData.dtmf_frequency /
                                  transactionData.total_answered_calls) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                            <td className=" text-center pb-2 font-bold text-black border border-black">
                              <CustomDropdown
                                options={[
                                  "MSME Funding",
                                  "GST SAHAY",
                                  "SEED FUND",
                                ]}
                                value={updatedIvrdidObj[innerIndex].service}
                                onChange={(e) =>
                                  handleServiceChange(innerIndex, e)
                                }
                              />
                            </td>
                            <td className="  text-center  pb-2 font-bold text-black border border-black">
                              <CustomDropdown
                                options={["PT DATA", "GT DATA", "DIN"]}
                                value={updatedIvrdidObj[innerIndex].source}
                                onChange={(e) =>
                                  handleSourceChange(innerIndex, e)
                                }
                              />
                            </td>
                            <td className=" text-center pb-2 font-bold text-black border border-black">
                              {/* {IVRDID_LOOKUP[transactionId]} */}
                              94
                            </td>
                          </tr>
                        )
                    )}
                  </React.Fragment>
                )
              )}
              {Object.entries({ ...total, ...totalEllision }).map(
                ([call_date, transactions]) => (
                  <React.Fragment key={call_date}>
                    <tr className=" bg-[#FEFF00] border pb-2 border-black">
                      <td
                        colSpan="2"
                        className=" text-black border pb-2 border-black font-bold text-center"
                      >
                        Total
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {(!isNaN(totalSum["dtmf_frequency"])
                          ? totalSum["dtmf_frequency"]
                          : 0) +
                          (!isNaN(totalEllisionSum["dtmf_frequency"])
                            ? totalEllisionSum["dtmf_frequency"]
                            : 0)}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {(!isNaN(totalSum["total_answered_calls"])
                          ? totalSum["total_answered_calls"]
                          : 0) +
                          (!isNaN(totalEllisionSum["total_answered_calls"])
                            ? totalEllisionSum["total_answered_calls"]
                            : 0)}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {(!isNaN(totalSum["total_missed_calls"])
                          ? totalSum["total_missed_calls"]
                          : 0) +
                          (!isNaN(totalEllisionSum["total_missed_calls"])
                            ? totalEllisionSum["total_missed_calls"]
                            : 0)}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {/* Calculate average_time_period */}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {/* Calculate total channel */}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        {/* Calculate percentage */}
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        -
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        -
                      </td>
                      <td className=" text-black border pb-2 border-black font-bold text-center">
                        -
                      </td>
                    </tr>
                  </React.Fragment>
                )
              )}
              <tr
                colSpan="11"
                scope="col"
                className=" text-center font-bold  text-base  text-black uppercase bg-[#FEFF00] border border-black"
              >
                <td colSpan="11" className="pb-2">
                  !! हम बढ़ाएंगे आपका बिजनेस !!
                </td>
              </tr>
            </tbody>
          </table>
        </Spin>
      </div>

      <div
        className={`grid grid-cols-2 mt-10 ${
          ivrSummaryData.length === 0 ? "gap-4" : ""
        }`}
      >
        {ivrSummaryData.length !== 0 ? (
          <Stats summaryData={ivrSummaryData} />
        ) : (
          <Skeleton active />
        )}
        {ivrSummaryData.length !== 0 ? (
          <BarChart summaryData={ivrSummaryData} />
        ) : (
          <Skeleton active />
        )}
      </div>
    </div>
  );
};

export default IVR;

// ratio: dtmf / ans * 100
