import client from "./client";

export const userLogin = async (obj) => {
  try {
    const { data } = await client.post(`/auth/signin`, obj);
    return data;
  } catch (error) {
    return { error: error.message || error };
  }
};
