import React, { useEffect, useState, useRef } from "react";
import { getAgentCustomerData, getCollectionsData } from "../../api/airtel";

// Table Utils
import { Table, Input, Button, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const Agent_Customer_Talk_Time_Report = () => {
  const [agentCustomerData, setAgentCustomerData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [employeeDetailsMap, setEmployeeDetailsMap] = useState({});
  const [totalInboundCalls, setTotalInboundCalls] = useState(0);
  const [totalOutboundCalls, setTotalOutboundCalls] = useState(0);
  const [totalInboundDuration, setTotalInboundDuration] = useState(0);
  const [totalOutboundDuration, setTotalOutboundDuration] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);
  const [totalCallsDuration, setTotalCallsDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const processData = (dataArray) => {
    let framedData = {};
    let totalCallsData = 0;
    let totalCallsDurationData = 0;
    let totalInboundCallsData = 0;
    let totalOutboundCallsData = 0;
    let totalInboundCallsDurationData = 0;
    let totalOutboundCallsDurationData = 0;



    dataArray.map((data) => {
      if (!framedData[data.Agent_Number]) {
        framedData[data.Agent_Number] = {
          INBOUND: {
            totalCalls: 0,
            totalDuration: 0,
          },
          OUTBOUND: {
            totalCalls: 0,
            totalDuration: 0,
          },
        };
      }
      if (data.Call_Type === "INBOUND") {
        totalInboundCallsData += data.Call_Frequency;
        totalInboundCallsDurationData += data.Total_Call_Duration;
      }
      if (data.Call_Type === "OUTBOUND") {
        totalOutboundCallsData += data.Call_Frequency;
        totalOutboundCallsDurationData += data.Total_Call_Duration;
      }
      totalCallsData += data.Call_Frequency;
      totalCallsDurationData += data.Total_Call_Duration;


      //   Summary Calls Freq.
      setTotalCalls(totalCallsData);
      setTotalInboundCalls(totalInboundCallsData);
      setTotalOutboundCalls(totalOutboundCallsData);
      //   Summary DUration
      setTotalCallsDuration(secondsToHMS(totalCallsDurationData));
      setTotalInboundDuration(secondsToHMS(totalInboundCallsDurationData));
      setTotalOutboundDuration(secondsToHMS(totalOutboundCallsDurationData));
      framedData[data.Agent_Number][data.Call_Type].totalCalls +=
        data.Call_Frequency;
      framedData[data.Agent_Number][data.Call_Type].totalDuration +=
        data.Total_Call_Duration;
    });

    let framedDataArray = [];
    Object.keys(framedData).forEach((key) => {
      framedDataArray.push({
        Agent_Number: key,
        Agent_Name: employeeDetailsMap[key],
        Inbound_Calls: framedData[key].INBOUND.totalCalls,
        Inbound_Duration: framedData[key].INBOUND.totalDuration,
        Outbound_Calls: framedData[key].OUTBOUND.totalCalls,
        Outbound_Duration: framedData[key].OUTBOUND.totalDuration,
      });
    });
    setDataSource(framedDataArray);
  };

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const calculateCollections = (agentNumber) => {
    const employee = employeeCollection.find(
      (emp) => emp.NUMBER === agentNumber
    );
    return employee ? employee['Mar-24'] : 0;
  };

  const columns = [
    {
      title: "Agent Number",
      dataIndex: "Agent_Number",
      key: "Agent_Number",
      width: 200,
      ...getColumnSearchProps("mobileNumber"),
    },
    {
      title: "Agent Name",
      dataIndex: "Agent_Name",
      key: "Agent_Name",
      ...getColumnSearchProps("mobileNumber"),
    },
    {
      title: "Inbound_Calls",
      dataIndex: "Inbound_Calls",
      key: "Inbound_Calls",
      sorter: (a, b) => a.Inbound_Calls - b.Inbound_Calls,
    },
    {
      title: "Inbound_Duration",
      dataIndex: "Inbound_Duration",
      key: "Inbound_Duration",
      render: (text, record, index) => {
        const formatedDuration = secondsToHMS(record.Inbound_Duration);
        return (
          <div className="bg-transparent rounded-lg -ml-2  -mr-2">
            {formatedDuration}
          </div>
        );
      },
      sorter: (a, b) => a.Inbound_Duration - b.Inbound_Duration,
    },
    {
      title: "Outbound_Calls",
      dataIndex: "Outbound_Calls",
      key: "Outbound_Calls",
      sorter: (a, b) => a.Outbound_Calls - b.Outbound_Calls,
    },
    {
      title: "Outbound_Duration",
      dataIndex: "Outbound_Duration",
      key: "Outbound_Duration",
      render: (text, record, index) => {
        const formatedDuration = secondsToHMS(record.Outbound_Duration);
        return (
          <div className="bg-transparent rounded-lg -ml-2  -mr-2">
            {formatedDuration}
          </div>
        );
      },
      sorter: (a, b) => a.Outbound_Duration - b.Outbound_Duration,
    },
    {
      title: "Collections",
      dataIndex: "Collections",
      key: "Collections",
      sorter: (a, b) => calculateCollections(a.Agent_Number) - calculateCollections(b.Agent_Number),
      render: (_, record) => <span>

        {calculateCollections(record.Agent_Number)}  ₹
      </span>
    },
  ];

  const [employeeCollection, setEmployeeCollection] = useState([]);
  useEffect(() => {
    const fetchAgentCustomerData = async () => {
      setLoading(true);
      const { data } = await getAgentCustomerData();
      setAgentCustomerData(data.result);
      setEmployeeDetailsMap(data.employeeDetailsMap);
      setLoading(false);
    };

    const fetchEmployeeCollection = async () => {
      setLoading(true);
      const data = await getCollectionsData();
      setEmployeeCollection(data.result);
      setLoading(false);
    };

    if (agentCustomerData.length === 0) {
      fetchAgentCustomerData();
      fetchEmployeeCollection();
    }
  }, []);

  useEffect(() => {
    processData(agentCustomerData);
  }, [agentCustomerData]);

  return (
    <>
      <div className="mt-10 mb-10 mx-auto grid grid-cols-3 gap-4">
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800  font-semibold">Total Calls - {totalCalls}</p>
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800 font-semibold">Total Calls Duration - {totalCallsDuration}</p>
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800 font-semibold">Total Inbound Calls - {totalInboundCalls}</p>
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800 font-semibold">Total Inbound Calls Duration - {totalInboundDuration}</p>
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800  font-semibold">Total Outbound Calls - {totalOutboundCalls}</p>
        <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800 font-semibold">Total Outbound Calls Duration - {totalOutboundDuration}</p>
        {/* <p className="py-3 px-6 text-orange-500 uppercase bg-zinc-800 font-semibold">Total Collections - {collection} ₹</p> */}
      </div >
      <div>
        {!loading ? (
          <Table columns={columns} dataSource={dataSource} />
        ) : (
          <div className="flex justify-center items-center min-h-screen min-w-screen">
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

export default Agent_Customer_Talk_Time_Report;