import client from "./client";

export const pythonReport = async (obj) => {
  try {
    const { data } = await client.post("/pythonReport/incomeTaxReport", {
      data: obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const dialerReport = async (obj) => {
  try {
    const { data } = await client.post("/pythonReport/dialerReport", {
      data: obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const IvrReport = async (obj) => {
  try {
    const { data } = await client.post("/pythonReport/ivrReport", {
      data: obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const menuReport = async (obj) => {
  try {
    const { data } = await client.post("/pythonReport/menuReport", {
      data: obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const TypewiseReport = async (obj) => {
  try {
    const { data } = await client.post("/pythonReport/typewiseReport", {
      data: obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
