import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import {
  createCronjobs,
  getAccountDetails,
  sendWhatsappBc,
} from "../../../api/whatsapp";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import toast from "react-hot-toast";

const Manual = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [language, setLanguage] = useState("");
  const [service, setService] = useState("");
  const [account, setAccount] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [source, setSource] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [mappedSources, setMappedSources] = useState([]);
  const [loading, setLoading] = useState(false);

  const mappedKeys = {
    ["contactNumber"]: ["Mobile"],
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSource = (event) => {
    setSource(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  function findAuthToken(accountname) {
    for (const item of accountDetails) {
      const { account } = item;
      if (account.accountname === accountname) {
        return account.auth_token;
      }
    }
    return null;
  }

  const handleAccountChange = (event) => {
    setAccount(event.target.value);
    setAuthToken(findAuthToken(event.target.value));
  };

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null;
  }

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray);
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let headers = Object.keys(results.data[0]);
          let contactNumberKey = headers.filter((key) => getKeyForElement(key));
          let parameterKeys = headers.filter((key) => key !== "Mobile");

          results.data.forEach((row) => {
            let obj = {
              contactNumber: row[contactNumberKey],
              parameters: [],
            };

            parameterKeys.forEach((key) => {
              obj.parameters.push(row[key]);
            });

            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = await sendWhatsappBc({
      source: source,
      phoneNumbers: numbers,
      templateName: selectedOption,
      account: account,
      lang: language,
      service: service,
      authToken,
    });
    setLoading(false);

    // const data = await createCronjobs({
    //   cronPattern: "0 */1 10-17 * * 1-6",
    //   templateName: selectedOption,
    //   account: account,
    //   source: source,
    //   lang: language,
    //   service: service,
    //   slabSize: "5000",
    //   phoneNumbers: numbers,
    // });

    if (data.success) {
      toast.success(data.message);
      setNumbers("");
      setAccount("");
      setLanguage("");
      setSelectedOption("");
      setSelectedFiles([]);
    } else {
      toast.error(data.error);
    }
  };

  const processData = () => {
    let report = [];
    dataSource.forEach((row) => {
      if (row.contactNumber.length === 12) {
        report.push({
          mobileNumber: row.contactNumber,
          parameters: row.parameters,
        });
      } else if (row.contactNumber.length === 10) {
        report.push({
          mobileNumber: "91" + row.contactNumber,
          parameters: row.parameters,
        });
      }
    });
    setNumbers(report);
  };

  const accountDetail = async () => {
    try {
      const response = await getAccountDetails();

      if (response) {
        setAccountDetails(response.responses);

        const uniqueSources = [
          ...new Set(response.responses.map((account) => account.source)),
        ];

        setMappedSources(uniqueSources);
      } else {
        toast.error("Error fetching data");
      }
    } catch (err) {
      console.log("Data fetching error", err);
    }
  };

  useEffect(() => {
    if (dataSource.length !== 0) {
      processData();
    }
  }, [dataSource]);

  useEffect(() => {
    accountDetail();
  }, []);

  return (
    <div className="container mx-auto my-8">
      <div className="flex space-x-4 items-center">
        <div>
          <label
            htmlFor="source-dropdown"
            className="block font-semibold text-gray-700"
          >
            Choose Source:
          </label>
          <select
            id="source-dropdown"
            value={source}
            onChange={handleSource}
            className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
          >
            <option value="">Select</option>
            {mappedSources.map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
          </select>
        </div>
        {source && (
          <div>
            <label
              htmlFor="account-dropdown"
              className="block font-semibold text-gray-700"
            >
              Choose Account:
            </label>
            <select
              id="account-dropdown"
              value={account}
              onChange={handleAccountChange}
              className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
            >
              <option value="">Select</option>
              {accountDetails
                .filter((accountDetail) => accountDetail.source === source)
                .map((accountDetail, index) => (
                  <option key={index} value={accountDetail.account.accountname}>
                    {accountDetail.account.accountname}
                  </option>
                ))}
            </select>
          </div>
        )}
        {account && (
          <div>
            <label
              htmlFor="template-dropdown"
              className="block font-semibold text-gray-700"
            >
              Choose Template:
            </label>
            <select
              id="template-dropdown"
              value={selectedOption}
              onChange={handleChange}
              className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
            >
              <option value="">Select</option>
              {accountDetails
                .filter(
                  (accountDetails) =>
                    accountDetails.account.accountname === account
                )
                .map((accountDetails, index) => {
                  return (
                    <>
                      {accountDetails.account.templates.map((template) => {
                        return (
                          <option key={index} value={template.tempName}>
                            {template.tempName}
                          </option>
                        );
                      })}
                    </>
                  );
                })}
            </select>
          </div>
        )}
        {selectedOption && (
          <div>
            <label
              htmlFor="language-dropdown"
              className="block font-semibold text-gray-700"
            >
              Choose Language:
            </label>
            <select
              id="template-dropdown"
              value={language}
              onChange={handleLanguageChange}
              className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
            >
              <option value="">Select</option>
              {accountDetails
                .filter(
                  (accountDetails) =>
                    accountDetails.account.accountname === account
                )
                .map((accountDetails, index) => {
                  return (
                    <>
                      {[
                        ...new Set(
                          accountDetails.account.templates.map(
                            (template) => template.lang
                          )
                        ),
                      ].map((lang, index) => (
                        <option key={index} value={lang}>
                          {lang}
                        </option>
                      ))}
                    </>
                  );
                })}
            </select>
          </div>
        )}
        {language && (
          <div>
            <label
              htmlFor="language-dropdown"
              className="block font-semibold text-gray-700"
            >
              Choose Service:
            </label>
            <select
              id="template-dropdown"
              value={service}
              onChange={handleServiceChange}
              className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
            >
              <option value="">Select</option>
              <option value="MSME Funding">MSME Funding</option>
              <option value="GST sahay">GST sahay</option>
              <option value="SEED FUND">SEED FUND</option>
              <option value="Instragram Marketing">Instragram Marketing</option>
            </select>
          </div>
        )}
      </div>
      <div className="mt-8">
        {source && account && selectedOption && language && service && (
          <form className="flex flex-col w-fit gap-x-3">
            <h1 className="font-semibold">Excel to CSV Converter : </h1>
            <input type="file" multiple onChange={handleFileInputChange} />
          </form>
        )}
        <div>
          {selectedFiles.length > 0 && (
            <div className="">
              <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
              <ul>
                {selectedFiles.map((file, index) => (
                  <li key={index} className="flex items-center mb-1">
                    <span className="mr-2">{file.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div>
          {selectedFiles.length > 0 && (
            <div
              className=" border-black cursor-pointer transition duration-150 hover:scale-105 rounded-sm inline
            font-semibold border-2 mt-5 p-1 text-cente"
            >
              {/* {loading === "false" ? ( */}
              <button onClick={handleSubmit} className="r">
                Submit
              </button>
              {/* // ) : (
              //   <Spin
              //     indicator={
              //       <LoadingOutlined
              //         style={{
              //           fontSize: 24,
              //         }}
              //         spin
              //       />
              //     }
              //   />
              // )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Manual;
