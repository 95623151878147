import React from "react";

const IncomingCallToActionBroadcastTable = ({ otherOutcomes }) => {
  return (
    <div>
      <table
        className="table-auto  w-full mx-auto  text-sm font-bold  rounded-md"
        id="capture"
      >
        <thead className="text-black uppercase font-bold  bg-[#FEFF00]">
          <th className="border border-black pb-2 font-bold text-center">
            TEMPLATE
          </th>
          <th className="border border-black pb-2 font-bold text-center">
            API REPLY
          </th>
          <th className="border border-black pb-2 font-bold text-center">
            TOTAL ANSWERED INCOMING CALLS
          </th>
          <th className="border border-black pb-2 font-bold text-center">
            TOTAL MISSED INCOMING CALLS
          </th>
          <th className="border border-black pb-2 font-bold text-center">
            300 SEC.
          </th>
        </thead>
        <tbody>
          {otherOutcomes &&
            Object.keys(otherOutcomes.templates).map((key) => {
              return (
                <tr>
                  <td className="border pb-2 border-black text-center">
                    {key}
                  </td>
                  <td className="border pb-2 border-black text-center">
                    {otherOutcomes.templates[key].totalApiReply
                      ? otherOutcomes.templates[key].totalApiReply
                      : 0}
                  </td>
                  <td className="border pb-2 border-black text-center">
                    {otherOutcomes.templates[key]?.totalAnsweredCalls}
                  </td>
                  <td className="border pb-2 border-black text-center">
                    {otherOutcomes.templates[key]?.totalMissedCalls}
                  </td>
                  <td className="border pb-2 border-black text-center">
                    {
                      otherOutcomes.templates[key]
                        ?.totalClientWithMoreThan300SecondsDuration
                    }
                  </td>
                </tr>
              );
            })}
          <tr>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              GRAND TOTAL
            </td>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              {otherOutcomes?.["TOTAL"]?.totalApiReply}
            </td>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              {otherOutcomes["TOTAL"]?.totalAnsweredCalls}
            </td>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              {otherOutcomes["TOTAL"]?.totalMissedCalls}
            </td>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              {
                otherOutcomes["TOTAL"]
                  ?.totalClientWithMoreThan300SecondsDuration
              }
            </td>
          </tr>
          <tr>
            <td className="border bg-orange-300 pb-2 border-black text-center">
              UNIQUE CLIENTS
            </td>
            <td
              colSpan={2}
              className="border bg-orange-300 pb-2 border-black text-center"
            >
              {otherOutcomes["TOTAL"]?.totalUniqueClients}
            </td>
            <td
              colSpan={2}
              className="border bg-orange-300 pb-2 border-black text-center"
            >
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IncomingCallToActionBroadcastTable;
