import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const LineChart = (props) => {
  const { chartData, options } = props;

  return <div>{chartData && <Line data={chartData} options={options} />}</div>;
};

export default LineChart;
