import React, { useState, useEffect } from "react";
import { getFalseDNDInputData } from "../../../api/airtel";
import { Collapse, DatePicker, Spin } from "antd";
import moment from "moment";
const { Panel } = Collapse;

const EmployeeList = ({
  dndDailerCallList,
  dndAirtelCallList,
  dndCustomerData,
}) => {
  function secondsToMmSs(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    // Add leading zeros if needed
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return minutes + ":" + remainingSeconds;
  }
  // format '2024-03-05T00:00:00.000Z' to "DD/MM/YYYY" format
  function formatDateToDDMMYYYY(dateString) {
    // Create a new Date object from the string
    const date = new Date(dateString);

    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  return (
    <div className="grid grid-cols-3 gap-5">
      <table class="table-auto h-fit border-collapse border border-gray-300 bg-white shadow-md">
        <thead>
          <tr class="bg-gray-100">
            <th colSpan={4} class="px-4 py-2  border border-gray-300">
              CALLS DATA DONE USING DAILER
            </th>
          </tr>
          <tr class="bg-gray-100">
            <th class="px-4 py-2  border border-gray-300">Employee Name</th>
            <th class="px-4 py-2  border border-gray-300">Date</th>
            <th class="px-4 py-2  border border-gray-300">Time</th>
            <th class="px-4 py-2  border border-gray-300">Duration</th>
          </tr>
        </thead>
        <tbody>
          {dndDailerCallList.map((data) => {
            return (
              <tr className="text-center">
                <td class="border px-4 py-2">{data.Agent_Name}</td>
                <td class="border px-4 py-2">
                  {formatDateToDDMMYYYY(data.Date)}
                </td>
                <td class="border px-4 py-2">{data.Time}</td>
                <td class="border px-4 py-2">{secondsToMmSs(data.duration)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table class="table-auto h-fit border-collapse border border-gray-300 bg-white shadow-md">
        <thead>
          <tr class="bg-gray-100 border">
            <th colSpan={5} class="px-4 py-2 border border-gray-300">
              CALLS DONE FROM AIRTEL WITHIN THREE DAYS AFTER{" "}
              <span className="text-red-500 font-bold">DND</span> DISPO Calls
              Done From Airtel WithIn THREE Days after DND Dispo
            </th>
          </tr>
          <tr class="bg-gray-100 border">
            <th class="px-4 py-2 border border-gray-300">Employee Name</th>
            <th class="px-4 py-2 border border-gray-300">Date</th>
            <th class="px-4 py-2 border border-gray-300">Time</th>
            <th class="px-4 py-2 border border-gray-300">Status</th>
            <th class="px-4 py-2 border border-gray-300">Duration</th>
          </tr>
        </thead>
        <tbody>
          {dndAirtelCallList.map((data) => {
            return (
              <tr
                className={`${
                  data.status === "Answered" ? "bg-green-100" : ""
                } text-center`}
              >
                <td class="border px-4 py-2">{data.Agent_Name}</td>
                <td class="border px-4 py-2">{data.Date}</td>
                <td class="border px-4 py-2">{data.Time}</td>
                <td class="border px-4 py-2">{data.status}</td>
                <td class="border px-4 py-2">{data.duration}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table className="table-auto h-fit border-collapse border border-gray-300 bg-white shadow-md">
        <thead>
          <tr className="bg-gray-100 border">
            <th colSpan={5} className="px-4 py-2 border border-gray-300">
              Collections
            </th>
            <th colSpan={5} className="px-4 py-2 border border-gray-300">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {dndCustomerData ? (
            <>
              <tr className="text-center">
                <td className="border px-4 py-2" colSpan={5}>
                  {dndCustomerData.Received_Amt}
                </td>
                <td className="border px-4 py-2" colSpan={5}>
                  {moment(dndCustomerData.Received_Date).format("DD-MM-YYYY")}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td colSpan={10} className="text-center border px-4 py-2">
                  No data available
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

const DndCustomerDetail = ({ dndCustomerData, number }) => {
  return (
    <>
      {dndCustomerData ? (
        <div
          className={`p-3 grid grid-cols-3 text-center font-semibold text-md ${
            dndCustomerData?.Received_Amt === 0 ? "bg-white" : "bg-green-500"
          }`}
        >
          <p>{dndCustomerData?.name}</p>
          <p>{dndCustomerData?.source}</p>
          <p>{dndCustomerData?.mobileNumber}</p>
        </div>
      ) : (
        <div className="grid grid-cols-3 text-center font-semibold text-md bg-red">
          <p>{"UNKNOWN"}</p>
          <p>{"UNKNOWN"}</p>
          <p>{number}</p>
        </div>
      )}
    </>
  );
};

const DNDFalseInput = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const createComapanyDetailMap = (companyDetails) => {
    let companyDetailsMap = {};
    companyDetails.forEach((obj) => {
      companyDetailsMap[obj.mobileNumber.slice(-10)] = {
        name: obj.companyName,
        source: obj.source,
        mobileNumber: obj.mobileNumber,
        Received_Amt: obj.Received_Amt,
        Received_Date: obj.Received_Date,
      };
    });
    return companyDetailsMap;
  };

  const createEmployeeDetailMap = (employeeDetails) => {
    let employeeDetailsMap = {};
    employeeDetails.forEach((obj) => {
      employeeDetailsMap[obj.mobileNumber.slice(-10)] = obj.Name;
    });
    return employeeDetailsMap;
  };

  const processData = (dndCustomerMap, companyDetails, employeeDetails) => {
    const dataSource = Object.keys(dndCustomerMap).map((dndCustomerNumber) => {
      const dndDailerList = [
        {
          ...dndCustomerMap[dndCustomerNumber].dailerCall,
        },
      ];
      const dndAirtelList = dndCustomerMap[dndCustomerNumber].airtelCall.map(
        (data) => ({
          ...data,
          Agent_Name: employeeDetails[data.Agent_Number.slice(-10)],
        })
      );

      const dndCustomerData = companyDetails[dndCustomerNumber.slice(-10)];
      return {
        key: dndCustomerNumber,
        dndCustomerData,
        dndDailerList,
        dndAirtelList,
      };
    });
    setDataSource(dataSource);
  };
  const fetchFalseDNDInputData = async (dateString) => {
    setLoading(true);
    try {
      const data = await getFalseDNDInputData(dateString);
      if (data && data.result) {
        const { dndCustomerMap, companyDetails, employeeDetails } = data.result;
        const companyDetailsMap = createComapanyDetailMap(companyDetails);
        const employeeDetailsMap = createEmployeeDetailMap(employeeDetails);
        processData(dndCustomerMap, companyDetailsMap, employeeDetailsMap);
      } else {
        console.error("API response does not contain expected data:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };
  const handleDateChange = (dateString) => {
    if (!dateString) return alert("Please select a date");
    fetchFalseDNDInputData(dateString);
  };

  return (
    <div className="p-10 text-center mx-auto">
      <p className="mb-10 text-3xl text-center">Calling Even After DND Dispo</p>
      <p className="text-green-500 font-semibold text-lg text-center">
        ------------ READY COMPLETELY ------------
      </p>
      <DatePicker
        onChange={(date, dateString) => handleDateChange(dateString)}
        format={"YYYY-MM-DD"}
        placeholder="Select date"
        className="my-5"
      />
      {loading ? (
        <Spin />
      ) : (
        <Collapse defaultActiveKey={["0"]} accordion>
          {dataSource.map((data, index) => (
            <Panel
              key={index}
              header={
                <DndCustomerDetail
                  number={data.key}
                  dndCustomerData={data.dndCustomerData}
                />
              }
            >
              <EmployeeList
                dndDailerCallList={data.dndDailerList}
                dndAirtelCallList={data.dndAirtelList}
                dndCustomerData={data.dndCustomerData}
              />
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default DNDFalseInput;
