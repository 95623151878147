import React, { useRef } from "react";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import { handleCopyImage } from "../../../helper";

const ReportTable = ({ data }) => {
  const tableRef = useRef(null);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const captureAndDownload = () => {
    const element = document.getElementById("captureCallHippo"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `callHippo-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  let totalCalls = 0;
  let totalAnsweredCalls = 0;
  let totalMissedCalls = 0;
  let totalAnsCallDuration = 0;
  let totalAverageMin = 0;

  return (
    <div className="overflow-x-auto relative flex flex-col items-center  my-5">
      <div className="flex mr-[53rem] space-x-4">
        <button onClick={captureAndDownload}>
          <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button onClick={() => handleCopyImage(tableRef)} className="">
          <CopyOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
      </div>
      <table
        className="table-auto border-collapse border text-sm text-left border-black w-[900px] shadow-md"
        id="captureCallHippo"
        ref={tableRef}
      >
        <thead className="text-black border border-black uppercase bg-[#FEFF00]">
          <tr>
            <th className="text-black pb-2 border border-black  text-center">
              Sr. No
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              Name
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              Total Calls
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              No Answered Calls
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              Answered Calls
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              Call Duration
            </th>
            <th className="text-black pb-2 border border-black  text-center">
              Average Duration
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            if (item.callerName) {
              totalCalls += item.totalCalls;
              totalAnsweredCalls += item.answeredCalls;
              totalMissedCalls += item.noAnswer;
              totalAnsCallDuration += item.totalAnsweredDuration;
              totalAverageMin += item.avgAnswerDuration;
            }
            return (
              <>
                {item.callerName && (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td className="text-black pb-2 border border-black  text-center">
                        {index + 1}
                      </td>
                      <td className="text-black pb-2 border border-black  text-center">
                        {item.callerName?.charAt(0)?.toUpperCase() +
                          item.callerName?.slice(1)}
                      </td>
                      <td className="text-black pb-2 border border-black  text-center">
                        {item.totalCalls}
                      </td>
                      <td className="text-black pb-2 border border-black  text-center">
                        {item.noAnswer}
                      </td>
                      <td className="text-black pb-2 border border-black  text-center">
                        {item.answeredCalls}
                      </td>

                      <td className="text-black pb-2 border border-black  text-center">
                        {formatTime(item.totalAnsweredDuration)}
                      </td>
                      <td className="text-black pb-2 border border-black  text-center">
                        {formatTime(Math.round(item.avgAnswerDuration))}
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </>
            );
          })}

          <tr className="bg-[#FEFF00]">
            <td
              colSpan="2"
              className="text-black pb-2 border border-black font-semibold text-center"
            >
              Total
            </td>

            <td className="text-black pb-2 border border-black font-semibold text-center">
              {totalCalls}
            </td>
            <td className="text-black pb-2 border border-black font-semibold text-center">
              {totalMissedCalls}
            </td>
            <td className="text-black pb-2 border border-black font-semibold text-center">
              {totalAnsweredCalls}
            </td>
            <td className="text-black pb-2 border border-black font-semibold text-center">
              {formatTime(totalAnsCallDuration)}
            </td>
            <td className="text-black pb-2 border border-black font-semibold text-center">
              {formatTime(
                Math.round(totalAnsCallDuration / totalAnsweredCalls)
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
