import React, { useEffect, useState, useRef } from "react";
import {
  getClientCalls,
  getEmployeeCalls,
  getEmployeesData,
  getUnkownCalls,
  getCollectionData,
  getCollectionDataAll,
} from "../../api/airtel";

import { Table, Input, Button, Space, Spin, Modal, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import moment from "moment";

// PNG Utils
import html2canvas from "html2canvas";

// CSV Utils
import { CSVLink } from "react-csv";

import { DailyCallsHeader } from "./CSVHeaders/DailyCallsReport";
import CallingReport from "./CallingReport";
import Collections from "./Collections";

const Employee = () => {
  const [callsDataInternal, setCallsDataInternal] = useState([]);
  const [callsDataExternal, setCallsDataExternal] = useState([]);
  const [callsDataUnknown, setCallsDataUnknown] = useState([]);
  const [callsDataCollection, setCallsDataCollection] = useState([]);
  const [collectionData, setCollectionData] = useState(null);
  const [collectionDataAll, setCollectionDataAll] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [modalId, setModalId] = useState("1");
  const [modalNum, setModalNum] = useState("7874866543");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [totalAnswered, setTotalAnswered] = useState({
    internalCalls: 0,
    externalCalls: 0,
    unknownCalls: 0,
    didCalls: 0,
    totalCalls: 0,
    internalDuration: 0,
    externalDuration: 0,
    unknownDuration: 0,
    didDuration: 0,
    totalDuration: 0,
  });
  const [totalMissed, setTotalMissed] = useState({
    internalCalls: 0,
    externalCalls: 0,
    unknownCalls: 0,
    didCalls: 0,
    totalCalls: 0,
    internalDuration: 0,
    externalDuration: 0,
    unknownDuration: 0,
    didDuration: 0,
    totalDuration: 0,
  });
  const [day, setDay] = useState(null);

  const DIDNumbers = [
    "07969323000",
    "09240230240",
    "09240201800",
    "07969323079",
  ];

  function isNumberIsDIDNo(element) {
    return DIDNumbers.includes(element);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const addAgentNames = async (frameDataArray) => {
    const { employeesMap } = await getEmployeesData();
    for (let i = 0; i < frameDataArray.length; i++) {
      let agentName;
      if (
        frameDataArray[i]?.Agent_Number &&
        employeesMap[frameDataArray[i]?.Agent_Number.slice(-10)] !== undefined
      ) {
        agentName = employeesMap[frameDataArray[i].Agent_Number.slice(-10)];
      } else {
        // Handle the case where employeesMap does not contain a value for frameDataArray[i]?.Agent_Number
        agentName = "Unknown"; // or any other default value or handling
      }
      frameDataArray[i]["Agent_Name"] = agentName;
    }
    return frameDataArray;
  };

  const getUnkownCallsData = async () => {
    const data = await getUnkownCalls();
    return data.success ? data : null;
  };

  function formatDuration(totalDurationInMinutes) {
    // Convert minutes to hours, minutes, and seconds
    var hours = Math.floor(totalDurationInMinutes / 60);
    var minutes = Math.floor(totalDurationInMinutes % 60);
    var seconds = Math.floor((totalDurationInMinutes * 60) % 60);

    // Format the result as hh:mm:ss
    var formattedDuration =
      ("0" + hours).slice(-2) +
      ":" +
      ("0" + minutes).slice(-2) +
      ":" +
      ("0" + seconds).slice(-2);

    return formattedDuration;
  }

  // Utils for downloadin the CSV
  const captureAndDownload = () => {
    const element = document.getElementById("Dialer_Report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Dialer-Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // Format the Data for the CSV
  function formatDataForCsv(dataSource) {
    let csvData = [];
    dataSource?.forEach((data) => {
      const totalDuration = parseFloat(
        // (data.external.INBOUND.Total_Call_Duration || 0) / 60 +
        // (data.external.OUTBOUND.Total_Call_Duration || 0) / 60 +
        ((data.did.INBOUND.Total_Call_Duration || 0) / 60).toFixed(2)
      );

      const callFrequencySum = parseInt(
        // (data.external.OUTBOUND.Call_Frequency || 0) +
        // (data.external.INBOUND.Call_Frequency || 0) +
        data.did.INBOUND.Call_Frequency || 0
      );
      const Average_Min_Per_Call = Math.floor(
        parseFloat(totalDuration || 0) / callFrequencySum
      );
      if (callFrequencySum !== 0 && totalDuration !== 0) {
        csvData.push({
          header: "AGENT_DATA",
          Agent_Name: data.Agent_Name,
          Agent_Number: data.Agent_Number,
          // Did_Calls: data.did.INBOUND.Call_Frequency,
          // Did_Duration: parseFloat(
          //   (data.did.INBOUND.Total_Call_Duration / 60).toFixed(2)
          // ),
          // Inbound_Client_Calls: data.external.INBOUND.Call_Frequency,
          // Inbound_Client_Duration: parseFloat(
          //   (data.external.INBOUND.Total_Call_Duration / 60).toFixed(2)
          // ),
          // Outbound_Client_Calls: data.external.OUTBOUND.Call_Frequency,
          // Outbound_Client_Duration: parseFloat(
          //   (data.external.OUTBOUND.Total_Call_Duration / 60).toFixed(2)
          // ),
          Total_Awnsered: callFrequencySum,
          Total_Duration: formatDuration(totalDuration),
          Average_Min_Per_Call: isNaN(Average_Min_Per_Call)
            ? 0
            : Average_Min_Per_Call,
        });
      }
    });
    // Sorting function
    csvData?.sort((a, b) => {
      return b.Average_Min_Per_Call - a.Average_Min_Per_Call;
    });
    return csvData;
  }

  function downloadCsv() {
    showModal();
    // document.getElementById("csv-link").click();
  }

  function downloadCollection() {
    showModal1();
  }

  const addCollectionData = async (framedData, array, date) => {
    array.filter((e) => e.Date === date || date === '').forEach((data) => {
      if (!framedData[data.Agent_Number]) {
        framedData[data.Agent_Number] = {
          totalCalls: 0,
          totalDuration: 0,
          Total_Received_Amount: 0,
          internal: {
            INBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            },
            OUTBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            }
          },
          external: {
            INBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            },
            OUTBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            }
          },
          unknown: {
            INBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            },
            OUTBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            }
          },
          did: {
            INBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            },
            OUTBOUND: {
              Call_Frequency: 0,
              Total_Call_Duration: 0
            }
          },
        }
      }
      framedData[data.Agent_Number].Total_Received_Amount += (data.Total_Received_Amount || 0);

    })

  }

  function frameData(type, dataArray, framedData, totalAns, totalMissed) {
    let result = framedData;
    dataArray.forEach((data) => {
      const Total_Received_Amount = data.Total_Received_Amount;
      const agentNumber = data.Agent_Number;
      const callType = data.Call_Type;
      const callFrequency = data.Call_Frequency;
      const callDuration = data.Total_Call_Duration;
      const externalNumber = data.External_Number;
      const Overall_Call_Status = data.Overall_Call_Status;
      if (!result[agentNumber]) {
        result[agentNumber] = {
          totalCalls: 0,
          totalDuration: 0,
          Total_Received_Amount: 0,
          internal: {
            INBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
            OUTBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
          },
          external: {
            INBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
            OUTBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
          },
          unknown: {
            INBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
            OUTBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
          },
          did: {
            INBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
            OUTBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
          },
        };
      }
      if (!result[agentNumber][type]) {
        result[agentNumber] = {
          ...result[agentNumber],
          [type]: {
            INBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
            OUTBOUND: { Call_Frequency: 0, Total_Call_Duration: 0 },
          },
        };
      }
      if (Overall_Call_Status === "Answered") {
        if (callType == "INBOUND" && isNumberIsDIDNo(externalNumber)) {
          result[agentNumber]["did"][callType].Call_Frequency += callFrequency;
          result[agentNumber]["did"][callType].Total_Call_Duration +=
            callDuration;
          totalAns["didCalls"] += callFrequency;
          totalAns["didDuration"] += callDuration;
        } else {
          result[agentNumber][type][callType].Call_Frequency += callFrequency;
          result[agentNumber][type][callType].Total_Call_Duration +=
            callDuration;
          totalAns[`${type}Calls`] += callFrequency;
          totalAns[`${type}Duration`] += callDuration;
        }
        result[agentNumber].totalCalls += callFrequency;
        result[agentNumber].totalDuration += callDuration;
        totalAns["totalCalls"] += callFrequency;
        totalAns["totalDuration"] += callDuration;
      } else {
        if (callType == "INBOUND" && isNumberIsDIDNo(externalNumber)) {
          totalMissed["didCalls"] += callFrequency;
          totalMissed["didDuration"] += callDuration;
        } else {
          totalMissed[`${type}Calls`] += callFrequency;
          totalMissed[`${type}Duration`] += callDuration;
        }
        totalMissed["totalCalls"] += callFrequency;
        totalMissed["totalDuration"] += callDuration;
      }
    });
    return result;
  }

  const columns = [
    {
      title: "Agent Number",
      dataIndex: "Agent_Number",
      key: "Agent_Number",
      width: 200,
      ...getColumnSearchProps("Agent_Number"),
    },
    {
      title: "Agent Name",
      dataIndex: "Agent_Name",
      key: "Agent_Name",
      ...getColumnSearchProps("Agent_Name"),
    },
    // {
    //   title: "Collection",
    //   dataIndex: "Total_Received_Amount",
    //   key: "Total_Received_Amount",
    //   sorter: (a, b) => a.Total_Received_Amount - b.Total_Received_Amount,
    // },
    {
      title: "Inbound Calls",
      dataIndex: "InboundCalls",
      key: "InboundCalls",
      render: (text, record) => {
        const internal = record?.internal?.INBOUND?.Call_Frequency || 0;
        const external = record?.external?.INBOUND?.Call_Frequency || 0;
        const unknown = record?.unknown?.INBOUND?.Call_Frequency || 0;
        const did = record?.did?.INBOUND?.Call_Frequency || 0;
        const total = internal + external + unknown + did;
        const internalPercentage = ((internal / total) * 100).toFixed(2);
        const externalPercentage = ((external / total) * 100).toFixed(2);
        const unknownPercentage = ((unknown / total) * 100).toFixed(2);
        const didPercentage = ((did / total) * 100).toFixed(2);

        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">INTERNAL</th>
                <th className="border border-gray-300">CLIENT</th>
                <th className="border border-gray-300">EXTERNAL</th>
                <th className="border border-gray-300">DID</th>
              </tr>
              <tr>
                <td className="border border-gray-200 font-semibold text-center">
                  {internalPercentage || 0}%
                </td>
                <td className="border border-gray-200 font-semibold text-center">
                  {externalPercentage || 0}%
                </td>
                <td className="border border-gray-200 text-center font-semibold">
                  {unknownPercentage || 0}%
                </td>
                <td className="border border-gray-200 text-center font-semibold">
                  {didPercentage || 0}%
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "Outbound Calls",
      dataIndex: "OutboundCalls",
      key: "OutboundCalls",
      render: (text, record) => {
        const internal = record?.internal?.OUTBOUND?.Call_Frequency || 0;
        const external = record?.external?.OUTBOUND?.Call_Frequency || 0;
        const unknown = record?.unknown?.OUTBOUND?.Call_Frequency || 0;
        const did = record?.did?.OUTBOUND?.Call_Frequency || 0;
        const total = internal + external + unknown;
        const internalPercentage = ((internal / total) * 100).toFixed(2);
        const externalPercentage = ((external / total) * 100).toFixed(2);
        const unknownPercentage = ((unknown / total) * 100).toFixed(2);
        const didPercentage = ((did / total) * 100).toFixed(2);

        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">INTERNAL</th>
                <th className="border border-gray-300">CLIENT</th>
                <th className="border border-gray-300">EXTERNAL</th>
                {/* <th className="border border-gray-300">DID</th> */}
              </tr>
              <tr>
                <td className="border border-gray-200 font-semibold text-center">
                  {internalPercentage || 0}%
                </td>
                <td className="border border-gray-200 font-semibold text-center">
                  {externalPercentage || 0}%
                </td>
                <td className="border border-gray-200 font-semibold text-center">
                  {unknownPercentage || 0}%
                </td>
                {/* <td className="border border-gray-200 font-semibold text-center">
                  {didPercentage || 0}%
                </td> */}
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "Inbound Duration (Minutes)",
      dataIndex: "InboundDuration",
      key: "InboundDuration",
      render: (text, record) => (
        <table>
          <tbody>
            <tr className="bg-slate-200">
              <th className="border border-gray-300">INTERNAL</th>
              <th className="border border-gray-300">CLIENT</th>
              <th className="border border-gray-300">EXTERNAL</th>
              <th className="border border-gray-300">DID</th>
            </tr>
            <tr>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.internal?.INBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.external?.INBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.unknown?.INBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.did?.INBOUND?.Total_Call_Duration / 60).toFixed(2) ||
                  0}
              </td>
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      title: "Outbound Duration (Minutes)",
      dataIndex: "OutboundDuration",
      key: "OutboundDuration",
      render: (text, record) => (
        <table>
          <tbody>
            <tr className="bg-slate-200">
              <th className="border border-gray-300">INTERNAL</th>
              <th className="border border-gray-300">CLIENT</th>
              <th className="border border-gray-300">EXTERNAL</th>
              {/* <th className="border border-gray-300">DID</th> */}
            </tr>
            <tr>
              <td className="border border-gray-200  font-semibold text-center">
                {(record?.internal?.OUTBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.external?.OUTBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              <td className="border border-gray-200 font-semibold text-center">
                {(record?.unknown?.OUTBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td>
              {/* <td className="border border-gray-200 font-semibold text-center">
                {(record?.did?.OUTBOUND?.Total_Call_Duration / 60).toFixed(
                  2
                ) || 0}
              </td> */}
            </tr>
          </tbody>
        </table>
      ),
    },
    {
      title: "Total Calls Done",
      dataIndex: "totalCalls",
      key: "totalCalls",
      sorter: (a, b) => a.totalCalls - b.totalCalls,
    },
    {
      title: "Total Duration (Minutes)",
      dataIndex: "totalDuration",
      key: "totalDuration",
      sorter: (a, b) => a.totalDuration - b.totalDuration,
      render: (text, record) => <p>{(record.totalDuration / 60).toFixed(2)}</p>,
    },
  ];

  const dateFiltering = async (date) => {
    const currDate = date;
    // const endDate = "10/03/2024";
    let totalAns = {
      internalCalls: 0,
      externalCalls: 0,
      unknownCalls: 0,
      didCalls: 0,
      totalCalls: 0,
      internalDuration: 0,
      externalDuration: 0,
      unknownDuration: 0,
      didDuration: 0,
      totalDuration: 0,
    };
    let totalMissed = {
      internalCalls: 0,
      externalCalls: 0,
      unknownCalls: 0,
      didCalls: 0,
      totalCalls: 0,
      internalDuration: 0,
      externalDuration: 0,
      unknownDuration: 0,
      didDuration: 0,
      totalDuration: 0,
    };
    if (currDate === "") {
      let framedData = {};
      frameData(
        "internal",
        callsDataInternal,
        framedData,
        totalAns,
        totalMissed
      );
      frameData(
        "external",
        callsDataExternal,
        framedData,
        totalAns,
        totalMissed
      );
      frameData(
        "unknown",
        callsDataUnknown,
        framedData,
        totalAns,
        totalMissed
      );
      addCollectionData(framedData, callsDataCollection, currDate);
      let framedDataArray = [];
      Object.keys(framedData).forEach((key) => {
        framedDataArray.push({
          Agent_Number: key,
          ...framedData[key],
        });
      });
      let agentAddedFramedDataArray = await addAgentNames(framedDataArray);
      setDataSource(agentAddedFramedDataArray);
    } else {
      let newInternalData = callsDataInternal.filter(
        (data) => data.Date === currDate
      );
      let newExternalData = callsDataExternal.filter(
        (data) => data.Date === currDate
      );
      let newUnknownData = callsDataUnknown.filter(
        (data) => data.Date === currDate
      );
      let newCollectionData = callsDataCollection.filter(
        (data) => data.Date === currDate
      );
      let framedData = {};
      frameData("internal", newInternalData, framedData, totalAns, totalMissed);
      frameData("external", newExternalData, framedData, totalAns, totalMissed);
      frameData("unknown", newUnknownData, framedData, totalAns, totalMissed);

      addCollectionData(framedData, callsDataCollection, currDate);


      let framedDataArray = [];
      Object.keys(framedData).forEach((key) => {
        framedDataArray.push({
          Agent_Number: key,
          ...framedData[key],
        });
      });
      let agentAddedFramedDataArray = await addAgentNames(framedDataArray);
      setDataSource(agentAddedFramedDataArray);
    }
    setTotalAnswered(totalAns);
    setTotalMissed(totalMissed);
  };

  const onChangeDate = (date, dateString) => {
    setDay(dateString);
    dateFiltering(dateString);
  };



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      setStatus("fetching Employee to Employee Calls Data .....");
      const internalData = await getEmployeeCalls();

      setStatus("Fetching Employee to Client Calls Data .....");
      const externalData = await getClientCalls();

      setStatus("Fetching Employee to External Calls Data ......");
      const unknownData = await getUnkownCallsData();

      setStatus("Fetching Collection Data ......");
      const collectionData = await getCollectionData();
      const collectionDataAll = await getCollectionDataAll();
      setStatus("Framing Data .....");

      setCallsDataInternal(internalData.result);
      setCallsDataExternal(externalData.result);
      setCallsDataUnknown(unknownData.result);
      setCallsDataCollection(collectionData.result, "");
      setCollectionData(collectionData.result);

      setCollectionDataAll(collectionDataAll.result);

      let framedData = {};
      let totalAns = {
        internalCalls: 0,
        externalCalls: 0,
        unknownCalls: 0,
        didCalls: 0,
        totalCalls: 0,
        internalDuration: 0,
        externalDuration: 0,
        unknownDuration: 0,
        didDuration: 0,
        totalDuration: 0,
      };
      let totalMissed = {
        internalCalls: 0,
        externalCalls: 0,
        unknownCalls: 0,
        didCalls: 0,
        totalCalls: 0,
        internalDuration: 0,
        externalDuration: 0,
        unknownDuration: 0,
        didDuration: 0,
        totalDuration: 0,
      };
      frameData(
        "internal",
        internalData.result,
        framedData,
        totalAns,
        totalMissed
      );
      frameData(
        "external",
        externalData.result,
        framedData,
        totalAns,
        totalMissed
      );
      frameData(
        "unknown",
        unknownData.result,
        framedData,
        totalAns,
        totalMissed
      );

      addCollectionData(framedData, collectionData.result);
      setTotalAnswered(totalAns);
      setTotalMissed(totalMissed);
      let framedDataArray = [];
      Object.keys(framedData).forEach((key) => {
        framedDataArray.push({
          Agent_Number: key,
          ...framedData[key],
        });
      });

      let agentAddedFramedDataArray = await addAgentNames(framedDataArray);
      setDataSource(agentAddedFramedDataArray);

      setLoading(false);
    };
    if (dataSource.length === 0) {
      fetchData();
    } else {
      console.log("Already have the Data");
      formatDataForCsv();
    }
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Employee Data</h1>
      <div className="flex justify-start items-center mb-2">
        <div className="">
          <DatePicker onChange={onChangeDate} format={"DD/MM/YYYY"} />
        </div>
        <div>
          <CSVLink
            id="csv-link"
            data={formatDataForCsv(dataSource)}
            headers={DailyCallsHeader}
            filename={`CallDetails${day}.csv`}
          />
          <button
            className=" border-2 w-40 border-orange-500 mx-2 rounded-md text-orange-500 p-1 hover:text-white hover:bg-orange-500 transition duration-150 cursor-pointer"
            onClick={downloadCsv}
          >
            Download CSV
          </button>
        </div>
        {!loading && (
          <div>
            <button
              className=" border-2 w-40 border-orange-500 mx-2 rounded-md text-orange-500 p-1 hover:text-white hover:bg-orange-500 transition duration-150 cursor-pointer"
              onClick={downloadCollection}
            >
              Collections
            </button>
          </div>
        )}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "blue", color: "white" },
        }}
        width={1700}
      >
        <CallingReport
          setIsOpen={setIsModalOpen}
          totalAnswered={totalAnswered}
          totalMissed={totalMissed}
          dataSource={formatDataForCsv(dataSource)}
          Date={day}
        />
      </Modal>

      <Modal
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        okButtonProps={{
          style: { backgroundColor: "blue", color: "white" },
        }}
        width={1700}
      >
        <Collections
          setIsOpen={setIsModalOpen1}
          dataSourceCollection={collectionDataAll}
        // Date={day}
        />
      </Modal>

      {loading ? (
        <div className="min-h-screen min-w-screen space-y-2 flex flex-col justify-center items-center">
          <Spin />
          <p className="text-sm">{status}</p>
        </div>
      ) : (
        <div className="overflow-x-scroll">
          <Table columns={columns} dataSource={dataSource} />
        </div>
      )}
    </div>
  );
};

export default Employee;
