import React from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { userLogin } from "../../api/auth";

const LoginPage = () => {
  let navigate = useNavigate();

  const handleLogin = async (email, name, hd) => {
    try {
      const org = ["egniol.in", "egniol.co.in", "egniol.org"];
      if (hd && org.includes(hd)) {
        const data = await userLogin({ email, name, hd });
        if (data.success) {
          toast.success("Done Successfully");
          localStorage.setItem("authToken", data.token);
          localStorage.setItem("role", data.role);
          localStorage.setItem("email", email);
          navigate("/dispo");
        } else {
          toast.error("Failed Successfully");
        }
      } else {
        toast.error("Please Login with organizations emailId");
      }
    } catch (error) {
      console.error("Error Login :", error);
      toast.error("Error Logining");
      console.error("Error Login :", error);
      toast.error("Error Logining");
    }
  };

  return (
    <div className="min-h-screen flex  items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <Toaster />
      <div className="max-w-md w-full bg-white border-gray-800 shadow-gray-500 rounded-lg shadow-lg p-6 space-y-8">
        <h1 className="text-center text-2xl font-semibold text-gray-600">
          Login
        </h1>
        <div>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const userData = jwtDecode(credentialResponse.credential);
                await handleLogin(
                  userData?.email,
                  userData?.name,
                  userData?.hd
                );
              }}
              onError={(error) => {
                console.log(error);
                toast.error("Login Failed");
              }}
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";
// import { Spin } from "antd";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
// import { userLogin } from "../../api/auth";
// import qrt from "../../assets/logo.png";

// function Login() {
//   const initialValues = {
//     email: "",
//     password: "",
//   };

//   const [formValues, setFormValues] = useState(initialValues);
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       const data = await userLogin(formValues);

//       if (!data.success) {
//         toast.error(data.error);
//         setLoading(false);
//         return;
//       }

//       localStorage.setItem("authToken", data.token);
//       setLoading(false);
//       toast.success(`Login Successful`);
//       navigate(`/ivr`);
//     } catch (error) {
//       setLoading(false);
//       toast.error(error);
//     }
//   };

//   return (
//     <div className="flex items-center bg-zinc-800 justify-center min-h-screen overflow-hidden">
//       <Toaster position="top-center" reverseOrder={false} />

//       <div className="flex flex-col items-center justify-center w-full">
//         <img
//           src={qrt}
//           alt="Image"
//           className="mb-8 w-96"
//           style={{
//             filter: "drop-shadow(0 0 0.15rem rgb(200, 152, 0))",
//           }}
//         />

//         <form
//           className="max-w-md w-full p-8 border-orange-500 rounded-md  bg-white opacity-100 border"
//           style={{
//             backgroundColor: "white",
//             boxShadow: "rgb(190, 152, 0) 0px 7px 29px 0px",
//           }}
//         >
//           <h1 className="text-3xl font-semibold mb-4 text-center text-orange-500">
//             LOGIN
//           </h1>
//           <div className="mb-4">
//             <label className="block text-lg text-orange-500 font-semibold">
//               Email
//             </label>
//             <input
//               type="text"
//               name="email"
//               placeholder="Enter your email"
//               value={formValues.email}
//               onChange={handleChange}
//               className="w-full p-2 text-orange-500 placeholder:text-orange-500 placeholder:font-normal rounded-md h-12 font-semibold  border-orange-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-lg text-orange-500 font-semibold">
//               Password
//             </label>
//             <div className="relative">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 name="password"
//                 placeholder="Enter your password"
//                 value={formValues.password}
//                 onChange={handleChange}
//                 className="w-full p-2 rounded-md h-12 placeholder:text-orange-500 placeholder:font-normal font-semibold border-orange-500"
//               />
//               <button
//                 type="button"
//                 onClick={() => setShowPassword(!showPassword)}
//                 className="absolute right-2 top-2"
//               >
//                 {showPassword ? (
//                   <FaEyeSlash className="h-7 w-5 text-orange-500" />
//                 ) : (
//                   <FaEye className="h-7 w-5 text-orange-500" />
//                 )}
//               </button>
//             </div>
//           </div>

//           <button
//             onClick={handleSubmit}
//             className="w-full bg-orange-500 text-white font-semibold py-2 px-4 rounded-md hover:scale-105 transition duration-150"
//             disabled={loading}
//           >
//             {loading ? <Spin size="small" /> : "Submit"}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Login;
// };

// export default LoginPage;

// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";
// import { Spin } from "antd";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
// import { userLogin } from "../../api/auth";
// import qrt from "../../assets/logo.png";

// function Login() {
//   const initialValues = {
//     email: "",
//     password: "",
//   };

//   const [formValues, setFormValues] = useState(initialValues);
//   const [loading, setLoading] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues({
//       ...formValues,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       const data = await userLogin(formValues);

//       if (!data.success) {
//         toast.error(data.error);
//         setLoading(false);
//         return;
//       }

//       localStorage.setItem("authToken", data.token);
//       setLoading(false);
//       toast.success(`Login Successful`);
//       navigate(`/ivr`);
//     } catch (error) {
//       setLoading(false);
//       toast.error(error);
//     }
//   };

//   return (
//     <div className="flex items-center bg-zinc-800 justify-center min-h-screen overflow-hidden">
//       <Toaster position="top-center" reverseOrder={false} />

//       <div className="flex flex-col items-center justify-center w-full">
//         <img
//           src={qrt}
//           alt="Image"
//           className="mb-8 w-96"
//           style={{
//             filter: "drop-shadow(0 0 0.15rem rgb(200, 152, 0))",
//           }}
//         />

//         <form
//           className="max-w-md w-full p-8 border-orange-500 rounded-md  bg-white opacity-100 border"
//           style={{
//             backgroundColor: "white",
//             boxShadow: "rgb(190, 152, 0) 0px 7px 29px 0px",
//           }}
//         >
//           <h1 className="text-3xl font-semibold mb-4 text-center text-orange-500">
//             LOGIN
//           </h1>
//           <div className="mb-4">
//             <label className="block text-lg text-orange-500 font-semibold">
//               Email
//             </label>
//             <input
//               type="text"
//               name="email"
//               placeholder="Enter your email"
//               value={formValues.email}
//               onChange={handleChange}
//               className="w-full p-2 text-orange-500 placeholder:text-orange-500 placeholder:font-normal rounded-md h-12 font-semibold  border-orange-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-lg text-orange-500 font-semibold">
//               Password
//             </label>
//             <div className="relative">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 name="password"
//                 placeholder="Enter your password"
//                 value={formValues.password}
//                 onChange={handleChange}
//                 className="w-full p-2 rounded-md h-12 placeholder:text-orange-500 placeholder:font-normal font-semibold border-orange-500"
//               />
//               <button
//                 type="button"
//                 onClick={() => setShowPassword(!showPassword)}
//                 className="absolute right-2 top-2"
//               >
//                 {showPassword ? (
//                   <FaEyeSlash className="h-7 w-5 text-orange-500" />
//                 ) : (
//                   <FaEye className="h-7 w-5 text-orange-500" />
//                 )}
//               </button>
//             </div>
//           </div>

//           <button
//             onClick={handleSubmit}
//             className="w-full bg-orange-500 text-white font-semibold py-2 px-4 rounded-md hover:scale-105 transition duration-150"
//             disabled={loading}
//           >
//             {loading ? <Spin size="small" /> : "Submit"}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Login;
