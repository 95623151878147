import { Table } from "antd";
import moment from "moment";

const CanadaTable = ({ data }) => {
  const headerStyle = {
    color: "orange", // White text color
    textAlign: "center", // Center align text
    fontWeight: "semibold",
    fontSize: "16px",
    backgroundColor: "#1f2937",
  };

  // Define columns
  const columns =
    data.length > 0
      ? [
          {
            title: <span>Sr. No</span>,
            dataIndex: "srNo",
            key: "srNo",
            width: 80,
            align: "center",
            onHeaderCell: () => ({
              style: headerStyle,
            }),
            className:
              "text-center font-semibold border border-slate-300 header-orange",
            render: (text, record, index) => index + 1,
          },
          ...Object.keys(data[0])
            .filter((key) => key !== "callid")
            .map((key) => {
              let column = {
                title: (
                  <span style={headerStyle}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </span>
                ),
                dataIndex: key,
                key: key,
                width: 110,
                align: "center",
                className:
                  "text-center font-semibold border border-slate-300 header-orange",
                onHeaderCell: () => ({
                  style: headerStyle, // Apply header cell style
                }),
                onFilter: (value, record) => record[key] === value,
                render: (text, record, index) => {
                  // Customize rendering for the date-time fields
                  if (key === "startTime" || key === "endTime") {
                    return (
                      <span className="w-full">
                        <div>{moment(text).format("DD-MM-YYYY")}</div>
                        <div>{moment(text).format("h:mm:ss a")}</div>
                      </span>
                    );
                  }
                  if (key === "recordingUrl") {
                    return (
                      <button className=" bg-orange-300 py-2 px-3 rounded-lg">
                        {text && (
                          <a
                            target="_blank"
                            href={text}
                            className="hover:text-black"
                          >
                            Open
                          </a>
                        )}
                        {!text && <span>No recording</span>}
                      </button>
                    );
                  }
                  return text;
                },
              };

              // Add filters for specific columns
              if (key === "status") {
                column.filters = [
                  { text: "Cancelled", value: "Cancelled" },
                  { text: "Completed", value: "Completed" },
                  { text: "No Answer", value: "No Answer" },
                ];
                column.onFilter = (value, record) => record.status === value;
              }

              if (key === "callType") {
                column.filters = [
                  { text: "Incoming", value: "Incoming" },
                  { text: "Outgoing", value: "Outgoing" },
                ];
                column.onFilter = (value, record) => record.callType === value;
              }

              if (key === "duration") {
                column.filters = [
                  { text: ">15", value: 15 },
                  { text: ">100", value: 100 },
                ];
                column.onFilter = (value, record) => record.duration >= value;
              }

              return column;
            }),
        ]
      : [];
  return (
    <Table
      dataSource={data}
      columns={columns}
      className="w-full"
      scroll={{ x: true }}
    />
  );
};

export default CanadaTable;
