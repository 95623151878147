import React, { useEffect, useState } from "react";
import LineChart from "./Graphs/LineChart";
import "chart.js/auto";

const Stats = ({ summaryData }) => {
  const [chartData, setChartData] = useState(null);
  const [smooth, setSmooth] = useState(true);
  const [selectedOption, setSelectedOption] = useState("dtmf_frequency");

  const option = [
    "dtmf_frequency",
    "total_answered_calls",
    "total_missed_calls",
  ];

  const graphData = () => {
    let transformedData = [];
    if (summaryData) {
      Object.keys(summaryData).forEach((hour) => {
        const hourData = { hour: parseInt(hour) };

        Object.keys(summaryData[hour]).forEach((account) => {
          if (selectedOption === "dtmf_frequency") {
            hourData[account] = summaryData[hour][account].dtmf_frequency;
          }
          if (selectedOption === "total_answered_calls") {
            hourData[account] = summaryData[hour][account].total_answered_calls;
          }
          if (selectedOption === "total_missed_calls") {
            hourData[account] = summaryData[hour][account].total_missed_calls;
          }
        });

        transformedData.push(hourData);
      });

      // Extract all hours from the label
      const labels = transformedData.map((data) => data.hour);

      // Extract account IDs
      const accountIds = Object?.keys(transformedData[0])?.filter(
        (key) => key !== "hour"
      );

      // // Prepare datasets
      const datasets = accountIds.map((accountId) => {
        return {
          label: accountId,
          data: transformedData.map((data) => data[accountId]),
          fill: false,
          borderColor: "#" + ((Math.random() * 0xffffff) << 0).toString(16), // Random color for each line
        };
      });

      // Update chart data
      const chartData = {
        labels,
        datasets,
      };
      setChartData(chartData);
    }
  };

  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    graphData();
  }, [selectedOption]);

  useEffect(() => {
    let transformedData = [];
    if (summaryData) {
      Object.keys(summaryData).forEach((hour) => {
        const hourData = { hour: parseInt(hour) };

        Object.keys(summaryData[hour]).forEach((account) => {
          if (selectedOption === "dtmf_frequency") {
            hourData[account] = summaryData[hour][account].dtmf_frequency;
          }
        });

        transformedData.push(hourData);
      });

      // Extract all hours from the label
      const labels = transformedData.map((data) => data.hour);

      // Extract account IDs
      const accountIds = Object?.keys(transformedData[0])?.filter(
        (key) => key !== "hour"
      );

      // // Prepare datasets
      const datasets = accountIds.map((accountId) => {
        return {
          label: accountId,
          data: transformedData.map((data) => data[accountId]),
          fill: false,
          borderColor: "#" + ((Math.random() * 0xffffff) << 0).toString(16), // Random color for each line
        };
      });

      // Update chart data
      const chartData = {
        labels,
        datasets,
      };
      setChartData(chartData);
    }

    // Dummy data representing total answered calls for different hours and accounts
    // const dummyData = [
    //   { hour: "10", TACN2740: 100, TACN2766: 150, TACN2826: 200 },
    //   { hour: "11", TACN2740: 120, TACN2766: 180, TACN2826: 220 },
    //   { hour: "12", TACN2740: 130, TACN2766: 160, TACN2826: 240 },
    // ];
  }, [summaryData]);

  const options = {
    plugins: {
      filler: {
        propagate: false,
      },
      title: {
        display: true,
        text: (ctx) => "Fill: " + ctx.chart.data.datasets[0].fill,
      },
    },
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: smooth ? 0.4 : 0,
      },
    },
    plugins: {
      title: {
        display: true,
        text: (ctx) => "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
      },
    },
  };

  return (
    <div>
      <div>
        <select
          value={selectedOption}
          onChange={handleOptionSelect}
          className="border border-black p-1 px-3 rounded-sm"
        >
          {/* Mapping through options and creating option elements */}
          {option.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div>
        {chartData && <LineChart chartData={chartData} options={options} />}
      </div>
    </div>
  );
};
export default Stats;
