import React, { useEffect, useState, useRef } from "react";
import {
  getAggregatedData,
  getCurrentDateDialerAggregatedData,
  getDNDNumberRecords,
  getDailyReportData,
} from "../../../api/airtel";
import moment from "moment";
import { CSVLink } from "react-csv";
import DialerReportDownload from "./DialerReportDownload";

import { Modal, Skeleton } from "antd";

// Table Utils
import { Table, Input, Button, Space, Spin, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import toast from "react-hot-toast";

import EmployeeGraph from "../Graph/EmployeeGraph";
import DNDReport from "./DNDReport";
import DailyReport from "./DailyReport";
import DialerGraphContainer from "../Graph/Dialer/DialerGraphContainer";
const Call_Statuses = ["ANSWERED", "MISSED"];

const DialerReportAirtel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDNDReportOpen, setIsDNDReportOpen] = useState(false);
  const [isModalOpenReport, setIsModalOpenReport] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dailyReportData, setDailyReportData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [graphType, setGraphType] = useState("DATE");
  const [filterLoading, setFilterLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateWiseAggregatedData, setDateWiseAggregatedData] = useState([]);
  const [CallStatus, setCallStatus] = useState("ANSWERED");
  const [total, setTotal] = useState({
    ANSWERED: {
      PVT: {
        totalCalls: 0,
        totalDuration: 0,
      },
      GST: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["DND"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["UNKNOWN"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
    },
    MISSED: {
      PVT: {
        totalCalls: 0,
        totalDuration: 0,
      },
      GST: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["DND"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["UNKNOWN"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
    },
    ALL: {
      PVT: {
        totalCalls: 0,
        totalDuration: 0,
      },
      GST: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["DND"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
      ["UNKNOWN"]: {
        totalCalls: 0,
        totalDuration: 0,
      },
    },
  });

  function viewCSV() {
    showModal();
  }
  function viewDailyReport() {
    showModalReport();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalReport = () => {
    setIsModalOpenReport(true);
  };

  const handleOkReport = () => {
    setIsModalOpenReport(false);
  };
  const handleCancelReport = () => {
    setIsModalOpenReport(false);
  };

  const showDNDReport = () => {
    setIsDNDReportOpen(true);
  };
  const handleDNDReportOk = () => {
    setIsDNDReportOpen(false);
  };
  const handleDNDReportCancel = () => {
    setIsDNDReportOpen(false);
  };

  const handleDownload = () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates");
      return;
    }

    document.getElementById("csv-link").click();
  };

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function toDate(dateStr) {
    const parts = dateStr.split("/");
    return new Date(parts[2], parts[1] - 1, parts[0]); // Months are zero-based
  }

  function validateDates(startDate, endDate) {
    // Convert date strings to Date objects
    const startDateObj = toDate(startDate);
    const endDateObj = toDate(endDate);

    // Get current date
    const currentDate = new Date();

    // Check if startDate or endDate is greater than the current date
    if (startDateObj > currentDate || endDateObj > currentDate) {
      return {
        msg: "Start date or end date cannot be greater than the current date.",
        isValid: false,
      };
    }

    // Check if startDate is greater than endDate
    if (startDateObj > endDateObj) {
      return {
        msg: "Start date cannot be greater than end date.",
        isValid: false,
      };
    }

    // Dates are valid
    return {
      msg: "Valid date",
      isValid: true,
    };
  }

  function isDateBetween(rowDateStr, startDateStr, endDateStr) {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("/");
      return new Date(`${year}-${month}-${day}`);
    };

    const rowDate = parseDate(rowDateStr);
    const startDate = parseDate(startDateStr);
    const endDate = parseDate(endDateStr);

    return rowDate >= startDate && rowDate <= endDate;
  }

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const rangeWiseFiltering = (startDate, endDate) => {
    setFilterLoading(true);

    let filteredAggregatedData = [];
    if (!startDate || !endDate) {
      filteredAggregatedData = dateWiseAggregatedData;
      processAggregatedData(filteredAggregatedData);

      setGraphType("DATE");
    } else if (startDate === endDate) {
      const { msg, isValid } = validateDates(startDate, endDate);
      if (!isValid) {
        toast.error(msg);
        setFilterLoading(false);
        return;
      }

      dateWiseAggregatedData.forEach((row) => {
        if (row.date === startDate) {
          filteredAggregatedData.push({
            ...row,
          });
        }
      });
      processAggregatedData(filteredAggregatedData);
      setGraphType("TIME");
    } else {
      const { msg, isValid } = validateDates(startDate, endDate);
      if (!isValid) {
        toast.error(msg);
        setFilterLoading(false);
        return;
      }

      const start = parseDate(startDate);
      const end = parseDate(endDate);
      if (isNaN(start) || isNaN(end)) {
        throw new Error("Invalid start or end date");
      }
      if (start > end) {
        toast.error("Start date should be less than end date");
        return;
      }

      dateWiseAggregatedData.forEach((row) => {
        if (isDateBetween(row.date, startDate, endDate)) {
          filteredAggregatedData.push({
            ...row,
          });
        }
      });
      processAggregatedData(filteredAggregatedData);
      setGraphType("DATE");
    }
    setFilterLoading(false);
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    rangeWiseFiltering(null, null);
  };

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const addCallDetails = (
    processedAggregatedData,
    Agent_Name,
    Agent_Call_Data
  ) => {
    Object.keys(Agent_Call_Data).forEach((Call_Status) => {
      processedAggregatedData[Agent_Name][Call_Status]["DND"].totalCalls +=
        Agent_Call_Data[Call_Status]["DND"].totalCalls;
      processedAggregatedData[Agent_Name][Call_Status]["UNKNOWN"].totalCalls +=
        Agent_Call_Data[Call_Status]["UNKNOWN"].totalCalls;
      processedAggregatedData[Agent_Name][Call_Status]["PVT"].totalCalls +=
        Agent_Call_Data[Call_Status]["PVT"].totalCalls;
      processedAggregatedData[Agent_Name][Call_Status]["GST"].totalCalls +=
        Agent_Call_Data[Call_Status]["GST"].totalCalls;

      processedAggregatedData[Agent_Name][Call_Status]["DND"].totalDuration +=
        Agent_Call_Data[Call_Status]["DND"].totalDuration;
      processedAggregatedData[Agent_Name][Call_Status][
        "UNKNOWN"
      ].totalDuration += Agent_Call_Data[Call_Status]["UNKNOWN"].totalDuration;
      processedAggregatedData[Agent_Name][Call_Status]["PVT"].totalDuration +=
        Agent_Call_Data[Call_Status]["PVT"].totalDuration;
      processedAggregatedData[Agent_Name][Call_Status]["GST"].totalDuration +=
        Agent_Call_Data[Call_Status]["GST"].totalDuration;

      processedAggregatedData[Agent_Name][Call_Status]["PVT"].totalInterested +=
        Agent_Call_Data[Call_Status]["PVT"].totalInterested;
      processedAggregatedData[Agent_Name][Call_Status]["GST"].totalInterested +=
        Agent_Call_Data[Call_Status]["GST"].totalInterested;
      processedAggregatedData[Agent_Name][Call_Status][
        "UNKNOWN"
      ].totalInterested +=
        Agent_Call_Data[Call_Status]["UNKNOWN"].totalInterested;

      // FOR BOTH ANSWERED AND MISSED
      processedAggregatedData[Agent_Name]["ALL"]["DND"].totalCalls +=
        Agent_Call_Data[Call_Status]["DND"].totalCalls;
      processedAggregatedData[Agent_Name]["ALL"]["UNKNOWN"].totalCalls +=
        Agent_Call_Data[Call_Status]["UNKNOWN"].totalCalls;
      processedAggregatedData[Agent_Name]["ALL"]["PVT"].totalCalls +=
        Agent_Call_Data[Call_Status]["PVT"].totalCalls;
      processedAggregatedData[Agent_Name]["ALL"]["GST"].totalCalls +=
        Agent_Call_Data[Call_Status]["GST"].totalCalls;

      processedAggregatedData[Agent_Name]["ALL"]["DND"].totalDuration +=
        Agent_Call_Data[Call_Status]["DND"].totalDuration;
      processedAggregatedData[Agent_Name]["ALL"]["UNKNOWN"].totalDuration +=
        Agent_Call_Data[Call_Status]["UNKNOWN"].totalDuration;
      processedAggregatedData[Agent_Name]["ALL"]["PVT"].totalDuration +=
        Agent_Call_Data[Call_Status]["PVT"].totalDuration;
      processedAggregatedData[Agent_Name]["ALL"]["GST"].totalDuration +=
        Agent_Call_Data[Call_Status]["GST"].totalDuration;

      processedAggregatedData[Agent_Name]["ALL"]["PVT"].totalInterested +=
        Agent_Call_Data[Call_Status]["PVT"].totalInterested;
      processedAggregatedData[Agent_Name]["ALL"]["GST"].totalInterested +=
        Agent_Call_Data[Call_Status]["GST"].totalInterested;
      processedAggregatedData[Agent_Name]["ALL"]["UNKNOWN"].totalInterested +=
        Agent_Call_Data[Call_Status]["UNKNOWN"].totalInterested;
    });
  };

  const calculateTotal = (total) => {
    let totalSourcesCalls = {
      ANSWERED: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
        },
      },
      MISSED: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
        },
      },
      ALL: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
        },
      },
    };
    Object.keys(total).forEach((hour) => {
      Object.keys(total[hour]).forEach((Call_Status) => {
        totalSourcesCalls[Call_Status]["PVT"].totalCalls +=
          total[hour][Call_Status]["PVT"].totalCalls;
        totalSourcesCalls[Call_Status]["GST"].totalCalls +=
          total[hour][Call_Status]["GST"].totalCalls;
        totalSourcesCalls[Call_Status]["DND"].totalCalls +=
          total[hour][Call_Status]["DND"].totalCalls;
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalCalls +=
          total[hour][Call_Status]["UNKNOWN"].totalCalls;

        totalSourcesCalls[Call_Status]["PVT"].totalDuration +=
          total[hour][Call_Status]["PVT"].totalDuration;
        totalSourcesCalls[Call_Status]["GST"].totalDuration +=
          total[hour][Call_Status]["GST"].totalDuration;
        totalSourcesCalls[Call_Status]["DND"].totalDuration +=
          total[hour][Call_Status]["DND"].totalDuration;
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalDuration +=
          total[hour][Call_Status]["UNKNOWN"].totalDuration;

        totalSourcesCalls[Call_Status]["PVT"].totalInterested +=
          total[hour][Call_Status]["PVT"].totalInterested;
        totalSourcesCalls[Call_Status]["GST"].totalInterested +=
          total[hour][Call_Status]["GST"].totalInterested;
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalInterested +=
          total[hour][Call_Status]["UNKNOWN"].totalInterested;

        // CUMMULATIVE TOTALS BOTH ANSWERED AND MISSED
        totalSourcesCalls["ALL"]["PVT"].totalCalls +=
          total[hour][Call_Status]["PVT"].totalCalls;
        totalSourcesCalls["ALL"]["GST"].totalCalls +=
          total[hour][Call_Status]["GST"].totalCalls;
        totalSourcesCalls["ALL"]["DND"].totalCalls +=
          total[hour][Call_Status]["DND"].totalCalls;
        totalSourcesCalls["ALL"]["UNKNOWN"].totalCalls +=
          total[hour][Call_Status]["UNKNOWN"].totalCalls;

        totalSourcesCalls["ALL"]["PVT"].totalDuration +=
          total[hour][Call_Status]["PVT"].totalDuration;
        totalSourcesCalls["ALL"]["GST"].totalDuration +=
          total[hour][Call_Status]["GST"].totalDuration;
        totalSourcesCalls["ALL"]["DND"].totalDuration +=
          total[hour][Call_Status]["DND"].totalDuration;
        totalSourcesCalls["ALL"]["UNKNOWN"].totalDuration +=
          total[hour][Call_Status]["UNKNOWN"].totalDuration;

        totalSourcesCalls["ALL"]["PVT"].totalInterested +=
          total[hour][Call_Status]["PVT"].totalInterested;
        totalSourcesCalls["ALL"]["GST"].totalInterested +=
          total[hour][Call_Status]["GST"].totalInterested;
        totalSourcesCalls["ALL"]["UNKNOWN"].totalInterested +=
          total[hour][Call_Status]["UNKNOWN"].totalInterested;
      });
    });
    return totalSourcesCalls;
  };

  const addTotals = (grandTotal, totalSourcesCalls) => {
    Call_Statuses.forEach((Call_Status) => {
      grandTotal[Call_Status]["PVT"].totalCalls +=
        totalSourcesCalls[Call_Status]["PVT"].totalCalls;
      grandTotal[Call_Status]["GST"].totalCalls +=
        totalSourcesCalls[Call_Status]["GST"].totalCalls;
      grandTotal[Call_Status]["DND"].totalCalls +=
        totalSourcesCalls[Call_Status]["DND"].totalCalls;
      grandTotal[Call_Status]["UNKNOWN"].totalCalls +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalCalls;

      grandTotal[Call_Status]["PVT"].totalDuration +=
        totalSourcesCalls[Call_Status]["PVT"].totalDuration;
      grandTotal[Call_Status]["GST"].totalDuration +=
        totalSourcesCalls[Call_Status]["GST"].totalDuration;
      grandTotal[Call_Status]["DND"].totalDuration +=
        totalSourcesCalls[Call_Status]["DND"].totalDuration;
      grandTotal[Call_Status]["UNKNOWN"].totalDuration +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalDuration;

      grandTotal[Call_Status]["PVT"].totalInterested +=
        totalSourcesCalls[Call_Status]["PVT"].totalInterested;
      grandTotal[Call_Status]["GST"].totalInterested +=
        totalSourcesCalls[Call_Status]["GST"].totalInterested;
      grandTotal[Call_Status]["UNKNOWN"].totalInterested +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalInterested;

      grandTotal["ALL"]["PVT"].totalCalls +=
        totalSourcesCalls[Call_Status]["PVT"].totalCalls;
      grandTotal["ALL"]["GST"].totalCalls +=
        totalSourcesCalls[Call_Status]["GST"].totalCalls;
      grandTotal["ALL"]["DND"].totalCalls +=
        totalSourcesCalls[Call_Status]["DND"].totalCalls;
      grandTotal["ALL"]["UNKNOWN"].totalCalls +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalCalls;

      grandTotal["ALL"]["PVT"].totalDuration +=
        totalSourcesCalls[Call_Status]["PVT"].totalDuration;
      grandTotal["ALL"]["GST"].totalDuration +=
        totalSourcesCalls[Call_Status]["GST"].totalDuration;
      grandTotal["ALL"]["DND"].totalDuration +=
        totalSourcesCalls[Call_Status]["DND"].totalDuration;
      grandTotal["ALL"]["UNKNOWN"].totalDuration +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalDuration;

      grandTotal["ALL"]["PVT"].totalInterested +=
        totalSourcesCalls[Call_Status]["PVT"].totalInterested;
      grandTotal["ALL"]["GST"].totalInterested +=
        totalSourcesCalls[Call_Status]["GST"].totalInterested;
      grandTotal["ALL"]["UNKNOWN"].totalInterested +=
        totalSourcesCalls[Call_Status]["UNKNOWN"].totalInterested;
    });
  };

  const calculateAverages = (grandTotal) => {
    Call_Statuses.forEach((Call_Status) => {
      grandTotal[Call_Status]["PVT"].avgCallDuration = Math.ceil(
        grandTotal[Call_Status]["PVT"].totalDuration /
          grandTotal[Call_Status]["PVT"].totalCalls
      );
      grandTotal[Call_Status]["GST"].avgCallDuration = Math.ceil(
        grandTotal[Call_Status]["GST"].totalDuration /
          grandTotal[Call_Status]["GST"].totalCalls
      );
      grandTotal[Call_Status]["DND"].avgCallDuration = Math.ceil(
        grandTotal[Call_Status]["DND"].totalDuration /
          grandTotal[Call_Status]["DND"].totalCalls
      );
      grandTotal[Call_Status]["UNKNOWN"].avgCallDuration = Math.ceil(
        grandTotal[Call_Status]["UNKNOWN"].totalDuration /
          grandTotal[Call_Status]["UNKNOWN"].totalCalls
      );
    });
    grandTotal["ALL"]["PVT"].avgCallDuration = Math.ceil(
      grandTotal["ALL"]["PVT"].totalDuration /
        grandTotal["ALL"]["PVT"].totalCalls
    );
    grandTotal["ALL"]["GST"].avgCallDuration = Math.ceil(
      grandTotal["ALL"]["GST"].totalDuration /
        grandTotal["ALL"]["GST"].totalCalls
    );
    grandTotal["ALL"]["DND"].avgCallDuration = Math.ceil(
      grandTotal["ALL"]["DND"].totalDuration /
        grandTotal["ALL"]["DND"].totalCalls
    );
    grandTotal["ALL"]["UNKNOWN"].avgCallDuration = Math.ceil(
      grandTotal["ALL"]["UNKNOWN"].totalDuration /
        grandTotal["ALL"]["UNKNOWN"].totalCalls
    );
  };

  const processAggregatedData = (aggregatedData) => {
    let processedAggregatedData = {};
    let processedAggregatedDataArray = [];
    let grandTotal = {
      ANSWERED: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
      },
      MISSED: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
      },
      ALL: {
        PVT: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        GST: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["UNKNOWN"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
          totalInterested: 0,
          totalDND: 0,
        },
        ["DND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
      },
    };
    aggregatedData.forEach((agData) => {
      let { summary, date, total } = agData;
      summary = JSON.parse(summary);
      total = JSON.parse(total);
      const totalSourcesCalls = calculateTotal(total);

      addTotals(grandTotal, totalSourcesCalls);

      Object.keys(summary).forEach((Agent_Name) => {
        if (!processedAggregatedData[Agent_Name]) {
          processedAggregatedData[Agent_Name] = {
            ["ANSWERED"]: {
              ["PVT"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["GST"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["UNKNOWN"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["DND"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
              },
            },
            MISSED: {
              ["PVT"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["GST"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["UNKNOWN"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["DND"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
              },
            },
            ALL: {
              ["PVT"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["GST"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["UNKNOWN"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
                totalInterested: 0,
                totalDND: 0,
              },
              ["DND"]: {
                totalCalls: 0,
                totalDuration: 0,
                avgCallDuration: 0,
              },
            },
          };
        }
        addCallDetails(
          processedAggregatedData,
          Agent_Name,
          summary[Agent_Name]
        );
      });
    });
    calculateAverages(grandTotal);
    Object.keys(processedAggregatedData).forEach((Agent_Name) => {
      let Agent_Data = processedAggregatedData[Agent_Name];
      Call_Statuses.forEach((Call_Status) => {
        if (
          Agent_Data[Call_Status]["GST"].totalCalls !== 0 ||
          Agent_Data[Call_Status]["GST"].totalDuration !== 0
        ) {
          Agent_Data[Call_Status]["GST"].avgCallDuration = Math.ceil(
            Agent_Data[Call_Status]["GST"].totalDuration /
              Agent_Data[Call_Status]["GST"].totalCalls
          );
        }
        if (
          Agent_Data[Call_Status]["PVT"].totalCalls !== 0 ||
          Agent_Data[Call_Status]["PVT"].totalDuration !== 0
        ) {
          Agent_Data[Call_Status]["PVT"].avgCallDuration = Math.ceil(
            Agent_Data[Call_Status]["PVT"].totalDuration /
              Agent_Data[Call_Status]["PVT"].totalCalls
          );
        }
        if (
          Agent_Data[Call_Status]["UNKNOWN"].totalCalls !== 0 ||
          Agent_Data[Call_Status]["UNKNOWN"].totalDuration !== 0
        ) {
          Agent_Data[Call_Status]["UNKNOWN"].avgCallDuration = Math.ceil(
            Agent_Data[Call_Status]["UNKNOWN"].totalDuration /
              Agent_Data[Call_Status]["UNKNOWN"].totalCalls
          );
        }
      });
      // for both answered and missd both
      if (
        Agent_Data["ALL"]["GST"].totalCalls !== 0 ||
        Agent_Data["ALL"]["GST"].totalDuration !== 0
      ) {
        Agent_Data["ALL"]["GST"].avgCallDuration = Math.ceil(
          Agent_Data["ALL"]["GST"].totalDuration /
            Agent_Data["ALL"]["GST"].totalCalls
        );
      }
      if (
        Agent_Data["ALL"]["PVT"].totalCalls !== 0 ||
        Agent_Data["ALL"]["PVT"].totalDuration !== 0
      ) {
        Agent_Data["ALL"]["PVT"].avgCallDuration = Math.ceil(
          Agent_Data["ALL"]["PVT"].totalDuration /
            Agent_Data["ALL"]["PVT"].totalCalls
        );
      }
      if (
        Agent_Data["ALL"]["UNKNOWN"].totalCalls !== 0 ||
        Agent_Data["ALL"]["UNKNOWN"].totalDuration !== 0
      ) {
        Agent_Data["ALL"]["UNKNOWN"].avgCallDuration = Math.ceil(
          Agent_Data["ALL"]["UNKNOWN"].totalDuration /
            Agent_Data["ALL"]["UNKNOWN"].totalCalls
        );
      }
      processedAggregatedDataArray.push({
        Agent_Name,
        ...Agent_Data,
      });
    });
    setDataSource(processedAggregatedDataArray);
    setFilteredData(aggregatedData);
    setTotal(grandTotal);
    return processedAggregatedData;
  };

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "Agent_Name",
      key: "Agent_Name",
      className: "font-bold text-center",
      ...getColumnSearchProps("Agent_Name"),
    },
    {
      title: "Total DND Calls Done",
      dataIndex: "DND",
      key: "DND",
      className: "font-bold text-center",
      sorter: (a, b) =>
        a[CallStatus]["DND"].totalCalls - b[CallStatus]["DND"].totalCalls,
      render: (text, record) => {
        return <p>{record[CallStatus]["DND"].totalCalls}</p>;
      },
    },
    {
      title: "PVT",
      dataIndex: "Source",
      key: "Source",
      sorter: (a, b) =>
        a[CallStatus]["PVT"].totalCalls - b[CallStatus]["PVT"].totalCalls,
      render: (text, record) => {
        const avgDuration = secondsToHMS(
          record[CallStatus]["PVT"].avgCallDuration
        );
        const totalCalls =
          record[CallStatus]["PVT"].totalCalls +
          record[CallStatus]["GST"].totalCalls;
        const totalDuration =
          record[CallStatus]["PVT"].totalDuration +
          record[CallStatus]["GST"].totalDuration;

        let totalCallsPercentage = 0;
        if (totalCalls !== 0 || totalDuration !== 0) {
          totalCallsPercentage = (
            (record[CallStatus]["PVT"].totalCalls / totalCalls) *
            100
          ).toFixed(2);
        }

        let totalDurationPercentage = 0;
        if (totalCalls !== 0 || totalDuration !== 0) {
          totalDurationPercentage = (
            (record[CallStatus]["PVT"].totalDuration / totalDuration) *
            100
          ).toFixed(2);
        }
        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black font-bold bg-[#90D150] text-center">
                  <span className="flex-col">
                    <p>{record[CallStatus]["PVT"].totalCalls}</p>
                    <p>[{totalCallsPercentage}%]</p>
                  </span>
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  <span className="flex-col">
                    <p>
                      {secondsToHMS(record[CallStatus]["PVT"].totalDuration)}
                    </p>
                    <p>[{totalDurationPercentage}%] </p>
                  </span>
                </td>
                <td className="border-2 border-black bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "GST",
      dataIndex: "Source",
      key: "Source",
      sorter: (a, b) =>
        a[CallStatus]["GST"].totalCalls - b[CallStatus]["GST"].totalCalls,
      render: (text, record) => {
        const avgDuration = secondsToHMS(
          record[CallStatus]["GST"].avgCallDuration
        );

        const totalCalls =
          record[CallStatus]["PVT"].totalCalls +
          record[CallStatus]["GST"].totalCalls;
        const totalDuration =
          record[CallStatus]["PVT"].totalDuration +
          record[CallStatus]["GST"].totalDuration;

        let totalCallsPercentage = 0;
        if (totalCalls !== 0 || totalDuration !== 0) {
          totalCallsPercentage = (
            (record[CallStatus]["GST"].totalCalls / totalCalls) *
            100
          ).toFixed(2);
        }

        let totalDurationPercentage = 0;
        if (totalCalls !== 0 || totalDuration !== 0) {
          totalDurationPercentage = (
            (record[CallStatus]["GST"].totalDuration / totalDuration) *
            100
          ).toFixed(2);
        }

        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black  font-bold bg-[#90D150] text-center">
                  <span className="flex-col">
                    <p> {record[CallStatus]["GST"].totalCalls}</p>
                    <p>[{totalCallsPercentage}%]</p>
                  </span>
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  <span className="flex-col">
                    <p>{record[CallStatus]["GST"].totalDuration}</p>
                    <p> [{totalDurationPercentage}% ]</p>
                  </span>
                </td>
                <td className="border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "TOTAL",
      dataIndex: "totalCalls",
      key: "totalCalls",
      sorter: (a, b) =>
        a[CallStatus]["PVT"].totalCalls +
        a[CallStatus]["GST"].totalCalls -
        (b[CallStatus]["PVT"].totalCalls + b[CallStatus]["GST"].totalCalls),
      render: (text, record) => {
        let totalCalls =
          record[CallStatus]["PVT"].totalCalls +
          record[CallStatus]["GST"].totalCalls;
        let totalDuration =
          record[CallStatus]["PVT"].totalDuration +
          record[CallStatus]["GST"].totalDuration;
        const totalDurationString = secondsToHMS(totalDuration);
        let avgDuration = 0;
        if (totalDuration !== 0 || totalCalls !== 0)
          avgDuration = secondsToHMS(Math.ceil(totalDuration / totalCalls));

        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black bg-[#90D150] font-bold text-center">
                  {totalCalls}
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  {totalDurationString}
                </td>
                <td className="border-2 border-black bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
      //   sorter: (a, b) => a.Outbound_Calls - b.Outbound_Calls,
    },
  ];

  function mergeAndSortArrays(result, currentArray) {
    // Iterate over each object in 'result'
    result.forEach((resultObj) => {
      // Parse the 'date' field to convert it to a sortable format
      const resultDate = moment(resultObj.date, "DD/MM/YYYY").toDate();

      // Check if 'date' exists in 'dateWiseArray'
      const existingIndex = currentArray.findIndex((dateObj) => {
        // Parse the 'date' field in 'dateWiseArray' to compare
        const currentArrayDate = moment(dateObj.date, "DD/MM/YYYY").toDate();
        // Compare parsed dates
        return currentArrayDate.getTime() === resultDate.getTime();
      });

      if (existingIndex !== -1) {
        // If 'date' exists, update 'summary' and 'total'
        currentArray[existingIndex].summary = resultObj.summary;
        currentArray[existingIndex].total = resultObj.total;
      } else {
        // If 'date' doesn't exist, push the entire object
        currentArray.push(resultObj);
      }
    });

    // Sort 'dateWiseArray' based on the 'date' field
    currentArray.sort((a, b) => {
      const dateA = moment(a.date, "DD/MM/YYYY").toDate();
      const dateB = moment(b.date, "DD/MM/YYYY").toDate();
      return dateA - dateB;
    });

    return currentArray;
  }

  const fetchAggregatedData = async (startDate, endDate) => {
    const { result } = await getAggregatedData({ startDate, endDate });
    const mergedArray = mergeAndSortArrays(result, dateWiseAggregatedData);
    processAggregatedData(mergedArray);
    setDateWiseAggregatedData(mergedArray);
    setGraphType("DATE");
  };

  useEffect(() => {
    const fetchAgentCustomerData = async () => {
      setLoading(true);
      let { result } = await getAggregatedData();
      setDateWiseAggregatedData(result);
      processAggregatedData(result);
      setLoading(false);
    };

    if (dataSource.length === 0) {
      fetchAgentCustomerData();
    }

    const fetchDailyReportData = async () => {
      const data = await getDailyReportData();

      if (data.success) {
        setDailyReportData(data?.result);
      }
    };

    fetchDailyReportData();
  }, []);

  useEffect(() => {
    if (dateWiseAggregatedData.length === 1) setGraphType("TIME");
  }, [dateWiseAggregatedData]);

  /*Logic of Download CSV in frameData when Object Object get */
  const csvColumns = [
    "Agent_Name",
    "DNDtotalCalls",
    "totalCalls",
    "totalDuration",
    "avgCallDuration",
    "PVTtotalCallsPercentage",
    "PVTtotalDurationPercentage",
    "totalPVTavgDuration",
    "GSTtotalCallsPercentage",
    "GSTtotalDurationPercentage",
    "totalGSTavgDuration",
  ];

  const flattenObject = (obj, prefix = "") => {
    return Object.keys(obj).reduce((acc, key) => {
      const pre = prefix.length ? `${prefix}.` : "";
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(acc, flattenObject(obj[key], pre + key));
      } else {
        acc[pre + key] = obj[key];
      }
      return acc;
    }, {});
  };

  const csvData = [
    csvColumns.join(","), // Add column names as the first row
    ...dataSource.map((item) => {
      const flattenedItem = flattenObject(item);

      const totalPVTcalls = flattenedItem["PVT.totalCalls"];
      const totalPVTDuration = flattenedItem["PVT.totalDuration"];
      const totalGSTcalls = flattenedItem["GST.totalCalls"];
      const totalGSTDuration = flattenedItem["GST.totalDuration"];
      const totalCalls = flattenedItem["totalCalls"];
      const totalDuration = flattenedItem["totalDuration"];
      const avgCallDuration = flattenedItem["avgCallDuration"];

      const totalPVTavgDuration = secondsToHMS(
        flattenedItem["PVT.avgCallDuration"]
      );
      const totalGSTavgDuration = secondsToHMS(
        flattenedItem["GST.avgCallDuration"]
      );

      const a = secondsToHMS(totalDuration);
      const b = secondsToHMS(avgCallDuration);

      flattenedItem["totalDuration"] = a;
      flattenedItem["avgCallDuration"] = b;

      // Calculate percentages
      const totalPVTcallsPercentage = (
        (totalPVTcalls / totalCalls) *
        100
      ).toFixed(2);
      const totalPVTDurationPercentage = (
        (totalPVTDuration / totalDuration) *
        100
      ).toFixed(2);

      // Calculate percentages
      const totalGSTcallsPercentage = (
        (totalGSTcalls / totalCalls) *
        100
      ).toFixed(2);
      const totalGSTDurationPercentage = (
        (totalGSTDuration / totalDuration) *
        100
      ).toFixed(2);

      // Add percentages to the flattened object
      flattenedItem["PVTtotalCallsPercentage"] = totalPVTcallsPercentage;
      flattenedItem["PVTtotalDurationPercentage"] = totalPVTDurationPercentage;
      flattenedItem["totalPVTavgDuration"] = totalPVTavgDuration;

      // Add percentages to the flattened object
      flattenedItem["GSTtotalCallsPercentage"] = totalGSTcallsPercentage;
      flattenedItem["GSTtotalDurationPercentage"] = totalGSTDurationPercentage;
      flattenedItem["totalGSTavgDuration"] = totalGSTavgDuration;

      // Remove percentage columns from the flattened object
      delete flattenedItem["GST.avgCallDuration"];
      delete flattenedItem["GST.totalCalls"];
      delete flattenedItem["GST.totalDuration"];
      delete flattenedItem["PVT.avgCallDuration"];
      delete flattenedItem["PVT.totalCalls"];
      delete flattenedItem["PVT.totalDuration"];
      delete flattenedItem["Date"];
      delete flattenedItem["DND.avgCallDuration"];
      delete flattenedItem["DND.totalDuration"];

      return Object.values(flattenedItem).join(",");
    }),
  ].join("\n");

  return (
    <>
      <div>
        <Modal
          open={isModalOpenReport}
          onOk={handleOkReport}
          onCancel={handleCancelReport}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1500}
        >
          <DailyReport
            setIsOpen={setIsModalOpenReport}
            dailyReportData={dailyReportData}
          />
        </Modal>

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1300}
        >
          <DialerReportDownload
            setIsOpen={setIsModalOpen}
            dataSource={dataSource}
          />
        </Modal>

        <Modal
          open={isDNDReportOpen}
          onOk={handleDNDReportOk}
          onCancel={handleDNDReportCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1300}
        >
          <DNDReport setIsOpen={isDNDReportOpen} dataSource={dataSource} />
        </Modal>

        <p className="text-3xl font-semibold my-2 text-center">
          Call Details of Dialer of all Sources
        </p>

        <div className="mb-2 mx-auto grid grid-cols-3 gap-4 p-2">
          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">PVT Call Statistics</h2>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total PVT Calls
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["PVT"].totalCalls}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total PVT Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["PVT"].totalDuration)}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        AVG PVT Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["PVT"].avgCallDuration)}
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total Interested
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["PVT"].totalInterested}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">GST Call Statistics</h2>

            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total GST Calls
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["GST"].totalCalls}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total GST Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["GST"].totalDuration)}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        AVG. GST Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["GST"].avgCallDuration)}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        TOTAL INTERESTED
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["GST"].totalInterested}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">
              OTHER Call Statistics
            </h2>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total OTHER Calls
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["UNKNOWN"].totalCalls}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total OTHER Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(
                          total[CallStatus]["UNKNOWN"].totalDuration
                        )}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        AVG. OTHER Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(
                          total[CallStatus]["UNKNOWN"].avgCallDuration
                        )}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        TOTAL INTERESTED
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["UNKNOWN"].totalInterested}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">DND Call Statistics</h2>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total DND Calls
                      </td>
                      <td className="py-2 px-4">
                        {total[CallStatus]["DND"].totalCalls}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total DND Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["DND"].totalDuration)}
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        AVG. DND Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total[CallStatus]["DND"].avgCallDuration)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-between w-full mt-5 mb-5">
          <div className="ml-10">
            <span className="mx-2">From</span>
            <DatePicker
              onChange={(date) =>
                setStartDate(moment(date?.$d).format("DD/MM/YYYY"))
              }
              placeholder="Start date"
            />
            <span className="mx-2">to</span>
            <DatePicker
              onChange={(date) => {
                setEndDate(moment(date?.$d).format("DD/MM/YYYY"));
              }}
              placeholder="End date"
            />
            <button
              className="px-3 py-1 rounded-md bg-orange-500 ml-2 text-black font-semibold"
              onClick={() => rangeWiseFiltering(startDate, endDate)}
              disabled={loading}
            >
              Search
            </button>
            <button
              className="px-3 py-1 rounded-md bg-orange-500 ml-2 text-black font-semibold"
              onClick={() => clearFilter()}
              disabled={loading}
            >
              Clear Filter
            </button>
          </div>

          <div className="ml-1">
            <button
              onClick={viewDailyReport}
              disabled={loading}
              /*onClick={() => {
                if (dailyReportData && dailyReportData.length > 0) {
                  viewDailyReport();
                } else {
                  alert("Data is not loaded yet. Please wait.");
                }
              }}*/
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            >
              View Daily Report
            </button>
            <button
              onClick={viewCSV}
              disabled={loading}
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            >
              View Dailer Report
            </button>
            <button
              onClick={() => showDNDReport()}
              disabled={loading}
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            >
              View DND Report
            </button>

            <button
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
              onClick={handleDownload}
              disabled={loading}
            >
              Download CSV
            </button>
          </div>
          <CSVLink
            id="csv-link"
            data={csvData}
            filename={`DialerReporData_${moment().format(
              "YYYYMMDDHHmmss"
            )}.csv`}
          />
        </div>
        <div className="flex items-end justify-between p-4">
          <div>
            <select
              value={CallStatus}
              onChange={(e) => setCallStatus(e.target.value)}
              disabled={loading}
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
                width: "200px",
              }}
            >
              <option value="">Select an option</option>
              <option value="ANSWERED">ANSWERED</option>
              <option value="MISSED">MISSED</option>
              <option value="ALL">ALL</option>
            </select>
          </div>
          <p className=" font-semibold text-right px-5">
            <span className="text-red-500">NOTE</span>: UNKNOWN CALLS MEANS
            COMPANY NOT IN THE FINALPRODUCT2024 TABLE
          </p>
        </div>

        <Spin spinning={filterLoading} delay={500}>
          <div>
            {!loading ? (
              <>
                <DialerGraphContainer
                  filteredData={filteredData}
                  graphType={graphType}
                  CallStatus={CallStatus}
                />
                <EmployeeGraph
                  filteredData={filteredData}
                  CallStatus={CallStatus}
                />
                <Table columns={columns} dataSource={dataSource} />
              </>
            ) : (
              <div className="flex justify-center items-center min-h-screen min-w-screen">
                <Spin />
              </div>
            )}
          </div>
        </Spin>
      </div>
    </>
  );
};

export default DialerReportAirtel;
