import client from "./client";

export const getDataDateWise = async (startDate, endDate) => {
  try {
    const { data } = await client.get(
      `/ivr/getDataDateWise?startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAllRecords = async () => {
  try {
    const { data } = await client.get(`/ivr/getAllRecords`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const refreshIVRData = async () => {
  try {
    const { data } = await client.get(`/ivr/updateIVRData`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getTodaysAggregatedRecords = async (
  startDateTime,
  endDateTime
) => {
  try {
    const { data } = await client.get(
      `/ivr/getTodaysAggregatedRecords?startDate=${startDateTime}&endDate=${endDateTime}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getDateWiseAggregatedData = async (startDate, endDate) => {
  try {
    const { data } = await client.get(
      `/ivr/getDateWiseAggregatedData?startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const sendMediaInWhatsapp = async (obj) => {
  try {
    const { data } = await client.post(`/ivr/sendMediaInWhatsapp`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const IvrServiceForm = async (obj) => {
  try {
    const { data } = await client.post(`/ivr/ivrinsertService`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getIvrServiceForm = async () => {
  try {
    const { data } = await client.get(`/ivr/getIvrService`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
