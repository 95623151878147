// Function to update total calls and total duration for a specific source and call type
const updateTotalCallsAndDuration = (
  processedAggregatedData,
  Agent_Name,
  source,
  callType,
  callData
) => {
  processedAggregatedData[Agent_Name][source][callType].totalCalls +=
    callData[Agent_Name][source][callType].totalCalls;
  processedAggregatedData[Agent_Name][source][callType].totalDuration +=
    callData[Agent_Name][source][callType].totalDuration;
};

export const updateTotalCallsAndDurationForSpecificSource = (
  processedAggregatedData,
  Agent_Name,
  Agent_Call_Data
) => {
  updateTotalCallsAndDurationForSource(
    processedAggregatedData,
    Agent_Name,
    "PVT",
    Agent_Call_Data
  );
  updateTotalCallsAndDurationForSource(
    processedAggregatedData,
    Agent_Name,
    "GST",
    Agent_Call_Data
  );
  updateTotalCallsAndDurationForSource(
    processedAggregatedData,
    Agent_Name,
    "UNKNOWN",
    Agent_Call_Data
  );
};

// Function to update total calls and total duration for a specific source
const updateTotalCallsAndDurationForSource = (
  processedAggregatedData,
  Agent_Name,
  source,
  callData
) => {
  processedAggregatedData[Agent_Name][source].totalCalls +=
    callData[Agent_Name][source].totalCalls;
  processedAggregatedData[Agent_Name][source].totalDuration +=
    callData[Agent_Name][source].totalDuration;
};

// Function to update total calls and total duration for all sources
export const updateTotalCallsAndDurationForAllSources = (
  processedAggregatedData,
  Agent_Name,
  callData
) => {
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "PVT",
    "OUTBOUND",
    callData
  );
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "PVT",
    "INBOUND",
    callData
  );
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "GST",
    "OUTBOUND",
    callData
  );
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "GST",
    "INBOUND",
    callData
  );
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "UNKNOWN",
    "OUTBOUND",
    callData
  );
  updateTotalCallsAndDuration(
    processedAggregatedData,
    Agent_Name,
    "UNKNOWN",
    "INBOUND",
    callData
  );
};

// Function to update total calls and total duration for all sources combined
export const updateTotalCallsAndDurationForAll = (
  processedAggregatedData,
  Agent_Name,
  callData
) => {
  const totalCalls =
    callData[Agent_Name]["PVT"].totalCalls +
    callData[Agent_Name]["GST"].totalCalls +
    callData[Agent_Name]["UNKNOWN"].totalCalls;
  const totalDuration =
    callData[Agent_Name]["PVT"].totalDuration +
    callData[Agent_Name]["GST"].totalDuration +
    callData[Agent_Name]["UNKNOWN"].totalDuration;
  processedAggregatedData[Agent_Name].totalCalls += totalCalls;
  processedAggregatedData[Agent_Name].totalDuration += totalDuration;
};

// UPDATING TOTATS -------------------------------------------

// Function to update totals for a specific source and call type
const updateTotalsForSourceAndCallType = (
  grandTotal,
  source,
  callType,
  totalSourcesCalls
) => {
  grandTotal[source][callType].totalCalls +=
    totalSourcesCalls[source][callType].totalCalls;
  grandTotal[source][callType].totalDuration +=
    totalSourcesCalls[source][callType].totalDuration;
};

// Function to update totals for a specific source
const updateTotalsForSource = (grandTotal, source, totalSourcesCalls) => {
  grandTotal[source].totalCalls += totalSourcesCalls[source].totalCalls;
  grandTotal[source].totalDuration += totalSourcesCalls[source].totalDuration;
};

// Function to update totals for all sources and call types
export const updateTotalsForAllSourcesAndCallTypes = (
  grandTotal,
  totalSourcesCalls
) => {
  updateTotalsForSourceAndCallType(
    grandTotal,
    "PVT",
    "OUTBOUND",
    totalSourcesCalls
  );
  updateTotalsForSourceAndCallType(
    grandTotal,
    "PVT",
    "INBOUND",
    totalSourcesCalls
  );
  updateTotalsForSourceAndCallType(
    grandTotal,
    "GST",
    "OUTBOUND",
    totalSourcesCalls
  );
  updateTotalsForSourceAndCallType(
    grandTotal,
    "GST",
    "INBOUND",
    totalSourcesCalls
  );
  updateTotalsForSourceAndCallType(
    grandTotal,
    "UNKNOWN",
    "OUTBOUND",
    totalSourcesCalls
  );
  updateTotalsForSourceAndCallType(
    grandTotal,
    "UNKNOWN",
    "INBOUND",
    totalSourcesCalls
  );
};

// Function to update totals for all sources
export const updateTotalsForAllSources = (grandTotal, totalSourcesCalls) => {
  updateTotalsForSource(grandTotal, "PVT", totalSourcesCalls);
  updateTotalsForSource(grandTotal, "GST", totalSourcesCalls);
  updateTotalsForSource(grandTotal, "UNKNOWN", totalSourcesCalls);
};

// Function to update grand total
export const updateGrandTotal = (grandTotal, totalSourcesCalls) => {
  grandTotal.totalCalls +=
    totalSourcesCalls["PVT"].totalCalls +
    totalSourcesCalls["GST"].totalCalls +
    totalSourcesCalls["UNKNOWN"].totalCalls;
  grandTotal.totalDuration +=
    totalSourcesCalls["PVT"].totalDuration +
    totalSourcesCalls["GST"].totalDuration +
    totalSourcesCalls["UNKNOWN"].totalDuration;
};

// adding hourly totals data form te summary

// Function to calculate total calls and total duration based on source and call type
export const calculateSourceAndCallTypeTotal = (hourData, source, callType) => {
  let totalCalls = 0;
  let totalDuration = 0;

  // Iterate over each hour
  Object.values(hourData).forEach((hour) => {
    totalCalls += hour[source][callType].totalCalls;
    totalDuration += hour[source][callType].totalDuration;
  });

  return {
    totalCalls,
    totalDuration,
    avgCallDuration: totalCalls > 0 ? totalDuration / totalCalls : 0,
  };
};

// Function to calculate total calls and total duration based on source
export const calculateSourceTotal = (hourData, source) => {
  let totalCalls = 0;
  let totalDuration = 0;

  // Iterate over each hour
  Object.values(hourData).forEach((hour) => {
    totalCalls += hour[source].totalCalls;
    totalDuration += hour[source].totalDuration;
  });

  return {
    ["INBOUND"]: calculateSourceAndCallTypeTotal(hourData, source, "INBOUND"),
    ["OUTBOUND"]: calculateSourceAndCallTypeTotal(hourData, source, "OUTBOUND"),
    totalCalls,
    totalDuration,
    avgCallDuration: totalCalls > 0 ? totalDuration / totalCalls : 0,
  };
};

// averagtes calcultion

export const calculateAverageForSourceAndCallType = (sourceData) => {
  return {
    totalCalls: sourceData.totalCalls,
    totalDuration: sourceData.totalDuration,
    avgCallDuration: Math.ceil(
      sourceData.totalDuration / sourceData.totalCalls
    ),
  };
};

// Function to calculate average call duration for a specific source
export const calculateAverageForSource = (grandTotal, source) => {
  return {
    ["INBOUND"]: calculateAverageForSourceAndCallType(
      grandTotal[source]["INBOUND"]
    ),
    ["OUTBOUND"]: calculateAverageForSourceAndCallType(
      grandTotal[source]["OUTBOUND"]
    ),
    totalCalls: grandTotal[source].totalCalls,
    totalDuration: grandTotal[source].totalDuration,
    avgCallDuration: Math.ceil(
      grandTotal[source].totalDuration / grandTotal[source].totalCalls
    ),
  };
};
