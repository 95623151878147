import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { addReport } from "../../api/report";
import toast, { Toaster } from "react-hot-toast";
import { Spin } from "antd";

function Unique_Talk_Time_Report({ selectedOption }) {
  const [csvLink, setCsvLink] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [arr, setArr] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const priorityArray = ["interested", "not interested", "scrap"];

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null; // If no key found for the element
  }
  // Array utils !

  const removeElement = (array, element) => {
    const newArray = array.filter((item) => !item.includes(element));
    return newArray;
  };
  const isExistsElement = (array, element) => {
    if (!array) {
      return false;
    }

    const exists = array.some((item) => item === element);
    if (exists) {
      return true;
    } else {
      return false;
    }
  };

  const removeFromAllKeys = (obj, elementToRemove) => {
    // Iterate over each property (array) in the object
    for (let key in obj) {
      if (obj[key]) {
        // Remove the specific element from each array
        obj[key] = obj[key].filter((item) => item !== elementToRemove);
      }
    }
  };

  const mappedKeys = {
    ["Customer Number"]: ["Customer Number", "Customer Number"],
    ["Disposition Name"]: [
      "Agent Disposition",
      "Disposition Name",
      "status_name",
    ],

    ["Total Call Duration (HH:MM:SS)"]: [
      "Customer Talk Duration",
      "Total Call Duration (HH:MM:SS)",
      "length_in_sec",
    ],
    ["Campaign Name"]: ["Campaign Name", "Campaign Name", "campaign_name"],
    ["Connected to Agent"]: ["Connected to Agent", "Agent Name", "full_name"],
  };
  // Array of specific dispositions
  const specificDispositions = [
    "call rejected",
    "channel issue",
    "issue with receiver network",
    "noans",
    "network issue",
    "invalid dialing",
    "lead being called",
    "not reachable",
    "receiver is busy",
    "number changed",
    "system failure",
    "unallocated number",
    "sos",
  ];

  const header = [
    "Busy",
    "Call Back",
    "Interested",
    "Language Issue",
    "Need Update",
    "not interested",
    "not pickup",
    "300 sec",
    "DND",
    "scrap",
    "Undisposed",
  ];

  // Function to check if a disposition is present in the array
  const isSpecificDisposition = (disposition) => {
    return specificDispositions.includes(disposition);
  };

  const handleSave = async () => {
    try {
      const jsonData = JSON.stringify(arr);

      const data = await addReport(jsonData, selectedOption);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      toast.error("Error occurred while saving the report");
    }
  };

  // Function to convert duration to seconds
  function durationToSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }
  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let keys = [];
          Object.keys(results.data[0]).map((key) => {
            keys.push({ mappedKey: getKeyForElement(key), originalKey: key });
          });
          results.data.map((data) => {
            let obj = {};
            keys.forEach(({ originalKey, mappedKey }) => {
              obj[mappedKey] = data[originalKey];
            });
            framedData.push(obj);
          });
          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  const convertToCSV = (arr) => {
    const header = Object.keys(arr[0]).join(",");
    const rows = arr.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };
  const downloadCSV = (arr) => {
    const csvContent = convertToCSV(arr);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    setCsvLink(url);
  };
  const captureAndDownload = () => {
    const element = document.getElementById("Dialer_Report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Dialer-Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const renderTable = () => {
    return (
      <>
        <table className="m-auto mt-3" id="Dialer_Report">
          <thead className="border border-black">
            <tr className="text-center">
              <th
                className="text-center border-black border pb-3 bg-[#92D14b] font-bold"
                colSpan={7}
              >
                201 Agents - AutoDialer Disposition Report{" "}
                {moment().format("DD-MM-YYYY")}
              </th>
            </tr>
            <tr className="border">
              <th
                className="border pb-3 text-center border-black p-2 bg-[#92D14b]"
                key={"sr no"}
              >
                Sr no.
              </th>
              {Object.keys(arr[0]).map((key) => (
                <th
                  className="border pb-3 text-center p-2 border-black  bg-[#92D14b]"
                  key={key}
                >
                  {key === "header" ? "Disposition" : key.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {arr.map((row, index) => (
              <tr key={index} className="border ">
                {row["header"] === "GRAND TOTAL" ||
                row["header"] === "TOTAL AGENTS" ||
                row["header"] === "TOTAL INTERESTED" ? (
                  <></>
                ) : (
                  <td
                    className={`border pb-3 text-center ${
                      row["header"] === "300 SEC" ||
                      row["header"] === "INTERESTED"
                        ? "bg-[#92D14b]"
                        : row["header"] === "NOT INTERESTED"
                        ? "text-red-500"
                        : ""
                    }  p-1 font-bold border-black`}
                    // key={idx}
                  >
                    {index + 1}
                  </td>
                )}
                {Object.values(row).map((value, idx) => {
                  if (
                    value === "GRAND TOTAL" ||
                    value === "TOTAL AGENTS" ||
                    value === "TOTAL INTERESTED"
                  ) {
                    return (
                      <td
                        colSpan={2}
                        className={`border pb-3 ${
                          value === "GRAND TOTAL"
                            ? "bg-[#92D14b]"
                            : value === "TOTAL AGENTS"
                            ? "bg-[#ffd866]"
                            : value === "TOTAL INTERESTED"
                            ? "bg-[#f5b083]"
                            : ""
                        } text-center p-1 font-bold border-black`}
                        key={idx}
                      >
                        {value}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        className={`border pb-3 ${
                          row["header"] === "GRAND TOTAL" ||
                          row["header"] === "300 SEC" ||
                          row["header"] === "INTERESTED"
                            ? "bg-[#92D14b]"
                            : row["header"] === "TOTAL AGENTS"
                            ? "bg-[#ffd866]"
                            : row["header"] === "TOTAL INTERESTED"
                            ? "bg-[#f5b083]"
                            : row["header"] === "NOT INTERESTED"
                            ? "text-red-500"
                            : ""
                        } text-center p-1 font-bold border-black`}
                        key={idx}
                      >
                        <p className="text">{value}</p>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray); // or const mergedArray = dataArray.flat();
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };

  // Function to handle file input change
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };
  const handleGenerateReport = () => {
    // Function to generate the report when the button is clicked
    processData();
  };

  const isPresentWithHighPriority = (result, team, dispo, customerNumber) => {
    let isPresent = false;
    priorityArray.map((disposition) => {
      if (isExistsElement(result[team][disposition], customerNumber)) {
        isPresent = true;
      }
      if (disposition === dispo) {
        return isPresent;
      }
    });
    return isPresent;
  };

  const getTotalAgents = (report) => {
    let totalAgents = {
      header: "TOTAL AGENTS",
      ["team a"]: 0,
      ["team b"]: 0,
      ["team france"]: 0,
      ["chennai team"]: 0,
      ["grand total"]: 0,
    };

    Object.keys(report).forEach((team) => {
      let customerNumberSet = new Set();
      Object.keys(report[team]).forEach((dispo) => {
        report[team][dispo].forEach((customerNumber) => {
          customerNumberSet.add(customerNumber);
        });
      });
      totalAgents[team] = customerNumberSet.size;
      totalAgents["grand total"] += customerNumberSet.size;
    });

    return totalAgents;
  };

  const formatData = (report) => {
    let dataArray = [];
    header.map((disposition) => {
      let dispoData = {
        header: disposition.toLocaleUpperCase(),
        ["team a"]: 0,
        ["team b"]: 0,
        ["team france"]: 0,
        ["chennai team"]: 0,
        ["grand total"]: 0,
      };
      Object.keys(report).forEach((key) => {
        dispoData[key] += report[key][disposition.toLocaleLowerCase()]
          ? report[key][disposition.toLocaleLowerCase()].length
          : 0;
        dispoData["grand total"] += report[key][disposition.toLocaleLowerCase()]
          ? report[key][disposition.toLocaleLowerCase()].length
          : 0;
      });
      dataArray.push(dispoData);
    });
    // Calculating the Grand Total, Total interested, total Agents
    let grandTotalsArray = [];
    // INITIALIZATION OF THE GRAND TOTAL
    let grandTotal = {
      header: "GRAND TOTAL",
      ["team a"]: 0,
      ["team b"]: 0,
      ["team france"]: 0,
      ["chennai team"]: 0,
      ["grand total"]: 0,
    };
    // INITIALIZATION OF THE TOTAL INTERESTED
    let totalInterested = {
      header: "TOTAL INTERESTED",
      ["team a"]: 0,
      ["team b"]: 0,
      ["team france"]: 0,
      ["chennai team"]: 0,
      ["grand total"]: 0,
    };
    dataArray.map((data) => {
      Object.keys(data).forEach((key) => {
        if (key !== "header") {
          grandTotal[key] += data[key];
          if (
            data["header"].toLocaleLowerCase() === "interested" ||
            data["header"].toLocaleLowerCase() === "300 sec"
          ) {
            totalInterested[key] += data[key];
          }
        }
      });
    });
    const totalAgents = getTotalAgents(report);
    grandTotalsArray.push(grandTotal);
    grandTotalsArray.push(totalInterested);
    grandTotalsArray.push(totalAgents);
    const finalReport = [...dataArray, ...grandTotalsArray];

    setArr(finalReport);
    downloadCSV(finalReport);
  };

  const processRecord = (result, team, customerNumber, dispo) => {
    if (dispo === "interested") {
      if (isPresentWithHighPriority(result, team, dispo, customerNumber)) {
      } else {
        removeFromAllKeys(result[team], customerNumber);
        result[team][dispo].push(customerNumber);
      }
    } else if (dispo === "not interested") {
      if (isPresentWithHighPriority(result, team, dispo, customerNumber)) {
      } else {
        removeFromAllKeys(result[team], customerNumber);
        result[team][dispo].push(customerNumber);
      }
    } else if (dispo === "scrap") {
      if (isPresentWithHighPriority(result, team, dispo, customerNumber)) {
      } else {
        removeFromAllKeys(result[team], customerNumber);
        result[team][dispo].push(customerNumber);
      }
    } else {
      if (isPresentWithHighPriority(result, team, dispo, customerNumber)) {
      } else {
        removeFromAllKeys(result[team], customerNumber);
        result[team][dispo].push(customerNumber);
      }
    }
  };

  const processData = () => {
    let report = {};
    let set = new Set();
    dataSource.forEach((row) => {
      if (!row["Disposition Name"] || !row["Campaign Name"]) {
      } else {
        let disposition = row["Disposition Name"].toLocaleLowerCase();
        const team = row["Campaign Name"].toLocaleLowerCase();
        const customerNumber = row["Customer Number"].trim();
        if (disposition === "do not call") disposition = "dnd";
        if (disposition === "not interesred") disposition = "not interested";
        if (disposition === "wrong number") disposition = "scrap";

        const duration = row["Total Call Duration (HH:MM:SS)"]
          ? durationToSeconds(row["Total Call Duration (HH:MM:SS)"])
          : 0;
        // is disposition is not interested !
        if (disposition !== "interested" && duration >= 300) {
          disposition = "300 sec";
        }

        if (isSpecificDisposition(disposition)) {
          disposition = "not pickup";
        }
        // Initialize count for the disposition if not present
        if (!report[team]) {
          report[team] = {};
        }
        if (!report[team][disposition]) {
          report[team][disposition] = [];
        }
        processRecord(report, team, customerNumber, disposition);
        handleSave();
      }
    });
  };

  // useEffect(() => {
  //   processData();
  // }, [dataSource]);

  return (
    <div className="m-5">
      <h1 className="font-semibold">Excel to CSV Converter : </h1>
      <form className="flex flex-row w-fit gap-x-3">
        <input type="file" multiple onChange={handleFileInputChange} />
        <button
          type="button"
          onClick={handleGenerateReport}
          className="border-black cursor-pointer rounded-sm font-semibold border-2 p-1 text-center"
        >
          Generate Report
        </button>
        {csvLink && (
          <>
            <a
              href={csvLink}
              download="repoUnique_Talk_Time_Report.csv"
              className=" border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              Download CSV
            </a>
            <div
              onClick={captureAndDownload}
              className="border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
              Download PNG
            </div>
          </>
        )}
      </form>
      <div>
        {selectedFiles.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} className="flex items-center mb-1">
                  <span className="mr-2">{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {arr.length > 0 && renderTable()}
    </div>
  );
}

export default Unique_Talk_Time_Report;
