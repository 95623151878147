import client from "./client";

export const getCalls = async (startDateTime, endDateTime) => {
  try {
    const { data } = await client.get(
      `/callHippo/calls?startDateTime=${startDateTime}&endDateTime=${endDateTime}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCallerStatistics = async (startDateTime, endDateTime) => {
  try {
    const { data } = await client.get(
      `/callHippo/caller-statistics?startDateTime=${startDateTime}&endDateTime=${endDateTime}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
