import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const Incoming1800Graph = ({ summaryIncoming }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (summaryIncoming) {
      const chmaDialerData = summaryIncoming?.CHMADialer?.map((item) => ({
        x: item.hour_range,
        y: item.call_count,
      }));

      const waLogsData = summaryIncoming?.missedDialer?.map((item) => ({
        x: item.hour_range,
        y: item.call_count,
      }));

      const chartData = {
        labels: chmaDialerData?.map((item) => item.x), // Assuming both arrays have the same x values
        datasets: [
          {
            label: "CHMADialer",
            data: chmaDialerData,
            borderColor: "red",
            fill: false,
          },
          {
            label: "wa_logs",
            data: waLogsData,
            borderColor: "blue",
            fill: false,
          },
        ],
      };

      setChartData(chartData);
    }
  }, [summaryIncoming]);

  return (
    <div>
      {chartData && (
        <Line
          data={chartData}
          options={{
            scales: {
              x: {
                type: "category",
                labels: chartData.labels,
              },
              y: {
                beginAtZero: true,
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default Incoming1800Graph;
