import { Spin } from "antd";
import React from "react";

const MenuTable = ({ endStamp, data, loading, header }) => {
  const currentDate = new Date().toLocaleDateString("en-GB");

  const modifiedData = data.map((item) => {
    if (item.source === "DTMF") {
      return { ...item, source: "DTMF PRESSED", remarks: `DATE : ${currentDate}` };
    }
    if (item.source === "WEB INCOMING") {
      return { ...item, source: "WEB INCOMING MISSED CALLS", remarks: `DATE : ${currentDate}` };
    }
    if (item.source === "API REPLY PT") {
      return { ...item, source: "API REPLY - INCOME TAX", remarks: `PT DATA` };
    }
    if (item.source === "API REPLY GT & UDY") {
      return { ...item, source: "API REPLY - INCOME TAX", remarks: `GT & UDY` };
    }
    if (item.source === "EMAIL") {
      return { ...item, source: "EMAIL LEADS", remarks: `MSME & GST SAHAY` };
    }
    if (item.source === "WEBSITE") {
      return { ...item, source: "WEBSITE LEADS", remarks: `${endStamp}` };
    }
    if (item.source === "TOTAL FRESH LEADS") {
      return { ...item, remarks: "-" }
    }
    return item;
  });

  // Reorder the data according to your desired sequence
  const orderedData = [
    modifiedData.find(item => item?.source === "WEBSITE LEADS"),
    modifiedData.find(item => item?.source === "EMAIL LEADS"),
    modifiedData.find(item => item?.source === "API REPLY - INCOME TAX" && item.remarks === "PT DATA"),
    modifiedData.find(item => item?.source === "API REPLY - INCOME TAX" && item.remarks === "GT & UDY"),
    modifiedData.find(item => item?.source === "WEB INCOMING MISSED CALLS"),
    {
      source: "RCS API REPLY",
      adi: 0,
      chn: 0,
      total: 0,
      remarks: "MSME LOANS",
    },
    modifiedData.find(item => item?.source === "DTMF PRESSED"),
    modifiedData.find(item => item?.source === "TOTAL FRESH LEADS"), // Ensure TOTAL FRESH LEADS is last
  ];

  return (
    <Spin spinning={loading}>
      <div className="border rounded-sm overflow-hidden">
        <h2 className="text-lg font-bold border-l-2 border-r-2 border-t-2 border-black px-1 text-center bg-[#92D14b]">
          {header}
        </h2>
        <table className="w-full">
          <thead>
            <tr className=" bg-[#92D14b] text-center">
              <th className="border-2 border-black px-1">SR.No</th>
              <th className="border-2 border-black px-1">Source</th>
              <th className="border-2 border-black px-1">ADI</th>
              <th className="border-2 border-black px-1">CHE</th>
              <th className="border-2 border-black px-1">TOTAL</th>
              <th className="border-2 border-black px-1">REMARKS</th>
            </tr>
          </thead>
          <tbody className="text-center font-bold">
            {orderedData.map((item, index) => (
              item && (
                <tr key={index}>
                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS" ? "bg-[#FED964]" : ""}`}
                  >
                    {item.source !== "TOTAL FRESH LEADS" ? index + 1 : ""}
                  </td>
                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS"
                      ? "bg-[#FED964]"
                      : "bg-white"
                      }`}
                  >
                    {item.source}
                  </td>

                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS" ? "bg-[#FED964]" : ""}`}
                  >
                    {item.adi}
                  </td>
                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS" ? "bg-[#FED964]" : ""}`}
                  >
                    {item.chn}
                  </td>
                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS" ? "bg-[#FED964]" : ""}`}
                  >
                    {item.total}
                  </td>
                  <td
                    className={`border-2 border-black px-1 ${item.source === "TOTAL FRESH LEADS" ? "bg-[#FED964]" : ""}`}
                  >
                    {item.remarks}
                  </td>
                </tr>
              )
            ))}
          </tbody>
        </table>
      </div>
    </Spin>
  );
};

export default MenuTable;
