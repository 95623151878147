import React, { useState } from "react";
import Dialer_report from "./Dialer_report";
import Talk_Time_Report from "./Talk_Time_Report";
import Ans_Miss_Report from "./Ans_Miss_Report";
import Unique_Talk_Time_Report from "./Unique_Talk_Time_Report";
import Unique_Talk_Time_Data from "./Unique_Talk_Time_Data";
import Income_tax_dispo from "./Income_tax_dispo";

const ReportDropDown = () => {
  const [selectedOption, setSelectedOption] = useState("Dialer Report");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="container mx-auto my-8">
      <label htmlFor="dropdown" className="block text-gray-700">
        Choose Service:
      </label>
      <select
        id="dropdown"
        value={selectedOption}
        onChange={handleChange}
        className="block appearance-none w-fit bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-gray-500"
      >
        <option value="Dialer Report">Dialer Report</option>
        <option value="Talk Time Report">Talk Time Report</option>
        <option value="Answered & Missed Report">
          Answered & Missed Report
        </option>
        <option value="Unique Talk Time Report">Unique Talk Time Report</option>
        <option value="Unique Talk Time Data">Unique Talk Time Data</option>
        <option value="INCOME TAX DISPO">INCOME TAX DISPO</option>
      </select>
      {selectedOption === "Dialer Report" && (
        <Dialer_report selectedOption={selectedOption} />
      )}
      {selectedOption === "Talk Time Report" && (
        <Talk_Time_Report selectedOption={selectedOption} />
      )}
      {selectedOption === "Answered & Missed Report" && (
        <Ans_Miss_Report selectedOption={selectedOption} />
      )}
      {selectedOption === "Unique Talk Time Report" && (
        <Unique_Talk_Time_Report selectedOption={selectedOption} />
      )}
      {selectedOption === "Unique Talk Time Data" && (
        <Unique_Talk_Time_Data selectedOption={selectedOption} />
      )}
      {selectedOption === "INCOME TAX DISPO" && (
        <Income_tax_dispo selectedOption={selectedOption} />
      )}
    </div>
  );
};

export default ReportDropDown;
