import React from 'react'

const FeedBackPopOver = ({ feedBack }) => {
    return (
        <div>
            <p className='font-semibold w-56'>{feedBack}</p>
        </div>
    )
}

export default FeedBackPopOver
