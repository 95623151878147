import React, { useEffect, useState } from "react";
import { DatePicker, Menu, ConfigProvider, Space } from "antd";
import { CSVLink } from "react-csv";
import "tailwindcss/tailwind.css";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import CanadaTable from "./Table/CanadaTable";
import { getAgentStats, getDataDateWise } from "../../api/open_phone_canada";
import { MessageOutlined, PhoneOutlined } from "@ant-design/icons";
import ReportTable from "./Table/ReportTable";
import { useParams } from "react-router-dom";

const Canada = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [agentStates, setAgentStates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Select Type");
  const [table, setTable] = useState(false);
  const { start, end } = useParams();

  let headers = [
    { label: "call_id", key: "call_id" },
    { label: "call_object", key: "call_object" },
    { label: "call_from_name", key: "call_from_name" },
    { label: "call_from", key: "call_from" },
    { label: "call_to", key: "call_to" },
    { label: "call_to_name", key: "call_to_name" },
    { label: "duration", key: "duration" },
    { label: "call_direction", key: "call_direction" },
    { label: "call_status", key: "call_status" },
    { label: "call_createdAt", key: "call_createdAt" },
    { label: "call_answeredAt", key: "call_answeredAt" },
    { label: "call_answeredBy", key: "call_answeredBy" },
    { label: "call_completedAt", key: "call_completedAt" },
    { label: "userId", key: "userId" },
    { label: "phoneNumberId", key: "phoneNumberId" },
    { label: "conversationId", key: "conversationId" },
  ];

  const phoneNumberMap = {
    "+16473703550": "Egniol canada -01",
    "+16474900555": "Egniol canada",
    "+16479519115": "Vansh",
    "+16474933360": "Pankti",
    "+16475597941": "Tanay",
    "+15315354934": "Darko  Bernard",
  };

  //fetch the data from the backend
  const fetchDataFromBackend = async () => {
    if (!startDate) {
      toast.error("select start date");
      return;
    }
    if (!endDate) {
      toast.error("select end date");
      return;
    }

    const data = await getDataDateWise(startDate, endDate);

    if (data.success) {
      setTable(false);
      const modifiedData = await data.data.map((record, index) => {
        // Calculate duration if both 'call_createdAt' and 'call_completedAt' are available
        if (record.call_completedAt) {
          const completedAtDate = new Date(record.call_completedAt);
          const createdAtDate = new Date(record.call_createdAt);
          const durationInSeconds = Math.floor(
            (completedAtDate - createdAtDate) / 1000
          ); // Convert milliseconds to seconds

          // Get names for call_from and call_to if they exist in phoneNumberMap
          const callFromName =
            phoneNumberMap[record.call_from] || record.call_from;
          const callToName = phoneNumberMap[record.call_to] || record.call_to;

          return {
            ...record,
            sr_no: index + 1,
            duration: durationInSeconds,
            call_from_name: callFromName,
            call_to_name: callToName,
          };
        }
        return {
          ...record,
          sr_no: index + 1,
          duration: "",
        };
      });
      setTableData(modifiedData);
    } else {
      toast(data.error);
    }
  };

  const getAgentWiseData = async () => {
    handleSetTable();
    if (!startDate) {
      toast.error("select start date");
      return;
    }
    if (!endDate) {
      toast.error("select end date");
      return;
    }
    const data = await getAgentStats(startDate, endDate);

    if (data.success) {
      // Convert object into an array of objects
      const dataArray = Object.entries(data.agentStats).map(
        ([agentName, stats]) => ({ agentName, ...stats })
      );
      // Sort the array based on averageDuration
      dataArray.sort((a, b) => b.averageDuration - a.averageDuration);

      // // Convert sorted array back into an object if needed
      const sortedData = {};
      dataArray.forEach(({ agentName, ...stats }) => {
        sortedData[agentName] = stats;
      });

      setAgentStates(sortedData);
    } else {
      toast.error(data.error);
    }
  };

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);
  };

  const handleSetTable = () => {
    setTable(true);
  };

  useEffect(() => {
    if (start !== undefined && end !== undefined) {
      setStartDate(start);
      setEndDate(end);
      getAgentWiseData();
    }
  }, [start, end]);

  const menu = (
    <Menu onClick={handleMenuClick} style={{ width: "150px" }}>
      <Menu.Item
        key="message"
        className="text-orange-500"
        icon={<MessageOutlined className="text-orange-500" />}
      >
        Message
      </Menu.Item>
      <Menu.Item
        key="call"
        className="text-orange-500"
        icon={<PhoneOutlined className="text-orange-500" />}
      >
        Call
      </Menu.Item>
    </Menu>
  );
  const handleDownload = () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates");
      return;
    }

    document.getElementById("csv-link").click();
  };
  return (
    <div className="m-5">
      <Toaster />
      <div className="flex justify-between mb-4">
        <div>
          <span className="mx-2">From</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  placeholder="Select start date"
                  showTime
                  onChange={(_, datetime) => {
                    setStartDate(datetime);
                  }}
                />
              </Space>
            </ConfigProvider>
          </Space>
          <span className="mx-2">to</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  showTime
                  onChange={(_, datetime) => {
                    setEndDate(datetime);
                  }}
                  placeholder="Select end date"
                />
              </Space>
            </ConfigProvider>
          </Space>
          <button
            className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105"
            onClick={fetchDataFromBackend}
          >
            View
          </button>
          {tableData?.length > 0 && (
            <button
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
              onClick={handleDownload}
              disabled={tableData?.length > 0 ? false : true}
            >
              Download CSV
            </button>
          )}
          <button
            className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            onClick={getAgentWiseData}
          >
            View Report
          </button>
        </div>

        <CSVLink
          id="csv-link"
          data={tableData}
          headers={headers}
          filename={`canada_data_${moment().format("YYYYMMDDHHmmss")}.csv`}
        />
        {/* <div className=" w-48">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="border-2 p-2 rounded-md border-orange-500"
          >
            <a
              className="ant-dropdown-link text-orange-500"
              onClick={(e) => e.preventDefault()}
            >
              {selectedOption === "Select Type" ? (
                "Select Type"
              ) : selectedOption === "message" ? (
                <>
                  <MessageOutlined className="text-orange-500 mx-2" /> Message
                </>
              ) : (
                <>
                  <PhoneOutlined className="text-orange-500 mx-2" /> Call
                </>
              )}
            </a>
          </Dropdown>
        </div> */}
      </div>
      <div className="flex flex-col items-center mx-2 p-4">
        {!table && <CanadaTable data={tableData} />}
        {table && (
          <ReportTable
            startDate={startDate}
            endDate={endDate}
            data={agentStates}
          />
        )}
      </div>
    </div>
  );
};

export default Canada;
