import React from "react";
import { Tabs } from "antd";
import MenuLeads from "./MenuLeads";
import MenuUtils from "./MenuUtils";
import AllMenuLeads from "./AllMenuLeads";
import RemoveMenuLeads from "./RemoveMenuLeads";

const { TabPane } = Tabs;

const MenuMain = () => {
  return (
    <Tabs defaultActiveKey="0" centered>
      <TabPane tab="Menu Leads" key="0" hidden>
        <MenuLeads />
      </TabPane>
      <TabPane tab="Menu Utils" key="1" hidden>
        <MenuUtils />
      </TabPane>
      <TabPane tab="Get Menu Leads" key="3" hidden>
        <AllMenuLeads />
      </TabPane>
      <TabPane tab="Remove Menu Leads" key="4" hidden>
        <RemoveMenuLeads />
      </TabPane>
    </Tabs>
  );
};

export default MenuMain;
