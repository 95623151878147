import client from "./client";

export const ComplainForm = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addComplain`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const ComplainFormCSV = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addComplainCSV`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const DtmfCSV = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addDtmfCSV`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const ScrapCSV = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addScrapCSV`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const FacebookCSVLIGMR = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addFacebookCSVLIGMR`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const FacebookCSVEGNIOL = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addFacebookCSVEGNIOL`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const EgniolLiveData = async (obj) => {
  try {
    const { data } = await client.post(`/complainForm/addEgniolLiveData`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};


export const getComplainForm = async () => {
  try {
    const { data } = await client.post(`/complainForm/getComplain`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const searchNumber = async (mobileNumber) => {
  try {
    const { data } = await client.get(
      `/complainForm/searchNumber/${mobileNumber}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
