import React, { useEffect, useState } from "react";
import DialerTable from "./Tables/IncomingTable";
import DialerAllTable from "./Tables/IncomingAllTable";
import {
  getDataDateWise,
  getTodaysAggregatedRecords,
  getAllAggregatedRecords,
} from "../../api/incoming";
import { DatePicker, Dropdown, Menu, Spin } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loader from "../General/Loader";

const Chennai = ({ activeTab }) => {
  const [dialerData, setDialerData] = useState([]);
  const [dialerAllData, setDialerAllData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Select Type");
  const [totalMissedCalls, setTotalMissedCalls] = useState(0);
  const [lodder, setLodder] = useState(false);
  const navigate = useNavigate();
  const incomingType = "CHENNAI";
  const handleSearch = async () => {
    if (selectedOption === "All Record") {
      setLodder(true);
      const { data } = await getAllAggregatedRecords(
        startDate,
        endDate,
        incomingType
      );
      const responseData = data || [];
      const agentWiseData =
        responseData.length > 0 ? responseData[0].agent_wise_data : {};
      setTotalMissedCalls(
        responseData.length > 0 ? responseData[0].total_missed_calls : 0
      );
      setDialerAllData(agentWiseData);
      setLodder(false);
    } else if (selectedOption === "Day wise") {
      setLodder(true);
      const { data } = await getDataDateWise(startDate, endDate, incomingType);
      const jsonData = data?.map((item) => {
        return {
          call_date: item.call_date,
          agent_wise_data: JSON.parse(item.agent_wise_data),
          total_missed_calls: JSON.parse(item.total_missed_calls),
        };
      });
      const sortedDialerData = jsonData.map(
        ({ call_date, agent_wise_data, total_missed_calls }) => {
          const sortedDayWiseData = Object.entries(agent_wise_data)
            .sort(([agent1, data1], [agent2, data2]) => {
              const avg1 = data1.average_min_ans_call || 0;
              const avg2 = data2.average_min_ans_call || 0;
              return avg2 - avg1; // Sort in descending order of average_min_ans_call
            })
            .reduce((acc, [agent, data]) => {
              acc[agent] = data;
              return acc;
            }, {});

          return {
            call_date,
            agent_wise_data: sortedDayWiseData,
            total_missed_calls,
          };
        }
      );
      setDialerData(sortedDialerData);

      setLodder(false);
    }
  };

  const getTodaysRecordsData = async () => {
    setLodder(true);
    const { data } = await getTodaysAggregatedRecords(incomingType);

    const sortedDialerData = data.map(
      ({ call_date, agent_wise_data, total_missed_calls }) => {
        const sortedDayWiseData = Object.entries(agent_wise_data)
          .sort(([agent1, data1], [agent2, data2]) => {
            const avg1 = data1.average_min_ans_call || 0;
            const avg2 = data2.average_min_ans_call || 0;
            return avg2 - avg1; // Sort in descending order of average_min_ans_call
          })
          .reduce((acc, [agent, data]) => {
            // Round off the average duration and convert it to minutes
            data.average_min_ans_call = Math.round(data.average_min_ans_call);
            acc[agent] = data;
            return acc;
          }, {});

        return {
          call_date,
          agent_wise_data: sortedDayWiseData,
          total_missed_calls,
        };
      }
    );
    setDialerData(sortedDialerData);

    setLodder(false);
  };

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick} style={{ width: "150px" }}>
      <Menu.Item key="All Record" className="text-orange-500">
        All Record
      </Menu.Item>
      <Menu.Item key="Day wise" className="text-orange-500">
        Day wise
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
    } else {
      getTodaysRecordsData();
    }
  }, []);

  useEffect(() => {
    if (activeTab == "2") {
      getTodaysRecordsData();
    }
  }, [activeTab]);
  return (
    <div className="container mx-auto mt-5">
      {/* {lodder && <Loader />} */}
      <div className="flex flex-row my-5">
        <button
          className="bg-orange-500 text-white px-4 py-2 rounded-md mb-4 mr-4 mx-3"
          onClick={() => {
            getTodaysRecordsData();
          }}
        >
          Today's Records
        </button>
        <div>
          <span className="mx-2">From</span>
          <DatePicker
            onChange={(date) =>
              setStartDate(moment(date?.$d).format("YYYY-MM-DD"))
            }
            placeholder="Start date"
          />
          <span className="mx-2">to</span>
          <DatePicker
            onChange={(date) => {
              setEndDate(moment(date?.$d).format("YYYY-MM-DD"));
            }}
            placeholder="End date"
          />
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="border-2 p-1 rounded-md mx-2 border-orange-500"
          >
            <a
              className="ant-dropdown-link text-orange-500"
              onClick={(e) => e.preventDefault()}
            >
              {selectedOption === "Select Type" ? (
                "Select Type"
              ) : selectedOption === "All Record" ? (
                <>All Record</>
              ) : (
                <>Day wise</>
              )}
            </a>
          </Dropdown>
          <button
            className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
      {/* <DialerTable dialerData={dialerData} /> */}
      {(selectedOption === "Select Type" || selectedOption === "Day wise") && (
        <Spin spinning={lodder}>
          <DialerTable
            incomingType={incomingType}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
            dialerData={dialerData}
          />
        </Spin>
      )}
      {selectedOption === "All Record" && (
        <Spin spinning={lodder}>
          <DialerAllTable
            incomingType={incomingType}
            startDate={startDate}
            endDate={endDate}
            totalMissedCalls={totalMissedCalls}
            dialerAllData={dialerAllData}
          />
        </Spin>
      )}
    </div>
  );
};

export default Chennai;
