import React, { useEffect, useState, useRef } from "react";
import { getClassWiseEmployeeData, getCollectionEmployeeClient } from "../../api/airtel";

import { Table, Input, Button, Space, Modal, Spin, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import EmployeeDetails from "./EmployeeDetails";

const classes = ["1-5", "5-10", "10-20", "20-30", "30-50", "50+"];
const ClassWiseEmployeeDetails = () => {
  // State to manage the currently selected class range
  const [selectedClass, setSelectedClass] = useState("1-5");
  const [employeeCallsData, setEmployeeCallsData] = useState(null);
  const [employeeClassWiseCallsData, setEmployeeClassWiseCallsData] =
    useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);

  const [employeeClientData, setEmployeeClientData] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handlePopUp = (mobileNo, agentName) => {
    setMobileNumber(mobileNo);
    setEmployeeName(agentName);
    showModal();
  };

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Agent Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      width: 200,
      ...getColumnSearchProps("mobileNumber"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        );

        return (
          <div className="bg-transparent rounded-lg -ml-2  -mr-2">
            <h2
              className="text-sm -mb-2 text-center transition duration-300 ease-in-out transform hover:scale-105 hover:text-orange-500 cursor-pointer"
              onClick={() => {
                handlePopUp(record.mobileNumber, record.Agent_Name);
              }}
            >
              {record.mobileNumber}
            </h2>
          </div>
        );
      },
    },
    {
      title: "Agent Name",
      dataIndex: "Agent_Name",
      key: "Agent_Name",
      ...getColumnSearchProps("Agent_Name"),
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      sorter: (a, b) =>
        employeeClassWiseCallsData[a.mobileNumber][selectedClass].count -
        employeeClassWiseCallsData[b.mobileNumber][selectedClass].count,
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2  -mr-2">
            {
              employeeClassWiseCallsData?.[record.mobileNumber]?.[selectedClass]
                ?.count
            }
          </div>
        );
      },
    },
  ];

  // Function to handle click on class range buttons
  const handleClassClick = (classRange) => {
    setSelectedClass(classRange);
  };

  const getClassWiseData = (result) => {
    return new Promise((resolve, reject) => {
      let agentCustomerFreqMap = {};
      result.forEach((row) => {
        const agentNumber = row.Agent_Number;
        const customerNumber = row.Customer_Number;
        const callFrequency = row.Call_Frequency;
        const total_call_duration = row.Total_Call_Duration;

        if (!agentCustomerFreqMap[agentNumber])
          agentCustomerFreqMap[agentNumber] = {};
        if (!agentCustomerFreqMap[agentNumber][customerNumber])
          agentCustomerFreqMap[agentNumber][customerNumber] = {
            callFrequency: 0,
            total_call_duration: 0,
          };
        // Calculating the frequency of calls ....!
        agentCustomerFreqMap[agentNumber][customerNumber]["callFrequency"] +=
          callFrequency;

        const totalMinutes = parseFloat((total_call_duration / 60).toFixed(2));

        agentCustomerFreqMap[agentNumber][customerNumber][
          "total_call_duration"
        ] += totalMinutes;
      });

      const classWiseData = {};
      result.forEach((row) => {
        const agentNumber = row.Agent_Number;
        const customerNumber = row.Customer_Number;
        const callFrequency =
          agentCustomerFreqMap[agentNumber][customerNumber]["callFrequency"];
        const total_call_duration =
          agentCustomerFreqMap[agentNumber][customerNumber][
          "total_call_duration"
          ];

        if (!classWiseData[agentNumber]) {
          classWiseData[agentNumber] = {
            "1-5": {
              count: 0,
              Customers: [],
            },
            "5-10": {
              count: 0,
              Customers: [],
            },
            "10-20": {
              count: 0,
              Customers: [],
            },
            "20-30": {
              count: 0,
              Customers: [],
            },
            "30-50": {
              count: 0,
              Customers: [],
            },
            "50+": {
              count: 0,
              Customers: [],
            },
          };
        }

        if (callFrequency >= 1 && callFrequency <= 5) {
          if (
            !classWiseData[agentNumber]["1-5"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["1-5"].count++;
            classWiseData[agentNumber]["1-5"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        } else if (callFrequency > 5 && callFrequency <= 10) {
          if (
            !classWiseData[agentNumber]["5-10"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["5-10"].count++;
            classWiseData[agentNumber]["5-10"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        } else if (callFrequency > 10 && callFrequency <= 20) {
          if (
            !classWiseData[agentNumber]["10-20"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["10-20"].count++;
            classWiseData[agentNumber]["10-20"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        } else if (callFrequency > 20 && callFrequency <= 30) {
          if (
            !classWiseData[agentNumber]["20-30"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["20-30"].count++;
            classWiseData[agentNumber]["20-30"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        } else if (callFrequency > 30 && callFrequency <= 50) {
          if (
            !classWiseData[agentNumber]["30-50"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["30-50"].count++;
            classWiseData[agentNumber]["30-50"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        } else {
          if (
            !classWiseData[agentNumber]["50+"].Customers.some(
              (customer) => customer.customerNumber === customerNumber
            )
          ) {
            classWiseData[agentNumber]["50+"].count++;
            classWiseData[agentNumber]["50+"].Customers.push({
              customerNumber,
              totalCallDuration: total_call_duration,
              callFrequency: callFrequency,
            });
          }
        }
      });
      resolve(classWiseData);
    });
  };

  const onChangeDate = (date, dateString) => {
    setDay(dateString);
    dayWiseFilteration(dateString);
  };
  const onChangeMonth = (date, dateString) => {
    setMonth(dateString);
    monthWiseFilteration(dateString);
  };

  const monthWiseFilteration = async (monthString) => {
    setLoading(true);
    let filteredData = [];
    if (monthString === "") {
      await resetFilter();
      setLoading(false);
      return;
    }
    employeeCallsData.map((data) => {
      if (data.Date.substring(3) === monthString) {
        filteredData.push(data);
      }
    });

    const classWiseData = await getClassWiseData(filteredData);
    setEmployeeClassWiseCallsData(classWiseData);
    let employeeFilteredData = [];
    Object.keys(classWiseData).map((mobileNumber) => {
      employeeFilteredData.push({
        mobileNumber: mobileNumber,
        Agent_Name: employeeDetails[mobileNumber.slice(-10)],
      });
    });
    setFilteredData(employeeFilteredData);
    setLoading(false);
  };

  const dayWiseFilteration = async (date) => {
    setLoading(true);
    let filteredData = [];
    if (date === "") {
      await resetFilter();
      setLoading(false);
      return;
    }
    employeeCallsData.map((data) => {
      if (data.Date === date) {
        filteredData.push(data);
      }
    });

    const classWiseData = await getClassWiseData(filteredData);
    setEmployeeClassWiseCallsData(classWiseData);
    let employeeFilteredData = [];
    Object.keys(classWiseData).map((mobileNumber) => {
      employeeFilteredData.push({
        mobileNumber: mobileNumber,
        Agent_Name: employeeDetails[mobileNumber.slice(-10)],
      });
    });

    setFilteredData(employeeFilteredData);
    setLoading(false);
  };

  const resetFilter = async () => {
    let filteredData = [];
    employeeCallsData.map((data) => {
      filteredData.push(data);
    });
    const classWiseData = await getClassWiseData(filteredData);
    setEmployeeClassWiseCallsData(classWiseData);
    let employeeFilteredData = [];
    Object.keys(classWiseData).map((mobileNumber) => {
      employeeFilteredData.push({
        mobileNumber: mobileNumber,
        Agent_Name: employeeDetails[mobileNumber.slice(-10)],
      });
    });

    setFilteredData(employeeFilteredData);
  };

  useEffect(() => {
    const fetchClassWiseDataData = async () => {
      setLoading(true);
      try {
        const { data } = await getClassWiseEmployeeData();
        const classWiseData = await getClassWiseData(data.result);

        const employeeCollection = await getCollectionEmployeeClient();

        setEmployeeClientData(employeeCollection.result);

        setEmployeeClassWiseCallsData(classWiseData);
        setEmployeeCallsData(data.result);
        setEmployeeDetails(data.employeeDetailsMap);


        let filteredData = [];
        Object.keys(classWiseData).map((mobileNumber) => {
          filteredData.push({
            mobileNumber: mobileNumber,
            Agent_Name: data.employeeDetailsMap[mobileNumber.slice(-10)],
          });
        });

        setFilteredData(filteredData);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (!employeeCallsData) fetchClassWiseDataData();
    else {
      console.log("Already have the Data !");
    }
  }, []);

  return (
    <div>
      {/* Render buttons for each class range */}
      <div className="flex space-x-2">
        {classes.map((classRange) => (
          <button
            key={classRange}
            onClick={() => handleClassClick(classRange)}
            className={`p-2  m-4 rounded-lg border-2 border-orange-500 text-orange-500 font-semibold hover:bg-orange-500 hover:text-white ${selectedClass === classRange
              ? " bg-orange-500 text-white "
              : " border-orange-500 text-orange-500 "
              }}`}
            style={{ marginRight: "10px" }}
          >
            {classRange}
          </button>
        ))}
      </div>
      <h1 className="font-semibold p-4">
        Agent Those Who done the calls to diffrent clients between{" "}
        {selectedClass} COUNT FREQ.
      </h1>
      <div className="flex justify-center my-4 items-center space-x-2">
        <DatePicker onChange={onChangeDate} format={"DD/MM/YYYY"} />
        <DatePicker
          onChange={onChangeMonth}
          picker="month"
          format={"MM/YYYY"}
        />
      </div>
      <Modal
        title="Employee Calls Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={4000}
      >
        <EmployeeDetails
          day={day}
          month={month}
          mobileNumber={mobileNumber}
          employeeClassWiseCallsData={employeeClassWiseCallsData}
          employeeCallsData={employeeCallsData}
          selectedClass={selectedClass}
          employeeName={employeeName}
          employeeClientData={employeeClientData}
        />
      </Modal>
      {!loading ? (
        <Table columns={columns} dataSource={filteredData} />
      ) : (
        <div className="flex justify-center items-center min-h-screen min-w-screen">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default ClassWiseEmployeeDetails;
