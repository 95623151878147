import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import toast, { Toaster } from "react-hot-toast";
import { Spin } from "antd";
import { addReport } from "../../api/report";
function Talk_Time_Report({ selectedOption }) {
  const [csvLink, setCsvLink] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null; // If no key found for the element
  }
  const mappedKeys = {
    // customerNumber: ["Customer Number", "Customer Number"],
    ["Total Call Duration (HH:MM:SS)"]: [
      "Customer Talk Duration",
      "Total Call Duration (HH:MM:SS)",
      "length_in_sec",
    ],
    ["Call Status"]: ["Call Status", "dialstatus"],
    ["Campaign Name"]: ["Campaign Name", "Campaign Name", "campaign_name"],
    ["Connected to Agent"]: ["Connected to Agent", "Agent Name", "full_name"],
  };

  // Function to check if a disposition is present in the array
  const handleSave = async () => {
    try {
      const jsonData = JSON.stringify(agentData);

      const data = await addReport(jsonData, selectedOption);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      toast.error("Error occurred while saving the report");
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const removeExtension = (agentName) => {
    // Split the name by the opening parenthesis
    const parts = agentName.split(" (");
    // Take the first part (firstname lastname)
    return parts[0].toUpperCase();
  };

  const padZero = (num) => {
    return num < 10 ? `0${num}` : num;
  };

  const formatDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  const convertToCSV = (arr) => {
    const header = Object.keys(arr[0]).join(",");
    const rows = arr.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };
  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let keys = [];
          Object.keys(results.data[0]).map((key) => {
            keys.push({ mappedKey: getKeyForElement(key), originalKey: key });
          });
          results.data.map((data) => {
            let obj = {};
            keys.forEach(({ originalKey, mappedKey }) => {
              obj[mappedKey] = data[originalKey];
            });
            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray); // or const mergedArray = dataArray.flat();
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };
  const handleGenerateReport = () => {
    // Function to generate the report when the button is clicked
    processData();
  };
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };
  const downloadCSV = (arr) => {
    const csvContent = convertToCSV(arr);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    setCsvLink(url);
  };
  const captureAndDownload = () => {
    const element = document.getElementById("Talk_Time_Report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Talk_Time_Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const processData = () => {
    const report = {};
    const agentStats = {};
    dataSource.forEach((row) => {
      const status = row["Call Status"];
      const agentName = row["Connected to Agent"];

      if (status && agentName) {
        if (!agentStats[agentName]) {
          agentStats[agentName] = { Answered: 0, Missed: 0 };
        }

        if (status === "Answered") {
          agentStats[agentName].Answered++;
        } else if (status === "Missed") {
          agentStats[agentName].Missed++;
        }
      }
    });

    const agentStatsArray = Object.entries(agentStats).map(
      ([agentName, stats]) => ({
        agentName: removeExtension(agentName),
        answeredCount: stats.Answered,
        missedCount: stats.Missed,
        totalCalls: stats.Answered + stats.Missed, // Calculate total calls for each agent
        totalCallDuration: dataSource
          .filter((row) => row["Connected to Agent"] === agentName)
          .reduce(
            (acc, row) =>
              acc + parseDuration(row["Total Call Duration (HH:MM:SS)"]),
            0
          ),
      })
    );

    const totalAnswered = agentStatsArray.reduce(
      (acc, curr) => acc + curr.answeredCount,
      0
    );
    const totalMissed = agentStatsArray.reduce(
      (acc, curr) => acc + curr.missedCount,
      0
    );

    // Calculate total call duration for all agents
    const totalCallDuration = agentStatsArray.reduce(
      (acc, curr) => acc + curr.totalCallDuration,
      0
    );
    const agentStatsArrayFormatted = agentStatsArray.map((agent) => ({
      ...agent,
      totalCallDuration: formatDuration(agent.totalCallDuration),
    }));

    const totals = {
      agentName: "Total",
      answeredCount: totalAnswered,
      missedCount: totalMissed,
      totalCalls: totalAnswered + totalMissed, // Calculate total calls
      totalCallDuration: formatDuration(totalCallDuration), // Format total call duration
    };

    agentStatsArrayFormatted.push(totals);

    const agentStatsArraySorted = agentStatsArrayFormatted.slice(); // Create a copy of the formatted array

    // Sort the array by total call duration (descending order)
    agentStatsArraySorted.sort((a, b) => {
      if (a.agentName === "Total") return 1; // Ensure "Total" row is always at the end
      if (b.agentName === "Total") return -1;
      return (
        parseDuration(b.totalCallDuration) - parseDuration(a.totalCallDuration)
      );
    });
    setAgentData(agentStatsArraySorted);
    downloadCSV(agentStatsArrayFormatted);
    handleSave();
  };

  const renderTable = () => {
    return (
      <table className="m-auto mt-3" id="Talk_Time_Report">
        <thead className="border border-black">
          <tr className="text-center">
            <th
              className="text-center border-black border pb-3 bg-yellow-500 font-bold"
              colSpan={6}
            >
              Talk Time Report
              {moment().format("DD-MM-YYYY")}
            </th>
          </tr>
          <tr className="border">
            <th
              className="border pb-3 text-center border-black p-2 bg-yellow-500"
              key={"sr no"}
            >
              Sr no.
            </th>
            {Object.keys(agentData[0]).map((key) => (
              <th
                className="border pb-3  text-center   border-black p-2 bg-yellow-500"
                key={key}
              >
                {key.toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {agentData.map((row, index) => (
            <tr key={index} className="border ">
              {row["agentName"] === "Total" ? (
                <></>
              ) : (
                <td
                  className={`border pb-3 text-center p-1  font-bold border-black`}
                >
                  {index + 1}
                </td>
              )}
              {Object.values(row).map((value, idx) => {
                if (value === "Total") {
                  return (
                    <td
                      colSpan={2}
                      className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-yellow-500`}
                      key={idx}
                    >
                      {value}
                    </td>
                  );
                } else {
                  return (
                    <td
                      className={`border pb-3 text-center ${
                        row["agentName"] === "Total" ? "bg-yellow-500" : ""
                      } p-1 font-bold border-black`}
                      key={idx}
                    >
                      <p className="text">{value}</p>
                    </td>
                  );
                }
              })}
            </tr>
          ))}
          {/* {agentData.map((row, index) => (
            <tr key={index} className="border ">
              {Object.values(row).map((value, idx) => (
                <td
                  className="border text-center p-1 font-semibold border-black"
                  key={idx}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </table>
    );
  };

  // useEffect(() => {
  //   processData();
  // }, [selectedFiles]);

  return (
    <div className="m-5">
      <h1 className="font-semibold">Excel to CSV Converter : </h1>
      <form className="flex flex-row w-fit gap-x-3">
        <input type="file" multiple onChange={handleFileInputChange} />
        <button
          type="button"
          onClick={handleGenerateReport}
          className="border-black cursor-pointer rounded-sm font-semibold border-2 p-1 text-center"
        >
          Generate Report
        </button>
        {csvLink && (
          <>
            <a
              href={csvLink}
              download="report.csv"
              className=" border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              Download CSV
            </a>
            <div
              onClick={captureAndDownload}
              className="border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
              Download PNG
            </div>
          </>
        )}
      </form>
      <div>
        {selectedFiles.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} className="flex items-center mb-1">
                  <span className="mr-2">{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {agentData.length > 0 && renderTable()}
    </div>
  );
}

export default Talk_Time_Report;
