import React, { useState } from 'react';
import axios from 'axios';  // Assuming you use axios for API calls
import moment from 'moment-timezone'
import { agentWiseDisposition } from '../../api/report';
const AgentNamesComponent = () => {
    const [inputText, setInputText] = useState('');
    const [agentData, setAgentData] = useState({});

    // Function to handle input change
    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    // Function to parse the text input into an array of agent names
    const parseAgentNames = () => {
        if (!inputText) return []
        return inputText.split('\n').map(name => name.trim()).filter(name => name);
    };

    // Function to handle form submission and make API call
    const handleSubmit = async (e) => {
        e.preventDefault();
        const agentNames = parseAgentNames();
        if (agentNames.length === 0) {
            setAgentData({})
            return
        }
        const currentDate = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');
        try {
            // Call your API with the agentNames array
            const response = await agentWiseDisposition({
                agentNames
            }, currentDate, currentDate);
            // Assume response data is in the format you mentioned
            setAgentData(response.data);
        } catch (error) {
            console.error('Error fetching agent data:', error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="agentNames" className="block text-gray-600 mb-2">
                        Enter Agent Names (one per line):
                    </label>
                    <textarea
                        id="agentNames"
                        value={inputText}
                        onChange={handleInputChange}
                        rows="5"
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Enter agent names, each on a new line"
                    ></textarea>
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
                >
                    Submit
                </button>
            </form>

            {Object.keys(agentData).length > 0 && (
                <div className="overflow-x-auto mt-6 space-y-6">
                    {Object.entries(agentData).map(([agentName, dispositions]) => (
                        <div key={agentName} className="bg-white border border-gray-200 rounded-lg shadow-md p-4">
                            <h3 className="text-xl font-semibold mb-4 text-gray-700">{agentName}</h3>
                            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
                                <thead>
                                    <tr className="bg-gray-100">
                                        <th className="px-4 py-2 text-left text-gray-600 font-medium">Disposition</th>
                                        <th className="px-4 py-2 text-left text-gray-600 font-medium">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dispositions.map((dispo, index) => (
                                        <tr key={index} className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}>
                                            <td className="border px-4 py-2">{dispo.dispo ? dispo.dispo : 'Undisposed'}</td>
                                            <td className="border px-4 py-2">{dispo.count}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AgentNamesComponent;
