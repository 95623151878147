import React from "react";
import { Tabs } from "antd";
import AirtelACTalkTimeReport from "./Airtel/Airtel_Agent_Customer_Talk_Time_Report";
import Airtel from "./Airtel/Airtel";
import DialerReportAirtel from "./Dialer/DialerReport";
import Employee from "./Employee";
import ClassWiseEmployeeDetails from "./ClassWiseEmployeeDetails";
import DNDFalseInput from "./FalseInputDetails/DNDFalseInput";
import Recording from "./Airtel/Recording";
import Welcome from "./Welcome";
import MissedCalls from "./MissedCalls";

const { TabPane } = Tabs;

const Main = () => {
  return (
    <Tabs defaultActiveKey="0" centered>
      <TabPane tab="Welcome" key="0" hidden>
        <Welcome />
      </TabPane>
      <TabPane tab="Dialer" key="1">
        <DialerReportAirtel />
      </TabPane>
      <TabPane tab="Airtel" key="2">
        <AirtelACTalkTimeReport />
      </TabPane>
      <TabPane tab="Employee (Class Wise)" key="3">
        <ClassWiseEmployeeDetails />
      </TabPane>
      <TabPane tab="DND False Input" key="4">
        <DNDFalseInput />
      </TabPane>
      <TabPane tab="Employee Details" key="5">
        <Airtel />
      </TabPane>
      <TabPane tab="Employee" key="6">
        <Employee />
      </TabPane>
      <TabPane tab="Recording" key="7">
        <Recording />
      </TabPane>
      <TabPane tab="Missed Calls" key="8">
        <MissedCalls />
      </TabPane>
    </Tabs>
  );
};

export default Main;
