import React, { useState } from "react";
import { Tabs } from "antd";
import Manual from "./Tabs/Manual";
import Schedule from "./Tabs/Schedule";
import Templates from "./Tabs/Templates";
import Analysis from "./Tabs/Analysis";

const Whatsapp = () => {
  const [activeTab, setActiveTab] = useState(1);

  const items = [
    {
      key: "1",
      label: "Manual",
      children: <Manual activeTab={activeTab} />,
    },
    {
      key: "2",
      label: "Cron Jobs",
      children: <Schedule activeTab={activeTab} />,
    },
    {
      key: "3",
      label: "Templates",
      children: <Templates activeTab={activeTab} />,
    },
    {
      key: "4",
      label: "Analysis",
      children: <Analysis activeTab={activeTab} />,
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="container mx-auto my-8">
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
    </div>
  );
};

export default Whatsapp;
