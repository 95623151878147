import toast from "react-hot-toast";
import html2canvas from "html2canvas";
import { sendMediaInWhatsapp } from "../api/ivr";
import moment from "moment";

export const handleCopyImage = (tableRef) => {
  html2canvas(tableRef.current)
    .then((canvas) => {
      canvas.toBlob((blob) => {
        const file = new File([blob], "table.png", {
          type: "image/png",
        });
        const clipboardItem = new ClipboardItem({ "image/png": file });
        navigator.clipboard.write([clipboardItem]);
      });
      toast.success("Copied to clipboard");
    })
    .catch((err) => {
      console.error("Error copying table:", err);
    });
};

export const handleCopyBase64Image = async (tableRef) => {
  try {
    const canvas = await html2canvas(tableRef.current);
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );
    const base64data = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
    });
    return base64data;
  } catch (error) {
    console.error("Error copying table:", error);
    throw error;
  }
};

export const handleWhatsappSend = async (tableRef, caption) => {
  const data = await sendMediaInWhatsapp({
    imgBase: await handleCopyBase64Image(tableRef),
    caption: caption,
  });
  if (data.success) {
    toast.success(data.msg);
  } else {
    toast.error(data.error);
  }
};

export const formatDateRange = async (startDate, endDate) => {
  if (startDate && endDate) {
    const startDateFormatted = moment(startDate).format("DD-MM-YYYY");
    const endDateFormatted = moment(endDate).format("DD-MM-YYYY");

    if (startDateFormatted === endDateFormatted) {
      return startDateFormatted;
    } else {
      return `${startDateFormatted} to ${endDateFormatted}`;
    }
  } else {
    const today = moment().format("DD-MM-YYYY");
    return `${today}`;
  }
};
