import React, { useState, useEffect } from 'react'
import { getCollectionsData } from "../../../api/airtel";

function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const AirtelReportDownload = ({ dataSource }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(30);
    const [displayedData, setDisplayedData] = useState([]);
    const [grandTotal, setGrandTotal] = useState({
        pvtCalls: 0,
        gstCalls: 0,
        totalCalls: 0,
        totalDuration: 0,
    });
    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const [grandTotalSummary, setGrandTotalSummary] = useState({});
    useEffect(() => {
        let gTotalSummary = {
            pvtCalls: 0,
            gstCalls: 0,
            pvtDuration: 0,
            gstDuration: 0,
            totalCalls: 0,
            totalDuration: 0,
            avgCallDuration: 0,
            collection: 0,
        };
        dataSource.forEach((item) => {
            if (dataSource.Agent_Name !== null) {
                gTotalSummary.pvtCalls += item.PVT.totalCalls;
                gTotalSummary.gstCalls += item.GST.totalCalls;
                gTotalSummary.pvtDuration += item.PVT.totalDuration;
                gTotalSummary.gstDuration += item.GST.totalDuration;
                gTotalSummary.totalCalls += item.totalCalls;
                gTotalSummary.totalDuration += item.totalDuration;
                gTotalSummary.collection += collection[item.Agent_Number.slice(-10)] || 0;
            }
        });

        gTotalSummary.totalDuration = secondsToHMS(gTotalSummary.totalDuration);
        setGrandTotalSummary(gTotalSummary);
    }, [dataSource]);


    const [collection, setCollections] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchEmployeeCollection = async () => {
            setLoading(true);
            const data = await getCollectionsData();
            // Convert collections array to object
            const collectionsObject = {};
            data.result.forEach(item => {
                collectionsObject[item.NUMBER] = item.Mar24;
            });
            setCollections(collectionsObject);

            setLoading(false);
        };
        fetchEmployeeCollection();
    }, []);


    useEffect(() => {
        const sortedData = [...dataSource].sort(
            (a, b) => b.totalDuration - a.totalDuration
        );

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = sortedData.slice(
            indexOfFirstRecord,
            indexOfLastRecord
        );

        let gTotal = {
            pvtCalls: 0,
            gstCalls: 0,
            totalCalls: 0,
            totalDuration: 0,
            collection: 0,
        };

        currentRecords.forEach((item) => {
            if (item.Agent_Name !== null) {
                gTotal.pvtCalls += item.PVT.totalCalls;
                gTotal.gstCalls += item.GST.totalCalls;
                gTotal.totalDuration += item.totalDuration;
                gTotal.totalCalls += item.totalCalls;

                gTotal.collection += collection[item.Agent_Number.slice(-10)] || 0;
            }
        });


        gTotal.totalDuration = secondsToHMS(gTotal.totalDuration);
        setGrandTotal(gTotal);
        setDisplayedData(currentRecords);
        // }, [dataSource, currentPage, recordsPerPage]);
    }, [dataSource, currentPage, recordsPerPage, collection]);

    return (
        <div>
            <div className='justify-center mt-5 mb-5'>
                <h1 className='p-1 text-center text-3xl font-semibold'>
                    <span className='border-black  p-1 rounded'>Airtel Report</span>
                </h1>
            </div>

            <div className="my-10">
                <table className="m-auto w-[80%]" id="Collections">
                    <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
                        <tr className="font-bold mb-5">
                            <th colSpan={3} className="border-2 border-black p-1 bg-[#FFFF00]">AIRTEL TALK TIME REPORT</th>
                        </tr>
                        <tr className="font-bold mb-5">
                            <th className="border-2 border-black p-1">SOURCE</th>
                            <th className=" border-2 border-black p-1">TOTAL CALLS</th>
                            <th className=" border-2 border-black p-1">TOTAL DUARTION</th>
                        </tr>
                    </thead>
                    <tbody className="bg-[#9BC1E6]">
                        <tr className="font-bold mb-5">
                            <th className="border-2 border-black p-1 bg-[#DEAA78]">PVT</th>
                            <td className=" border-2 text-center border-black p-1">
                                {grandTotalSummary.pvtCalls}
                            </td>
                            <th className=" border-2 border-black p-1">
                                {secondsToHMS(grandTotalSummary.pvtDuration)}
                            </th>
                        </tr>
                        <tr className="font-bold mb-5">
                            <th className="border-2 border-black p-1 bg-[#DEAA78]">GST</th>
                            <td className=" border-2 text-center border-black p-1">
                                {grandTotalSummary.gstCalls}
                            </td>
                            <th className=" border-2 text-center border-black p-1">
                                {secondsToHMS(grandTotalSummary.gstDuration)}
                            </th>
                        </tr>
                        <tr className="font-bold mb-5">
                            <th className="border-2 border-black p-1 bg-[#DEAA78]">TOTAL</th>
                            <td className=" border-2 text-center border-black p-1">
                                {grandTotalSummary.totalCalls}
                            </td>
                            <th className=" border-2 text-center border-black p-1">
                                {grandTotalSummary.totalDuration}
                            </th>
                        </tr>
                        <tr className="font-bold mb-5">
                            <th colSpan={2} className="border-2 border-black p-1 bg-[#FFFF00]">TOTAL Collections</th>
                            <td className=" border-2 text-center bg-[#FFFF00] border-black p-1">
                                {/* {grandTotalSummary.totalInterested} */}
                                {/*0 ₹ */}
                                {/* {collection} ₹ */}
                                {grandTotalSummary.collection} ₹

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className='flex justify-center'>
                <table className="m-auto" id="Collections">
                    <thead className='border-2  border-black mb-5 bg-[#FFFF00]'>
                        <tr className='font-bold mb-5'>
                            <th className='mb-5 border-2 border-black p-1'>Sr. Number</th>
                            <th className='mb-5 border-2 border-black p-1'>Employee Name</th>
                            <th className='mb-5 border-2 border-black p-1'>Employee Number</th>


                            <th className='mb-5 border-2 border-black p-1'>
                                PVT
                            </th>
                            <th className='mb-5 border-2 border-black p-1'>GST</th>

                            <th className='mb-5 border-2 border-black p-1'>AvgCallDuration</th>
                            <th className='mb-5 border-2 border-black p-1'>TotalDuration</th>
                            <th className='mb-5 border-2 border-black p-1'>TotalCalls</th>
                            <th className='mb-5 border-2 border-black p-1'>Collections</th>
                        </tr>
                    </thead>
                    <tbody className='pt-5'>

                        {/* {dataSource && dataSource.map((item, index) => ( */}
                        {displayedData.map((item, index) => (
                            <tr key={index}>
                                <td
                                    className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                                >
                                    {recordsPerPage * (currentPage - 1) + (index + 1)}
                                </td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{item.Agent_Name}</td>
                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{item.Agent_Number}</td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black `}>
                                    <div className="flex flex-col items-center">
                                        <span>{item.PVT.totalCalls}</span>
                                    </div>
                                </td>
                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black flex-col`}>
                                    <div className="flex flex-col items-center">
                                        <span>{item.GST.totalCalls}</span>

                                    </div>
                                </td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{secondsToHMS(item.avgCallDuration)}</td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{secondsToHMS(item.totalDuration)}</td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{item.totalCalls}</td>

                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>
                                    {collection[item.Agent_Number.slice(-10)] ? collection[item.Agent_Number.slice(-10)] + " ₹" : 0 + " ₹"}
                                </td>
                            </tr>
                        ))}
                        <tr className='bg-[#9BC1E6]'>
                            <td colSpan={3} className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>GRAND TOTAL</td>

                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{grandTotal.pvtCalls}</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{grandTotal.gstCalls}</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>-</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{grandTotal.totalDuration}</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{grandTotal.totalCalls}</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}>{grandTotal.collection + " ₹"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pagination flex justify-center">
                {Array.from({
                    length: Math.ceil(dataSource.length / recordsPerPage),
                }).map((_, index) => (
                    <button
                        className={`text-center align-center w-8 h-8 bg-orange-400 shadow-md hover:text-lg transition-transform duration-300 ease-in-out ${index + 1 === currentPage ? "animate-bounce" : ""
                            } hover:shadow-lg rounded-md font-semibold mt-4 mx-3`}
                        key={index}
                        onClick={() => handlePagination(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div >
    )
}

export default AirtelReportDownload