import client from "./client";

export const sendWhatsappBc = async (obj) => {
  try {
    const { data } = await client.post(`/whatsapp/send`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const createCronjobs = async (obj) => {
  try {
    const { data } = await client.post(`/whatsapp/createCronjobs`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const cleanPhoneNumbers = async (obj) => {
  try {
    const { data } = await client.post(`/whatsapp/cleanPhoneCSV`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const startCronjobs = async (jobId, obj) => {
  try {
    const { data } = await client.post(`/whatsapp/startCronjobs/${jobId}`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// getting template details
export const getTemplates = async () => {
  try {
    const { data } = await client.get(`/whatsapp/getTemplateDetails`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// adding template details
export const addTemplates = async (obj) => {
  try {
    const { data } = await client.post(`/whatsapp/addTemplateDetails`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const pauseCronjobs = async (jobId) => {
  try {
    const { data } = await client.get(`/whatsapp/pauseCronjobs/${jobId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const deleteCronjobs = async (jobId) => {
  try {
    const { data } = await client.delete(`/whatsapp/deleteCronjobs/${jobId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateCronjobs = async (jobId, obj) => {
  try {
    const { data } = await client.put(`/whatsapp/updateCronjobs/${jobId}`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const resumeCronjobs = async (jobId) => {
  try {
    const { data } = await client.get(`/whatsapp/resumeCronjobs/${jobId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const tirggerdCronjob = async (jobId) => {
  try {
    const { data } = await client.get(`/whatsapp/tirggerdCronjob/${jobId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAccountDetails = async () => {
  try {
    const { data } = await client.get(`/whatsapp/getAccountDetails`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCronjobsInfo = async () => {
  try {
    const { data } = await client.get(`/whatsapp/getCronjobsInfo`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getOutcomesOfBroadCast = async (date) => {
  try {
    const { data } = await client.post(
      `/summary/get-broadcast-outcome-report`,
      {
        date,
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAnalysis = async (date) => {
  try {
    const { data } = await client.get(`/whatsapp/get-Analysis/${date}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
