import client from "./client";

export const getDataDateWise = async (startDate, endDate, incomingType) => {
  try {
    const { data } = await client.get(
      `/incoming/getDataDateWise?startDate=${startDate}&endDate=${endDate}&incomingType=${incomingType}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getTodaysAggregatedRecords = async (incomingType) => {
  try {
    const { data } = await client.get(
      `/incoming/getTodaysAggregatedRecords/${incomingType}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAllAggregatedRecords = async (startDate, endDate, type) => {
  try {
    const { data } = await client.get(`/incoming/getAllAggregatedRecords`, {
      params: { startDate, endDate, type },
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getIncomingReport = async () => {
  try {
    const { data } = await client.get(`/incoming/getIncomingReport`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getIncoming1800Graph = async () => {
  try {
    const { data } = await client.post(`/incoming/insight_incoming`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
