import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ summaryData }) => {
  const [selectedOption, setSelectedOption] = useState("TACN5339");
  const [account, setAccount] = useState([]);
  const option = [];

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Answered Calls",
        data: [],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Missed Calls",
        data: [],
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  });

  const graphData = () => {
    let hours = [];
    let accountsSet = new Set();
    let formatedData = {};

    if (summaryData) {
      Object.keys(summaryData).forEach((hour) => {
        hours.push(hour);
        Object.keys(summaryData[hour]).forEach((account) => {
          accountsSet.add(account);
          if (!formatedData[account]) {
            formatedData[account] = {
              answeredCall: [],
              missedCall: [],
            };
          }
          //   if (!formatedData[account][hour]) {
          //     formatedData[account][hour] = {
          //       totalAnswered: 0,
          //       totalMissed: 0,
          //     };
          //   }

          formatedData[account]?.answeredCall.push(
            summaryData[hour][account]["total_answered_calls"]
          );
          //     summaryData[hour][account]["total_answered_calls"];
          formatedData[account]?.missedCall.push(
            summaryData[hour][account]["total_missed_calls"]
          );
          //     summaryData[hour][account]["total_missed_calls"];
        });
      });

      setAccount(accountsSet);
    }

    const data = {
      times: hours,
      answeredCalls: formatedData[selectedOption]?.answeredCall, // Replace with your actual data
      missedCalls: formatedData[selectedOption]?.missedCall, // Replace with your actual data
    };

    setChartData((prevState) => ({
      ...prevState,
      labels: data.times,
      datasets: [
        {
          ...prevState.datasets[0],
          data: data?.answeredCalls,
        },
        {
          ...prevState.datasets[1],
          data: data?.missedCalls,
        },
      ],
    }));
  };

  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    graphData();
  }, [selectedOption]);

  useEffect(() => {
    let hours = [];
    let accountsSet = new Set();
    let formatedData = {};

    if (summaryData) {
      Object.keys(summaryData).forEach((hour) => {
        hours.push(hour);
        Object.keys(summaryData[hour]).forEach((account) => {
          accountsSet.add(account);
          if (!formatedData[account]) {
            formatedData[account] = {
              answeredCall: [],
              missedCall: [],
            };
          }

          formatedData[account]?.answeredCall.push(
            summaryData[hour][account]["total_answered_calls"]
          );

          formatedData[account]?.missedCall.push(
            summaryData[hour][account]["total_missed_calls"]
          );
        });
      });

      setAccount(accountsSet);
    }

    const data = {
      times: hours,
      answeredCalls: formatedData[selectedOption]?.answeredCall,
      missedCalls: formatedData[selectedOption]?.missedCall,
    };

    setChartData((prevState) => ({
      ...prevState,
      labels: data.times,
      datasets: [
        {
          ...prevState.datasets[0],
          data: data?.answeredCalls,
        },
        {
          ...prevState.datasets[1],
          data: data?.missedCalls,
        },
      ],
    }));
  }, []);

  return (
    <div>
      <div>
        <select
          value={selectedOption}
          onChange={handleOptionSelect}
          className="border border-black p-1 px-3 rounded-sm"
        >
          {/* Mapping through options and creating option elements */}
          {[...account].map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div>
        <Bar data={chartData} />
      </div>
    </div>
  );
};

export default BarChart;
