import React, { useRef } from "react";
import moment from "moment";
import html2canvas from "html2canvas";
import {
  DownloadOutlined,
  CopyOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { Tabs } from "antd";
import {
  formatDateRange,
  handleCopyImage,
  handleWhatsappSend,
} from "../../../helper";

const DialerTable = ({
  dialerData,
  selectedOption,
  incomingType,
  startDate,
  endDate,
}) => {
  const tableRef = useRef(null);
  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const captureAndDownload = () => {
    const element = document.getElementById("capture"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `incoming-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className="sm:rounded-lg my-5 rounded-md w-[800px] flex items-center flex-col mx-auto">
      <div className="flex mr-[40rem] pb-1 space-x-4">
        <button onClick={captureAndDownload}>
          <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button onClick={() => handleCopyImage(tableRef)} className="">
          <CopyOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button
          onClick={async () =>
            await handleWhatsappSend(
              tableRef,
              `${
                incomingType === "CHENNAI" ? "*TEAM CHENNAI - " : "*"
              }TOLLFREE AND WEBSITE TALKTIME REPORT ${await formatDateRange(
                startDate,
                endDate
              )}*`
            )
          }
        >
          <WhatsAppOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
      </div>
      <table
        className="table-auto  w-11/12 mx-auto  text-sm  rounded-md"
        id="capture"
        ref={tableRef}
      >
        <thead className="text-black uppercase font-bold bg-[#FEFF00]">
          {selectedOption === "Select Type" && (
            <>
              <tr>
                <th
                  colSpan="5"
                  className=" text-base border text-center pb-2 border-black font-semibold "
                >
                  {`${
                    incomingType === "CHENNAI" ? "Team Chennai - " : ""
                  }TOLLFREE AND WEBSITE TALKTIME REPORT ${moment().format(
                    "DD-MM-YYYY"
                  )}`}
                </th>
              </tr>

              <tr>
                <th className="pb-2  border border-black text-center">
                  Sr. No
                </th>
                <th className="  border pb-2 border-black text-center">Name</th>
                <th className="  border pb-2 border-black text-center">
                  Answered Calls
                </th>
                <th className="  border pb-2 border-black text-center">
                  Total Call Duration
                </th>
                <th className=" border pb-2 border-black text-center">
                  Avg. Mins
                </th>
              </tr>
            </>
          )}
        </thead>
        <tbody className="font-bold">
          {Object.entries(dialerData).map(([name, item], index) => {
            let totalAnsweredCalls = 0;
            let totalMissedCalls = dialerData[index].total_missed_calls;
            let totalCallDuration = 0;
            let totalAverageMinPerAnsweredCall = 0;
            let idx = 0;
            return (
              <React.Fragment key={name}>
                {/* Separator row */}
                {selectedOption === "Day wise" && (
                  <>
                    <tr className="text-black uppercase font-bold bg-[#FEFF00]">
                      <td
                        colSpan="5"
                        className=" text-base border text-center pb-2 border-black font-semibold "
                      >
                        {`TOLLFREE AND WEBSITE TALKTIME REPORT ${moment(
                          item.call_date
                        ).format("DD-MM-YYYY")}`}
                      </td>
                    </tr>

                    <tr className="text-black uppercase font-bold bg-[#FEFF00]">
                      <th className="pb-2  border border-black text-center">
                        Sr. No
                      </th>
                      <th className="  border pb-2 border-black text-center">
                        Name
                      </th>
                      <th className="  border pb-2 border-black text-center">
                        Answered Calls
                      </th>
                      <th className="  border pb-2 border-black text-center">
                        Total Call Duration
                      </th>
                      <th className=" border pb-2 border-black text-center">
                        Avg. Mins
                      </th>
                    </tr>
                  </>
                )}

                {Object.entries(dialerData[index].agent_wise_data).map(
                  ([agentName, agentSummary]) => {
                    if (agentName !== "") {
                      idx++;
                      totalAnsweredCalls += agentSummary.total_answered_calls;
                      totalCallDuration += agentSummary.total_call_duration;
                      totalAverageMinPerAnsweredCall += Math.round(
                        agentSummary.average_min_ans_call
                      );
                    }
                    return (
                      agentName !== "" && (
                        <tr key={agentName}>
                          <td className="border pb-2 border-black  text-center">
                            {idx}
                          </td>
                          <td className="border pb-2 border-black  text-center">
                            {agentName
                              .replace("Extension", "")
                              .trim()
                              .toUpperCase()}
                          </td>
                          <td className="border pb-2 border-black  text-center">
                            {agentSummary.total_answered_calls}
                          </td>

                          <td className="border pb-2 border-black  text-center">
                            {formatTime(agentSummary.total_call_duration)}
                          </td>
                          <td className="border pb-2 border-black text-center">
                            {Math.floor(agentSummary.average_min_ans_call / 60)}
                          </td>
                        </tr>
                      )
                    );
                  }
                )}
                <tr className="bg-[#FEFF00]">
                  <td
                    colSpan="2"
                    className="border pb-2 border-black font-semibold text-center uppercase"
                  >
                    Total
                  </td>

                  <td className="border pb-2 border-black font-semibold text-center">
                    {totalAnsweredCalls}
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    {formatTime(totalCallDuration)}
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    {Math.floor(totalCallDuration / (totalAnsweredCalls * 60))}
                  </td>
                  <td colSpan="4"></td>
                </tr>
                <tr className="bg-[#F5AE82]">
                  <td
                    colSpan="2"
                    className="border pb-2 border-black font-semibold text-center uppercase"
                  >
                    Missed Call
                  </td>

                  <td className="border pb-2 border-black font-semibold text-center">
                    {totalMissedCalls}
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    -
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    -
                  </td>

                  <td colSpan="4"></td>
                </tr>
                <tr className="bg-[#9BC0E7]">
                  <td
                    colSpan="2"
                    className="border pb-2 border-black font-semibold text-center uppercase"
                  >
                    Grand Total
                  </td>

                  <td className="border pb-2 border-black font-semibold text-center">
                    {totalAnsweredCalls + (totalMissedCalls || 0)}
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    -
                  </td>
                  <td className="border pb-2 border-black font-semibold text-center">
                    -
                  </td>

                  <td colSpan="4"></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DialerTable;
