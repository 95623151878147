import React, { useEffect, useState } from "react";
import Table from "./MenuTable/Table";
import { getMenuLeads, uploadMenuLeads } from "../../api/menuLeads";

import { DatePicker, Spin } from "antd";
import { CSVLink } from "react-csv";
import { MdDownload } from "react-icons/md";
import MenuTable from "./MenuTable/MenuTable";
import { Switch } from "antd";
import { insertMenuLeads } from "../../api/menuLeads";
import toast from "react-hot-toast";

const { RangePicker } = DatePicker;


const MenuLeads = () => {
  // const [allLeads, setAllLeads] = useState([]);
  const [scrapRemovedLeads, setScrapRemovedLeads] = useState([]);
  const [scrapLeads, setScrapLeads] = useState([]);
  const [currentLeads, setCurrentLeads] = useState([]);
  const [menuDate, setMenuDate] = useState(null);
  const [wpLeads, setWpLeads] = useState([]);

  const [typeWiseLeadsAll, setTypeWiseLeadsAll] = useState([]);
  const [scrapRemovedTypeWiseLeads, setScrapRemovedTypeWiseLeads] = useState(
    []
  );
  const [currentTypeWiseLeads, setCurrentTypeWiseLeads] = useState([]);

  const [menuLeadsAll, setMenuLeadsAll] = useState([]);
  const [scrapRemovedMenuLeadsAll, setScrapRemovedMenuLeads] = useState([]);
  const [currentMenuLeadsAll, setCurrentMenuLeads] = useState([]);

  const [startStamp, setStartStamp] = useState("");
  const [endStamp, setEndStamp] = useState("");
  const [loading, setLoading] = useState(false);

  const [scrapRemoved, setScrapRemoved] = useState(false);

  const columnHeadings = ["Sr No", "Source", "Leads", "Remarks"];

  const columnTypeHeadings = ["Sr No", "Type", "Ahmedabad", "Chennai"];

  const [
    notKeptCompaniesWithBlankCompanyName,
    setNotKeptCompaniesWithBlankCompanyName,
  ] = useState([]);
  const [notKeptCompaniesDtmf, setNotKeptCompaniesDtmf] = useState([]);

  const handleChange = (checked) => {
    setScrapRemoved(checked);
  };

  function generateSlabArray(data) {
    const uniqueSlabs = new Set();
    const result = [];

    // Collect unique slabs
    for (const branch in data) {
      for (const slab in data[branch]) {
        if (slab !== "total") {
          uniqueSlabs.add(slab);
        }
      }
    }

    // Generate array of objects with counts for each slab and branch
    uniqueSlabs.forEach((slab) => {
      const obj = { source: slab };
      for (const branch in data) {
        obj[`${branch.toLowerCase() === "adi" ? "leads" : "remarks"}`] =
          data[branch][slab] || 0;
      }
      result.push(obj);
    });

    return result;
  }

  const getLeadsArray = (adiData, chnData) => {
    const sources = new Set([...Object.keys(adiData), ...Object.keys(chnData)]);
    let adiTotal = 0;
    let chnTotal = 0;

    const leadsArray = Array.from(sources).map((source, index) => {
      const adiCount = adiData[source] || 0;
      const chnCount = chnData[source] || 0;
      adiTotal += adiCount;
      chnTotal += chnCount;

      return {
        srNo: index + 1,
        source: source.toUpperCase(),
        adi: adiCount,
        chn: chnCount,
        total: adiCount + chnCount,
        remarks: "Remarks ..........",
      };
    });

    const totalRow = {
      srNo: leadsArray.length + 1,
      source: "TOTAL FRESH LEADS",
      adi: adiTotal,
      chn: chnTotal,
      total: adiTotal + chnTotal,
      remarks: "",
    };

    leadsArray.push(totalRow);

    return leadsArray;
  };

  const getTypeWiseArray = (dataObj) => {
    let typeWiseArray = [];
    if (dataObj.PT) {
      typeWiseArray.push({
        source: "PVT",
        leads: dataObj.PT.ADI || 0,
        remarks: dataObj.PT.CHENNAI || 0,
      });
    }
    if (dataObj.UDY) {
      typeWiseArray.push({
        source: "UDY",
        leads: dataObj.UDY.ADI || 0,
        remarks: dataObj.UDY.CHENNAI || 0,
      });
    }
    if (dataObj.GT) {
      typeWiseArray.push({
        source: "GT",
        leads: dataObj.GT.ADI ? dataObj.GT.ADI.total : 0,
        remarks: dataObj.GT.CHENNAI ? dataObj.GT.CHENNAI.total : 0,
      });
      const slabsArray = generateSlabArray(dataObj.GT);
      typeWiseArray.push(...slabsArray);
    }
    return typeWiseArray;
  };

  const initialize = (data) => {
    setNotKeptCompaniesDtmf(data?.notKeptCompaniesDtmf);
    setNotKeptCompaniesWithBlankCompanyName(
      data?.notKeptCompaniesWithBlankCompanyName
    );

    let allLeadsArray = getLeadsArray(
      data.leadsCount.allCount.ADI,
      data.leadsCount.allCount.CHENNAI
    );
    let scrapRemovedLeadsArray = getLeadsArray(
      data.leadsCount.scrapRemovedCount.ADI,
      data.leadsCount.scrapRemovedCount.CHENNAI
    );

    // console.log(allLeadsArray, scrapRemovedLeadsArray);

    setMenuLeadsAll(allLeadsArray);
    setScrapRemovedMenuLeads(scrapRemovedLeadsArray);

    setTypeWiseLeadsAll(getTypeWiseArray(data.typeWiseCount.allCount));
    setScrapRemovedTypeWiseLeads(
      getTypeWiseArray(data.typeWiseCount.scrapRemovedCount)
    );

    setScrapRemovedLeads(data.filteredCompanies);
    setScrapLeads(data.notKeptCompanies);

    if (scrapRemoved) {
      setCurrentLeads(data.filteredCompanies);
      setCurrentMenuLeads(scrapRemovedLeadsArray);
    } else {
      setCurrentLeads([...data.notKeptCompanies, ...data.filteredCompanies]);
      setCurrentMenuLeads(allLeadsArray);
    }

    setWpLeads(data.whatsappLeads);
  };

  const fetchMenuLeads = async () => {
    setLoading(true);
    const { data } = await getMenuLeads(startStamp, endStamp);
    initialize(data);
    setLoading(false);
  };

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      setStartStamp(start ? start.format("YYYY-MM-DD HH:mm:ss") : "");
      setEndStamp(end ? end.format("YYYY-MM-DD HH:mm:ss") : "");
    } else {
      setStartStamp("");
      setEndStamp("");
    }
  };

  useEffect(() => {
    if (scrapRemoved) {
      setCurrentLeads(scrapRemovedLeads);
      setCurrentMenuLeads(scrapRemovedMenuLeadsAll);
    } else {
      setCurrentLeads([...scrapLeads, ...scrapRemovedLeads]);
      setCurrentMenuLeads(menuLeadsAll);
    }
  }, [scrapRemoved]);

  const nextDayDate = () => {
    if (endStamp === "") return "DD-MM-YYYY";
    // Parse the endStamp to a JavaScript Date object
    const endDate = new Date(endStamp);

    // Add one day to the endStamp
    endDate.setDate(endDate.getDate() + 1);

    // Format the result as 'DD-MM-YYYY'
    const result =
      endDate.getDate().toString().padStart(2, "0") +
      "-" +
      (endDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      endDate.getFullYear();

    return result;
  };

  const allLeadsHeader = [
    { label: "ContactNumber", key: "ContactNumber" },
    { label: "CompanyName", key: "CompanyName" },
    { label: "CompanyEmail", key: "CompanyEmail" },
    { label: "CompanySource", key: "CompanySource" },
    { label: "Service", key: "Service" },
    { label: "Source", key: "Source" },
    { label: "Branch", key: "Branch" },
    { label: "CreatedAt", key: "CreatedAt" },
    { label: "Slab", key: "Slab" },
    { label: "State", key: "State" },
    { label: "Data", key: "Data" },
    { label: "Lead_Source", key: "Lead_Source" },
    { label: "Team", key: "Team" },
  ];
  const whatsappFormLeadsHeader = [
    { label: "ContactNumber", key: "ContactNumber" },
    { label: "CompanyName", key: "CompanyName" },
    { label: "CompanyEmail", key: "CompanyEmail" },
    { label: "CompanySource", key: "CompanySource" },
    { label: "CompanyGst", key: "CompanyGst" },
    { label: "CompanyTurnOver", key: "CompanyTurnOver" },
    { label: "Requirement", key: "Requirement" },
    { label: "Message", key: "Message" },
    { label: "Source", key: "Source" },
    { label: "Branch", key: "Branch" },
    { label: "CreatedAt", key: "CreatedAt" },
    { label: "CompanyEmail", key: "CompanyEmail" },
    { label: "Slab", key: "Slab" },
    { label: "Data", key: "Data" },
    { label: "Lead_Source", key: "Lead_Source" },
  ];

  const downloadAllLeads = () => {
    document.getElementById("all-leads").click();
  };
  const downloadAllScrapLeads = () => {
    document.getElementById("all-scrap-leads").click();
  };
  const downloadBlankAllLeads = () => {
    document.getElementById("not-kept-blank-leads").click();
  };
  const downloadDtmfRemovedAllLeads = () => {
    document.getElementById("not-kept-dtmf").click();
  };
  const downloadWpLeads = () => {
    document.getElementById("wp-leads").click();
  };

  const downloadsaveLeads = async () => {
    // const saveLeadsButton = document.getElementById("save-leads").click();

    // if (saveLeadsButton) {
    //   saveLeadsButton.click();
    // } else {
    //   console.warn("Save leads button not found.");
    // }
    setLoading(true);
    if (!menuDate) {
      toast.error('Select Proper Menu Date !');
      return;
    }
    if (!Array.isArray(currentLeads)) {
      console.error("currentLeads is not an array.");
      return;
    }
    const data = await uploadMenuLeads({
      menuDate: menuDate.format("YYYY-MM-DD"),
      menuLeads: currentLeads,
      scrapLeads,
      removedLeads: [...notKeptCompaniesWithBlankCompanyName, ...notKeptCompaniesDtmf]
    });
    if (data.success) {
      toast.success(data.message);
    } else {
      toast.error(data.error);
    }
    setLoading(false);
  }

  console.log(currentLeads, "currentLeads");

  const reorderedHeaders = [
    { label: "ContactNumber", key: "ContactNumber" },
    { label: "CompanyName", key: "CompanyName" },
    { label: "CompanyEmail", key: "CompanyEmail" },
    { label: "Service", key: "Service" },
    { label: "Source", key: "Source" },
    { label: "Branch", key: "Branch" },
    { label: "Slab", key: "Slab" },
    { label: "State", key: "State" },
    { label: "CompanySource", key: "CompanySource" },
    { label: "CreatedAt", key: "CreatedAt" },
    { label: "Data", key: "Data" },
    { label: "Lead_Source", key: "Lead_Source" },
    { label: "Team", key: "Team" },
  ];

  const reorderData = (data) => {
    return data.map(item => {
      return {
        ContactNumber: item.ContactNumber,
        CompanyName: item.CompanyName,
        CompanyEmail: item.CompanyEmail,
        Service: item.Service,
        Source: item.Source,
        Branch: item.Branch,
        Slab: item.Slab,
        State: item.State,
        CompanySource: item.CompanySource,
        CreatedAt: item.CreatedAt,
        Data: item.Data,
        Lead_Source: item.Lead_Source,
        Team: item.Team,
      };
    });
  };

  return (
    <div>
      <p className="text-3xl text-center my-10">~ Fresh Menu Leads ~</p>

      <div className="flex items-center space-x-4 m-4">
        <RangePicker
          showTime={{ format: "HH:mm:ss" }}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={handleDateChange}
        />
        <Spin spinning={loading}>
          <button
            className="p-2 bg-blue-500 rounded-md text-white"
            onClick={() => fetchMenuLeads()}
          >
            Get Leads
          </button>
        </Spin>
        <button
          className="p-2 bg-blue-500 flex justify-center items-center space-x-1 rounded-md text-white"
          onClick={() => downloadAllLeads()}
        >
          <p>All Leads</p>
          <MdDownload />
        </button>
        <button
          className="p-2 bg-blue-500 flex justify-center items-center space-x-1 rounded-md text-white"
          onClick={() => downloadAllScrapLeads()}
        >
          <p>All Scrap Leads</p>
          <MdDownload />
        </button>
        <button
          className="p-2 bg-blue-500 flex justify-center items-center space-x-1 rounded-md text-white"
          onClick={() => downloadBlankAllLeads()}
        >
          <p>Blank Company Name Leads</p>
          <MdDownload />
        </button>
        <button
          className="p-2 bg-blue-500 flex justify-center items-center space-x-1 rounded-md text-white"
          onClick={() => downloadDtmfRemovedAllLeads()}
        >
          <p>Dtmf Removed Leads</p>
          <MdDownload />
        </button>
        <button
          className="p-2 bg-blue-500 flex justify-center items-center space-x-1 rounded-md text-white"
          onClick={() => downloadWpLeads()}
        >
          <p>Wp Forms Leads</p>
          <MdDownload />
        </button>



        <Switch
          className=""
          style={{
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.3)",
          }}
          checked={scrapRemoved}
          onChange={handleChange}
        />
        <p className="font-semibold">Turn on to Get Scrap Removed Menu !</p>
        <CSVLink
          id="all-leads"
          // data={currentLeads}
          data={reorderData(currentLeads)}
          // headers={allLeadsHeader}
          headers={reorderedHeaders}
          filename={`allLeads${nextDayDate()}.csv`}
        />
        <CSVLink
          id="all-scrap-leads"
          data={scrapLeads}
          headers={allLeadsHeader}
          filename={`allScrapLeads${nextDayDate()}.csv`}
        />
        <CSVLink
          id="not-kept-blank-leads"
          data={notKeptCompaniesWithBlankCompanyName}
          headers={allLeadsHeader}
          filename={`notKeptCompaniesWithBlankCompanyName${nextDayDate()}.csv`}
        />
        <CSVLink
          id="not-kept-dtmf"
          data={notKeptCompaniesDtmf}
          headers={allLeadsHeader}
          filename={`notKeptCompaniesDtmf${nextDayDate()}.csv`}
        />
        <CSVLink
          id="wp-leads"
          data={wpLeads}
          headers={whatsappFormLeadsHeader}
          filename={`wpLeads${nextDayDate()}.csv`}
        />
      </div>
      <div className="flex items-center space-x-4 m-4">
        <DatePicker onChange={(date) => setMenuDate(date)} className="ml-4" />
        <Spin spinning={loading}>
          <button
            className="p-2 bg-red-500 flex justify-center items-center space-x-1 rounded-md text-white"
            onClick={() => downloadsaveLeads()}
          >
            <p>Save Menu</p>
            <MdDownload />
          </button>
        </Spin>
      </div>
      <div className="flex justify-around mt-5">
        <MenuTable
          endStamp={endStamp}
          data={currentMenuLeadsAll}
          loading={loading}
          header={`${nextDayDate()} Menu Leads`}
        />
      </div>
      <div className="flex justify-around my-5">
        <Table
          data={scrapRemoved ? scrapRemovedTypeWiseLeads : typeWiseLeadsAll}
          columnHeadings={columnTypeHeadings}
          loading={loading}
          header={`${nextDayDate()} Type Wise Leads`}
        />
      </div>
    </div>
  );
};

export default MenuLeads;
