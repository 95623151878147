import React, { useState, useEffect } from "react";
import { Upload, Button, Select, Form, Input, message, Spin } from "antd";
import { getTemplates, sendWhatsappMessages } from "../../api/egniol_updates";
import Papa from "papaparse";
import toast from "react-hot-toast";

const { Dragger } = Upload;
const { Option } = Select;

const Egniol_Updates = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [tenantId, setTenantId] = useState("340776"); // Replace with actual tenant ID
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [variables, setVariables] = useState([]);

  const getTemplatesName = async () => {
    try {
      // Attempt to fetch templates from the API
      const data = await getTemplates();
      setTemplates(data);
    } catch (error) {
      // Log the error for debugging purposes
      toast.error("Error fetching templates:", error);

      // Handle specific error cases (optional)
      if (error.response) {
        // If the API responded with an error status
        toast.error("API error response:", error.response.data);

        if (error.response.status === 404) {
          toast.error("Templates not found.");
        } else if (error.response.status === 500) {
          toast.error("Server error. Please try again later.");
        } else {
          toast.error(`Error: ${error.response.statusText}`);
        }
      } else if (error.request) {
        // If the request was made but no response was received
        toast.error("No response from server:", error.request);
        toast.error("No response from server. Please check your connection.");
      } else {
        // Any other error (e.g., request setup)
        toast.error("Request setup error:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  // Fetch WhatsApp templates on component load
  useEffect(() => {
    getTemplatesName();
  }, []);

  // Handle template selection
  const handleTemplateChange = (value) => {
    const selected = templates.find(
      (template) => template.template_name === value
    );
    if (selected) {
      setSelectedTemplate(selected);
      setVariables(selected.variables);
    } else {
      setVariables([]);
    }
  };

  // Handle file upload and parse CSV
  const handleFileChange = (file) => {
    if (file) {
      setFile(file);
      parseCsvFile(file);
    }
  };

  // Parse CSV file using PapaParse
  const parseCsvFile = (file) => {
    Papa.parse(file, {
      header: true, // Reads the header row as keys
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data;
        setCsvData(data);
        message.success(
          `${file.name} count (${data.length}) uploaded successfully.`
        );
      },
      error: function (error) {
        message.error("Failed to parse the CSV file.");
        console.error(error);
      },
    });
  };

  // Send messages based on CSV data
  const sendMessages = async () => {
    if (!selectedTemplate || csvData.length === 0) {
      toast.error("Please select a template and upload a CSV file.");
      return;
    }

    setLoading(true);

    try {
      // Extract phone numbers and parameters from CSV
      const payload = {
        template_name: selectedTemplate.template_name,
        broadcast_name: selectedTemplate.template_name,
        tenantId: tenantId,
        numbers: csvData.map((row) => {
          const phoneNumber = row.phone_number;
          const parameters = variables.map((paramName) => ({
            name: paramName,
            value: row[paramName],
          }));

          return {
            phone_number: phoneNumber,
            parameters: parameters,
          };
        }),
      };

      // Send the request
      const data = await sendWhatsappMessages(payload);

      // Check if the response has an error
      if (data.error) {
        toast.error(`Failed to send messages: ${data.error}`);
      } else {
        toast.success(data.message);
      }
    } catch (error) {
      console.error("Error sending messages:", error);

      // Check if it's a network or server error
      if (error.response) {
        // Server responded with a status outside of 2xx
        toast.error(
          `Server error: ${
            error.response.data.message || "Failed to send messages."
          }`
        );
      } else if (error.request) {
        // Request was made but no response was received
        toast.error("Network error: No response from server.");
      } else {
        // Something else happened while setting up the request
        toast.error(`Error: ${error.message}`);
      }
    } finally {
      // Always clear the loading state
      setLoading(false);
    }
  };

  const downloadSampleCsv = () => {
    if (!selectedTemplate) {
      message.warning("Please select a template first.");
      return;
    }

    const headers = ["phone_number", ...variables]; // Assuming variables are the dynamic fields
    const sampleRow = ["917486038157", ...variables.map(() => "SampleValue")]; // Example row with sample values
    const csvContent = [headers, sampleRow].map((e) => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute(
      "download",
      `${selectedTemplate.template_name}_sample.csv`
    );
    downloadLink.click();
  };

  return (
    <div style={{ padding: "20px" }}>
      <Form layout="vertical">
        <Form.Item label="Select Template">
          <Select
            placeholder="Select a template"
            onChange={handleTemplateChange}
            style={{ width: "100%" }}
          >
            {templates.map((template) => (
              <Option
                key={template.template_name}
                value={template.template_name}
              >
                {template.template_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Upload CSV File">
          <Dragger
            name="file"
            beforeUpload={(file) => {
              handleFileChange(file);
              return false; // Prevent automatic upload
            }}
            fileList={file ? [file] : []}
            onRemove={() => setFile(null)}
          >
            <p className="ant-upload-drag-icon">
              <i className="fas fa-upload"></i>
            </p>
            <p className="ant-upload-text">
              Click or drag CSV file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Ensure the CSV has a "phone_number" column and the dynamic
              variable columns.
            </p>
          </Dragger>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={sendMessages}
            loading={loading}
            disabled={!file}
          >
            Send Messages
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="default"
            onClick={downloadSampleCsv}
            disabled={!selectedTemplate}
          >
            Download Sample CSV
          </Button>
        </Form.Item>
      </Form>

      {loading && (
        <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
      )}
    </div>
  );
};

export default Egniol_Updates;
