import React, { useEffect, useState } from "react";
import { getOutcomesOfBroadCast } from "../../api/whatsapp";
import IncomingChennaiBroadcastTable from "./Tables/IncomingChennaiBroadcastTable";
import { HiRefresh } from "react-icons/hi";
import { Spin } from "antd";
import IncomingCallToActionBroadcastTable from "./Tables/incomingCallToActionBroadcastTable";
import HourWiseApiReplyGraph from "./Garph/HourWiseApiReplyGraph";

const Broadcast = () => {
  const [outComes, setOutComes] = useState(null);
  const [hourWiseApiReplyCount, setHourWiseApiReplyCount] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const fetchOutcomesOfBroadCast = async (date) => {
    setLoading(true);
    const data = await getOutcomesOfBroadCast(date);
    const outcomes = data.data.outcomes;
    setHourWiseApiReplyCount(data.data.hourWiseApiReplyCount);
    setOutComes(outcomes);
    setLoading(false);
  };

  const refreshData = async () => {
    if (selectedDate) {
      fetchOutcomesOfBroadCast(selectedDate);
    } else {
      const currentDate = new Date().toISOString().split("T")[0];
      fetchOutcomesOfBroadCast(currentDate);
    }
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (selectedDate && Object.keys(outComes).length === 0)
      fetchOutcomesOfBroadCast(currentDate);
  }, []);

  useEffect(() => {
    if (selectedDate) fetchOutcomesOfBroadCast(selectedDate);
  }, [selectedDate]);

  return (
    <div>
      <p className="text-3xl text-center">Broadcast</p>
      <input
        type="date"
        className="mb-10"
        value={selectedDate}
        onChange={handleDateChange}
      />
      <Spin spinning={loading}>
        <button
          onClick={refreshData}
          className="mr-auto text-xl bg-none text-blue-500"
        >
          <HiRefresh />
        </button>
        {outComes && (
          <>
            {/*<IncomingChennaiBroadcastTable chennaiOutcomes={outComes.chennai} />*/}
            <IncomingCallToActionBroadcastTable otherOutcomes={outComes} />
            <p className="text-3xl text-center my-4">Api Reply</p>
            <div
              style={{
                height: "30rem",
              }}
              class="p-4 my-4 rounded-3xl lg:col-span-3 flex items-center justify-center "
            >
              <HourWiseApiReplyGraph chartData={hourWiseApiReplyCount} />
            </div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default Broadcast;
