import client from "./client";

// { view("string") }
// Views Options
// ** BASED IN THIS THE QUERY RESULT WILL BE FETCHED ! **

// total_calls_per_day_view
// total_calls_per_month_view
// freq_call_done_by_agent_to_customer
// freq_call_done_by_agent_to_customer_per_day
// freq_call_done_by_agent_to_customer_per_month
// freq_call_done_by_customer_to_agent
// freq_call_done_by_customer_to_agent_per_day
// freq_call_done_by_customer_to_agent_per_month

export const getRecording = async (mobileNumber) => {
  try {
    const { data } = await client.get(
      `/airtel/downloadRecording/${mobileNumber}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAirtelSIMCallsData = async (obj) => {
  try {
    const { data } = await client.post(`/airtel/view`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAirtelSIMCallsDataSummary = async ({ view, modalNum }) => {
  try {
    const { data } = await client.post(`/airtel/summary`, { view, modalNum });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCompanyNames = async (obj) => {
  try {
    const { data } = await client.post(`/airtel/get-company-name`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getClassWiseEmployeeData = async () => {
  try {
    const { data } = await client.get(`/airtel/agent-to-customer-data`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

//getDailyReportData
export const getDailyReportData = async () => {
  try {
    const { data } = await client.get(
      `/summary/build-dailerSourceWise-summary`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCompanyDetails = async (obj) => {
  try {
    const { data } = await client.post(`/airtel/get-company-details`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

/*This 3 most important functions*/
export const getUnkownCalls = async () => {
  try {
    const { data } = await client.get(`/airtel/get-unknown-calls`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getClientCalls = async () => {
  try {
    const { data } = await client.get(`/airtel/get-client-calls`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getEmployeeCalls = async () => {
  try {
    const { data } = await client.get(`/airtel/get-employee-calls`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getEmployeesData = async () => {
  try {
    const { data } = await client.get(`/airtel/employees-data`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getDNDList = async (obj) => {
  try {
    const { data } = await client.get(`/airtel/get-dnd-numbers-list`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAggregatedData = async (obj) => {
  try {
    const { data } = await client.post(
      `/airtel/get-dialer-aggregated-data`,
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getCurrentDateDialerAggregatedData = async (obj) => {
  try {
    const { data } = await client.get(
      `/airtel/get-current-date-dialer-aggregated-data`,
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getAirtelAggregatedData = async () => {
  try {
    const { data } = await client.get(`/airtel/get-airtel-aggregated-data`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getAirtelCallsData = async () => {
  try {
    const { data } = await client.get(`/airtel/get-airtel-calls-data`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getDNDNumberRecords = async (obj) => {
  try {
    const { data } = await client.get(`/airtel/get-dnd-numbers-records`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCollectionData = async () => {
  try {
    const { data } = await client.get(`/airtel/get-employee-collections`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCollectionsData = async () => {
  try {
    const { data } = await client.get(`/airtel/get-employee-collection`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAgentCustomerData = async () => {
  try {
    const { data } = await client.get(`/airtel/agent-to-customer-all-calls`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCompaignDataSummary = async () => {
  try {
    const { data } = await client.get(`/airtel/compaignSummary`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAgentCustomerDailerData = async () => {
  try {
    const { data } = await client.get(
      `/airtel/agent-to-customer-all-calls-dialer`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCollectionDataAll = async () => {
  try {
    const { data } = await client.get(`/airtel/get-employee-collections-all`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getCollectionEmployeeClient = async () => {
  try {
    const { data } = await client.get(
      `/airtel/get-employee-company-collections`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getFalseDNDInputData = async (dateString) => {
  try {
    const { data } = await client.get(
      `/airtel/get-false-dispo-information/${dateString}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAirtelMissedReport = async (startDate, endDate) => {
  try {
    const { data } = await client.get(
      `/airtel/airtel-missed-report?startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
