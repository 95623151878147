import React, { createContext, useEffect, useState } from "react";
import { getAccountDetails } from "../api/whatsapp";

const QrtContext = createContext();

const QrtProvider = ({ children }) => {
    const [accountDetails, setAccountDetails] = useState(null);

    useEffect(() => {
        const fetchAccountDetails = async () => {
            try {
                const response = await getAccountDetails();
                if (response.success) {
                    setAccountDetails(response.responses);
                } else {
                    console.log("Error fetching account details");
                }
            } catch (error) {
                console.log("Error fetching account details:", error);
            }
        };

        fetchAccountDetails();
    }, []);

    const contextValues = {
        accountDetails,
    };

    return (
        <QrtContext.Provider value={contextValues}>
            {children}
        </QrtContext.Provider>
    );
};

export { QrtContext, QrtProvider };
