import { useState, useContext, useEffect } from "react";
import { Modal, Space, Table, Tag, Popover, Select, Tooltip } from "antd";
import { RiRestartFill } from "react-icons/ri";
import { PiPaperPlaneRightDuotone } from "react-icons/pi";
import { GrResume } from "react-icons/gr";

import csvSample from "../../../csv/remove_dnd_scrap.csv";
import {
  createCronjobs,
  updateCronjobs,
  deleteCronjobs,
  getCronjobsInfo,
  pauseCronjobs,
  resumeCronjobs,
  startCronjobs,
  cleanPhoneNumbers,
  tirggerdCronjob,
} from "../../../api/whatsapp";

import { FaDownload } from "react-icons/fa6";
import toast from "react-hot-toast";
import Papa from "papaparse";
import moment from "moment-timezone";
import cronstrue from "cronstrue";
import CronePopOver from "./CronePopOver";
import { QrtContext } from "../../../Context/QrtContext";
import {
  ExclamationCircleOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  DeleteFilled,
  EditFilled,
} from "@ant-design/icons";
import { Checkbox } from "antd";

const Schedule = () => {
  //using context , set account drop down dynamically.
  const qrtContext = useContext(QrtContext);
  const { accountDetails } = qrtContext;
  const { Option } = Select;

  const [accountOptions, setAccountOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");

  useEffect(() => {
    if (accountDetails && accountDetails.length > 0) {
      const netcoreAccounts = accountDetails
        .filter((item) => item.source === "airtel")
        .map((item) => item.account.accountname);
      setAccountOptions(netcoreAccounts);
    }
  }, [accountDetails]);

  useEffect(() => {
    if (selectedAccount && accountDetails && accountDetails.length > 0) {
      const selectedAccountObject = accountDetails.find(
        (item) => item.account.accountname === selectedAccount
      );
      if (selectedAccountObject) {
        setTemplateOptions(
          selectedAccountObject.account.templates.map(
            (template) => template.tempName
          )
        );
      }
    } else {
      setTemplateOptions([]);
    }
  }, [selectedAccount, accountDetails]);

  const [cronJobDetails, setCronJobDetails] = useState([]);
  const [inEditCronJobDetails, setInEditCronJobDetails] = useState({
    phoneNumbers: [],
    cronPattern: "",
    templateName: [],
    service: "",
    account: "",
    source: "",
    slabSize: "",
  });
  const [inEditCronJobPhoneDetails, setInEditCronJobPhoneDetails] = useState({
    phoneNumbers: [],
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [numbers2, setNumbers2] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const { confirm } = Modal;

  const showConfirm = async (action, record) => {
    confirm({
      title: `Are you sure you want to ${action} this cronjob?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        let data;
        if (action === "start") {
          data = await startCronjobs(record.id, { isRestart: false });
        } else if (action === "paused") {
          data = await pauseCronjobs(record.id);
        } else if (action === "resumed") {
          data = await resumeCronjobs(record.id);
        } else if (action === "deleted") {
          data = await deleteCronjobs(record.id);
        } else if (action === "restart") {
          data = await startCronjobs(record.id, { isRestart: true });
        } else if (action === "triggeredCronjob") {
          data = await tirggerdCronjob(record.id);
        }
        if (data?.success) {
          let status = "active";
          if (action === " paused") {
            status = "paused";
          } else if (action === "resumed") {
            status = "active";
          } else if (action === "deleted") {
            status = "deleted";
          }
          let updatedCronJobDetails;
          if (status !== "deleted") {
            updatedCronJobDetails = cronJobDetails.map((job) => {
              if (job.id === record.id) {
                return {
                  ...job,
                  status: status,
                };
              }
              return job;
            });
          } else {
            updatedCronJobDetails = cronJobDetails.filter(
              (job) => job.id !== record.id
            );
          }
          setCronJobDetails(updatedCronJobDetails);
          toast.success(data.message);
        } else {
          toast.error(data.error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleEdit = (jobId) => {
    const filterById = cronJobDetails.filter((item) => item.id === jobId);

    setInEditCronJobDetails({
      id: filterById[0].id,
      cronjobName: filterById[0].cronjob_name,
      cronPattern: filterById[0].cron_pattern,
      templateName: JSON.parse(filterById[0].template_name),
      account: filterById[0].account,
      service: filterById[0].service,
      slabSize: filterById[0].slabSize,
      source: filterById[0].source,
    });
    setSelectedAccount(filterById[0].account);
    setIsModalVisible(true);
  };

  const color = {
    paused: "#FFA500",
    active: "geekblue",
    inActive: "#F87315",
  };

  const columns = [
    {
      title: "Job Id",
      dataIndex: "id",
      key: "id",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Name",
      dataIndex: "cronjob_name",
      key: "cronjob_name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Time",
      dataIndex: "cron_pattern",
      key: "cron_pattern",
      render: (text, record, index) => {
        const feedback = cronstrue.toString(record.cron_pattern);
        const shortFeedback =
          feedback.length > 25 ? feedback.substring(0, 15) + "..." : feedback;

        return (
          <Popover content={<CronePopOver feedBack={feedback} />}>
            <div
              className="bg-transparent -mr-4 font-semibold border-r"
              title={feedback}
            >
              {record.cron_pattern}
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Slab Size",
      dataIndex: "slabSize",
      key: "slabSize",
    },
    {
      title: "Total",
      dataIndex: "total_numbers",
      key: "total_numbers",
    },
    {
      title: "Sent",
      dataIndex: "total_sent",
      key: "total_sent",
    },
    {
      title: "Last sent",
      dataIndex: "last_sent_time",
      key: "last_sent_time",
      render: (text) => (
        <span>
          {text &&
            moment(text).tz("Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, record) => (
        <>
          <Tag color={color[record.status]} key={record.id}>
            {record?.status?.toUpperCase()}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" className="flex ">
          {record.status === "inActive" && (
            <Tooltip title="Start">
              <PlayCircleFilled
                className="text-xl text-green-500"
                onClick={() => showConfirm("start", record)}
              />
            </Tooltip>
          )}
          {record.status === "active" && (
            <Tooltip title="Pause">
              <PauseCircleFilled
                className=" text-orange-500 text-xl"
                onClick={() => showConfirm("paused", record)}
              />
            </Tooltip>
          )}
          {record.status === "paused" && (
            <Tooltip title="Resume">
              <GrResume
                className="text-xl text-yellow-500 cursor-pointer"
                onClick={() => showConfirm("resumed", record)}
              />
            </Tooltip>
          )}
          <Tooltip title="Restart">
            <RiRestartFill
              className="text-2xl cursor-pointer text-cyan-500"
              onClick={() => showConfirm("restart", record)}
            />
          </Tooltip>
          <Tooltip title="Instant Send">
            <PiPaperPlaneRightDuotone
              className="text-2xl cursor-pointer text-orange-500"
              onClick={() => showConfirm("triggeredCronjob", record)}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <DeleteFilled
              onClick={() => showConfirm("deleted", record)}
              className=" text-red-500  text-xl"
            />
          </Tooltip>
          <Tooltip title="Edit">
            <EditFilled
              onClick={() => handleEdit(record.id)}
              className=" text-gray-500  text-xl"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const mappedKeys = {
    ["contactNumber"]: ["Mobile"],
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModal2 = () => {
    setIsModal2Visible(true);
  };

  const handleCancel = () => {
    setNumbers([]);
    setInEditCronJobDetails({
      phoneNumbers: [],
      cronPattern: "",
      cronjobName: "",
      templateName: [],
      service: "",
      account: "",
      source: "",
      slabSize: "",
    });
    setTemplateOptions([]);
    setIsModalVisible(false);
  };

  const handleCancel2 = () => {
    setNumbers2([]);
    setInEditCronJobDetails({
      phoneNumbers: [],
    });
    setIsModal2Visible(false);
  };

  const handleOk = async () => {
    const obj = { ...inEditCronJobDetails, phoneNumbers: numbers };
    if (inEditCronJobDetails.id) {
      // If inEditCronJobDetails has an id, it means it's an existing cron job, so we update it
      const updatedData = await updateCronjobs(inEditCronJobDetails.id, obj);
      if (updatedData?.success) {
        toast.success(updatedData.message);
      } else {
        toast.error(updatedData.error);
      }
    } else {
      if (obj.phoneNumbers.length === 0) {
        toast.error("Please upload a file with phone numbers");
        return;
      }
      //If inEditCronJobDetails doesn't have an id, it means it's a new cron job, so we create it
      const createdData = await createCronjobs(obj);
      if (createdData?.success) {
        toast.success(createdData.message);
      } else {
        toast.error(createdData.error);
      }
    }

    await getCronJobData();
    setInEditCronJobDetails({
      phoneNumbers: [],
      cronPattern: "",
      cronjobName: "",
      templateName: [],
      service: "",
      account: "",
      source: "",
      slabSize: "",
    });

    setNumbers([]);
    setDataSource([]);
    setSelectedFiles([]);
    const fileInput = document.getElementById("mobileNumbers");
    fileInput.value = "";

    setIsModalVisible(false);
  };

  const handleOk2 = async () => {
    const obj = { ...inEditCronJobPhoneDetails, phoneNumbers: numbers };
    if (obj.phoneNumbers.length === 0) {
      toast.error("Please upload a file with phone numbers");
      return;
    }
    //If inEditCronJobDetails doesn't have an id, it means it's a new cron job, so we create it
    const createdData = await cleanPhoneNumbers(obj);
    if (createdData?.success) {
      console.log(createdData.result, "filteredData");
      toast.success(createdData.message);
    } else {
      toast.error(createdData.error);
    }

    const csvData = [];
    // If there is data
    if (createdData.result.length > 0) {
      // Extract headers from the first item
      const headers = Object.keys(createdData.result[0]);
      csvData.push(headers);

      createdData.result.forEach((row) => {
        const rowData = headers.map((header) => row[header]);
        csvData.push(rowData);
      });
    }

    // Create CSV content
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Create Blob
    const blob = new Blob([csvContent], { type: "text/csv" });
    const blobUrl = URL.createObjectURL(blob);

    // Create temporary link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", "clean_phone_numbers.csv");

    // Append link to body and trigger click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);

    setNumbers2([]);
    setSelectedFiles([]);
    const fileInput = document.getElementById("mobileNumbers");
    fileInput.value = "";

    setIsModalVisible(false);
  };

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray);
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null;
  }

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  const processData = () => {
    let report = [];
    dataSource.forEach((row) => {
      if (row.contactNumber.length === 12) {
        report.push({
          mobileNumber: row.contactNumber,
          parameters: row.parameters,
        });
      } else if (row.contactNumber.length === 10) {
        report.push({
          mobileNumber: "91" + row.contactNumber,
          parameters: row.parameters,
        });
      }
    });
    setNumbers(report);
  };

  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let headers = Object.keys(results.data[0]);
          let contactNumberKey = headers.filter((key) => getKeyForElement(key));
          let parameterKeys = headers.filter((key) => key !== "Mobile");

          results.data.forEach((row) => {
            let obj = {
              contactNumber: row[contactNumberKey],
              parameters: [],
            };

            parameterKeys.forEach((key) => {
              obj.parameters.push(row[key]);
            });

            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const getCronJobData = async () => {
    const data = await getCronjobsInfo();
    if (data.success) {
      setCronJobDetails(data.data);
    }
  };

  const cronOptions = [
    { name: "Every minute", value: "* * * * *" },
    { name: "Every hour", value: "0 * * * *" },
    { name: "Every 15 minutes", value: "*/15 * * * *" },
    { name: "Every 30 minutes", value: "*/30 * * * *" },
    {
      name: "Every 10 minutes, between 10:00 AM and 04:59 PM",
      value: "0 */10 10-16 * * *",
    },
    {
      name: "Every 10 minutes, at 10:00 AM through 12:00 PM and 01:00 PM through 04:59 PM, Monday through Saturday",
      value: "*/10 10-12,13-16 * * 1-6",
    },
    {
      name: "Every hour, at 10:00 AM through 12:00 PM and 01:00 PM through 05:00 PM, Monday through Saturday",
      value: "0 10-12,13-17 * * 1-6",
    },
    {
      name: "Every hour, at 09:00 AM through 12:00 PM and 01:00 PM through 08:00 PM, Monday through Saturday",
      value: "0 9-12,13-20 * * 1-6",
    },
    { name: "Other", value: "other" },
  ];

  const sourceOptions = [
    { name: "Netcore", value: "netcore" },
    { name: "Omni", value: "omni" },
    { name: "Airtel", value: "airtel" },
  ];

  const handleDropdownChange = (value) => {
    if (value === "other") {
      const manualInput = window.prompt("Enter custom cron pattern:");
      setInEditCronJobDetails({
        ...inEditCronJobDetails,
        cronPattern: manualInput,
      });
    } else {
      setInEditCronJobDetails({
        ...inEditCronJobDetails,
        cronPattern: value,
      });
    }
  };

  const handleSourceChange = (value) => {
    setInEditCronJobDetails({
      ...inEditCronJobDetails,
      source: value,
    });
  };

  useEffect(() => {
    getCronJobData();
  }, []);

  useEffect(() => {
    if (dataSource.length !== 0) {
      processData();
    }
  }, [dataSource]);

  let inputClassName =
    "px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full bg-gray-100";
  return (
    <div>
      <div className="bg-white p-8 rounded-lg max-h-4/5 mx-auto border-2 border-gray-300">
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          size="lg"
        >
          <form>
            <div className="grid grid-cols-2 gap-4 ">
              <div>
                <label
                  htmlFor="cronjobName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cron Job Name
                </label>
                <input
                  type="text"
                  name="cronjobName"
                  className={inputClassName}
                  value={inEditCronJobDetails.cronjobName}
                  onChange={(e) =>
                    setInEditCronJobDetails({
                      ...inEditCronJobDetails,
                      cronjobName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="cronPattern"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cron Pattern
                </label>
                <Select
                  name="cronPattern"
                  value={inEditCronJobDetails.cronPattern}
                  onChange={handleDropdownChange}
                  style={{ width: "100%" }}
                >
                  {cronOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Account"
                  className="block text-sm font-medium text-gray-700"
                >
                  Account
                </label>

                <select
                  name="account"
                  value={inEditCronJobDetails.account}
                  //value={selectedAccount}
                  className={inputClassName}
                  onChange={(e) => {
                    setInEditCronJobDetails({
                      ...inEditCronJobDetails,
                      account: e.target.value,
                    });
                    setSelectedAccount(e.target.value);
                  }}
                  required
                >
                  <option value="" disabled>
                    Select Account
                  </option>
                  {accountOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="templateName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Template Name
                </label>

                <Checkbox.Group
                  className={inputClassName}
                  value={inEditCronJobDetails.templateName}
                  onChange={(checkedValues) => {
                    setInEditCronJobDetails({
                      ...inEditCronJobDetails,
                      templateName: checkedValues, // Store selected options as an array
                    });
                    setSelectedTemplate(checkedValues); // Update selected template
                  }}
                  options={templateOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="service"
                  className="block text-sm font-medium text-gray-700"
                >
                  Service
                </label>
                <input
                  type="text"
                  name="service"
                  id="service"
                  className={inputClassName}
                  value={inEditCronJobDetails.service}
                  onChange={(e) =>
                    setInEditCronJobDetails({
                      ...inEditCronJobDetails,
                      service: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="source"
                  className="block text-sm font-medium text-gray-700"
                >
                  Source
                </label>

                <Select
                  name="source"
                  value={inEditCronJobDetails.source}
                  onChange={handleSourceChange}
                  style={{ width: "100%" }}
                >
                  {sourceOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="templateName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slab Size
                </label>
                <input
                  type="text"
                  name="slabSize"
                  id="slabSize"
                  className={inputClassName}
                  value={inEditCronJobDetails.slabSize}
                  onChange={(e) =>
                    setInEditCronJobDetails({
                      ...inEditCronJobDetails,
                      slabSize: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="mobileNumbers"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Numbers
                </label>
                <input
                  type="file"
                  accept=".csv"
                  name="mobileNumbers"
                  id="mobileNumbers"
                  onChange={handleFileInputChange}
                  className={inputClassName}
                  required
                />
              </div>
            </div>
          </form>
        </Modal>

        <Modal open={isModal2Visible} onOk={handleOk2} onCancel={handleCancel2}>
          <form>
            <div>
              <h2 className="text-2xl font-bold mb-4 text-center">
                {/* CSV Form */}
                <Tooltip title="Sample csv file">
                  <a
                    href={csvSample}
                    download="remove_dnd_scrap.csv"
                    className=" mx-5 text-blue-500 rounded inline-block"
                  >
                    <p className="flex">
                      Sample csv...
                      <FaDownload />
                    </p>
                  </a>
                </Tooltip>
              </h2>
            </div>
            <div className="mt-4">
              <label
                htmlFor="mobileNumbers"
                className="block text-sm font-medium text-gray-700"
              >
                Mobile Numbers
              </label>
              <input
                type="file"
                accept=".csv"
                name="mobileNumbers"
                id="mobileNumbers"
                onChange={handleFileInputChange}
                className={inputClassName}
                required
              />
            </div>
          </form>
        </Modal>

        <div className="flex justify-end mb-2">
          <button
            onClick={showModal}
            className="text-white bg-blue-500 px-2 py-1 text-sm rounded-lg"
          >
            Add +
          </button>
          <button
            onClick={showModal2}
            className="mx-3 text-white bg-blue-500 px-2 py-1 text-sm rounded-lg"
          >
            Clean CSV
          </button>
        </div>
        <Table columns={columns} dataSource={cronJobDetails} />
      </div>
    </div>
  );
};

export default Schedule;
