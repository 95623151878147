import React, { useState } from "react";
import {
  dialerReport,
  IvrReport,
  menuReport,
  pythonReport,
  TypewiseReport,
} from "../../api/pythonReport";
import toast from "react-hot-toast";
const ReportDashboard = () => {
  const [dropdownValue, setDropdownValue] = useState("");
  const [textareaValue, setTextareaValue] = useState("");

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    setTextareaValue("");
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleDownload = async () => {
    try {
      if (dropdownValue === "") {
        toast.error("Please Select an option");
        return;
      } else if (dropdownValue === "Disposition Report") {
        const response = await pythonReport(textareaValue);
        let { base64Image } = response;
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${base64Image}`;
        link.download = "report.jpeg";
        link.click();
      } else if (dropdownValue === "AutoDialer Report") {
        const response = await dialerReport(textareaValue);
        let { base64Image } = response;
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${base64Image}`;
        link.download = "report.jpeg";
        link.click();
      } else if (dropdownValue === "Menu") {
        const response = await menuReport(textareaValue);
        let { base64Image } = response;
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${base64Image}`;
        link.download = "report.jpeg";
        link.click();
      } else if (dropdownValue === "IVR") {
        const response = await IvrReport(textareaValue);
        let { base64Image } = response;
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${base64Image}`;
        link.download = "report.jpeg";
        link.click();
      } else if (dropdownValue === "Typewise") {
        const response = await TypewiseReport(textareaValue);
        let { base64Image } = response;
        const link = document.createElement("a");
        link.href = `data:image/jpeg;base64,${base64Image}`;
        link.download = "report.jpeg";
        link.click();
      }

      // Create a blob from the base64 string and generate a URL for download
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Dashboard</h1>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Select an option:</label>
          <select
            value={dropdownValue}
            onChange={handleDropdownChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          >
            <option value="">--Please choose an option--</option>
            <option value="Disposition Report">Disposition Report</option>
            <option value="AutoDialer Report">AutoDialer Report</option>
            <option value="Menu">Menu</option>
            <option value="IVR">IVR</option>
            <option value="Typewise">Typewise</option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Enter text:</label>
          <textarea
            value={textareaValue}
            onChange={handleTextareaChange}
            rows="4"
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="text-center">
          <button
            onClick={handleDownload}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
          >
            Generate and Download Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportDashboard;
