export const DailyCallsHeader = [
  { label: "AGENT NAME", key: "Agent_Name" },
  { label: "AGENT NUMBER", key: "Agent_Number" },
  // { label: "DID CALLS FREQ.", key: "Did_Calls" },
  // { label: "TOTAL DID CALLS DURATION(MINUTES)", key: "Did_Duration" },
  // { label: "INBOUND CLIENT CALLS FREQ.", key: "Inbound_Client_Calls" },
  // {
  //   label: "INBOUND CLIENT CALLS DURATION (MINUTES)",
  //   key: "Inbound_Client_Duration",
  // },
  // { label: "OUBOUND CLIENT CALLS FREQ.", key: "Outbound_Client_Calls" },
  // {
  //   label: "OUTBOUND CLIENT CALLS DURATION (MINUTES)",
  //   key: "Outbound_Client_Duration",
  // },
  { label: "TOTAL AWNSERED CALLS", key: "Total_Awnsered" },
  { label: "TOTAL CALLS DURATION (MINUTES)", key: "Total_Duration" },
  { label: "AVG. MIN PER CALL", key: "Average_Min_Per_Call" },
];