import React, { useEffect, useState } from "react";

function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const DialerReportDownload = ({ dataSource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [displayedData, setDisplayedData] = useState([]);
  const [grandTotal, setGrandTotal] = useState({
    pvtCalls: 0,
    gstCalls: 0,
    totalCalls: 0,
    totalDuration: 0,
    totalInterested: 0,
  });

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [grandTotalSummary, setGrandTotalSummary] = useState({});
  useEffect(() => {
    let gTotalSummary = {
      pvtCalls: 0,
      gstCalls: 0,
      unknownCalls: 0,
      pvtDuration: 0,
      gstDuration: 0,
      unknownDuration: 0,
      totalCalls: 0,
      totalDuration: 0,
      avgDuration: 0,
      totalInterested: 0,
    };
    dataSource.forEach((item) => {
      if (dataSource.Agent_Name !== null) {
        gTotalSummary.pvtCalls += item.ALL.PVT.totalCalls;
        gTotalSummary.gstCalls += item.ALL.GST.totalCalls;
        gTotalSummary.unknownCalls += item.ALL.UNKNOWN.totalCalls;

        gTotalSummary.gstDuration += item.ALL.GST.totalDuration;
        gTotalSummary.pvtDuration += item.ALL.PVT.totalDuration;
        gTotalSummary.unknownDuration += item.ALL.UNKNOWN.totalDuration;

        gTotalSummary.totalCalls +=
          item.ALL.PVT.totalCalls +
          item.ALL.GST.totalCalls +
          item.ALL.UNKNOWN.totalCalls;
        gTotalSummary.totalDuration +=
          item.ALL.PVT.totalDuration +
          item.ALL.GST.totalDuration +
          item.ALL.UNKNOWN.totalDuration;
        gTotalSummary.totalInterested +=
          item.ALL.PVT.totalInterested +
          item.ALL.GST.totalInterested +
          item.ALL.UNKNOWN.totalInterested;
      }
    });

    gTotalSummary.totalDuration = secondsToHMS(gTotalSummary.totalDuration);
    setGrandTotalSummary(gTotalSummary);
  }, [dataSource]);

  useEffect(() => {
    const sortedData = [...dataSource].sort(
      (a, b) => b.totalDuration - a.totalDuration
    );

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    let gTotal = {
      pvtCalls: 0,
      gstCalls: 0,
      unknownCalls: 0,
      totalCalls: 0,
      totalDuration: 0,
    };

    currentRecords.forEach((item) => {
      if (item.Agent_Name !== null) {
        gTotal.pvtCalls += item.ALL.PVT.totalCalls;
        gTotal.gstCalls += item.ALL.GST.totalCalls;
        gTotal.unknownCalls += item.ALL.UNKNOWN.totalCalls;

        gTotal.totalCalls +=
          item.ALL.PVT.totalCalls +
          item.ALL.GST.totalCalls +
          item.ALL.UNKNOWN.totalCalls;
        gTotal.totalDuration +=
          item.ALL.PVT.totalDuration +
          item.ALL.GST.totalDuration +
          item.ALL.UNKNOWN.totalDuration;
      }
    });
    gTotal.totalDuration = secondsToHMS(gTotal.totalDuration);
    setGrandTotal(gTotal);
    setDisplayedData(currentRecords);
  }, [dataSource, currentPage, recordsPerPage]);

  return (
    <div>
      <div className="justify-center mt-5 mb-5">
        <h1 className="p-5 text-center text-3xl font-semibold">
          <span className="border-black p-2 rounded">Dialer Report</span>
        </h1>
      </div>
      <div className="my-10">
        <table className="m-auto w-[80%]" id="Collections">
          <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
            <tr className="font-bold mb-5">
              <th
                colSpan={3}
                className="border-2 border-black p-1 bg-[#FFFF00]"
              >
                DAILER TALK TIME REPORT
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1">SOURCE</th>
              <th className=" border-2 border-black p-1">TOTAL CALLS</th>
              <th className=" border-2 border-black p-1">TOTAL DUARTION</th>
            </tr>
          </thead>
          <tbody className="bg-[#9BC1E6]">
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1 bg-[#DEAA78]">PVT</th>
              <td className=" border-2 text-center border-black p-1">
                {grandTotalSummary.pvtCalls}
              </td>
              <th className=" border-2 border-black p-1">
                {secondsToHMS(grandTotalSummary.pvtDuration)}
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1 bg-[#DEAA78]">GST</th>
              <td className=" border-2 text-center border-black p-1">
                {grandTotalSummary.gstCalls}
              </td>
              <th className=" border-2 text-center border-black p-1">
                {secondsToHMS(grandTotalSummary.gstDuration)}
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1 bg-[#DEAA78]">OTHER</th>
              <td className=" border-2 text-center border-black p-1">
                {grandTotalSummary.unknownCalls}
              </td>
              <th className=" border-2 text-center border-black p-1">
                {secondsToHMS(grandTotalSummary.unknownDuration)}
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1 bg-[#DEAA78]">TOTAL</th>
              <td className=" border-2 text-center border-black p-1">
                {grandTotalSummary.totalCalls}
              </td>
              <th className=" border-2 text-center border-black p-1">
                {grandTotalSummary.totalDuration}
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th
                colSpan={2}
                className="border-2 border-black p-1 bg-[#FFFF00]"
              >
                TOTAL INTERESTED
              </th>
              <td className=" border-2 text-center bg-[#FFFF00] border-black p-1">
                {grandTotalSummary.totalInterested}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex justify-center w-full">
        <table className="m-auto w-[80%]" id="Collections">
          <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1">SR. NO</th>
              <th className=" border-2 border-black p-1">EMPLOYEE NAME</th>
              <th className=" border-2 border-black ">PVT CALLS</th>
              <th className=" border-2 border-black p-1">GST CALLS</th>
              <th className=" border-2 border-black p-1">OTHER CALLS</th>
              <th className=" border-2 border-black p-1">TOTAL CALLS</th>
              <th className=" border-2 border-black p-1">TOTAL DURATION</th>
              <th className=" border-2 border-black p-1">AVG. DURATION</th>
            </tr>
          </thead>
          <tbody className="pt-5">
            {/* {dataSource && dataSource.sort((a, b) => b.totalCalls - a.totalCalls).dataSource.map((item, index) => ( */}
            {displayedData.map((item, index) => (
              <tr key={index}>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {recordsPerPage * (currentPage - 1) + (index + 1)}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {item.Agent_Name}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black flex-col`}
                >
                  <div className="flex flex-row items-center justify-center">
                    <span>{item.ALL.PVT.totalCalls}</span>
                  </div>
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black `}
                >
                  <tr className="flex flex-row items-center justify-center">
                    <td>{item.ALL.GST.totalCalls}</td>
                  </tr>
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black `}
                >
                  <tr className="flex flex-row items-center justify-center">
                    <td>{item.ALL.UNKNOWN.totalCalls}</td>
                  </tr>
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {item.ALL.PVT.totalCalls +
                    item.ALL.GST.totalCalls +
                    item.ALL.UNKNOWN.totalCalls}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {secondsToHMS(
                    item.ALL.PVT.totalDuration +
                      item.ALL.GST.totalDuration +
                      item.ALL.UNKNOWN.totalCalls
                  )}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {secondsToHMS(
                    Math.ceil(
                      (item.ALL.PVT.totalDuration +
                        item.ALL.GST.totalDuration +
                        item.ALL.UNKNOWN.totalCalls) /
                        (item.ALL.PVT.totalCalls +
                          item.ALL.GST.totalCalls +
                          item.ALL.UNKNOWN.totalCalls)
                    )
                  )}
                </td>
              </tr>
            ))}
            <tr className="bg-[#9BC1E6]">
              <td
                colSpan={2}
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                GRAND TOTAL
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.pvtCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.gstCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.unknownCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.totalCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.totalDuration}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                -
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pagination flex justify-center">
        {Array.from({
          length: Math.ceil(dataSource.length / recordsPerPage),
        }).map((_, index) => (
          <button
            className={`text-center align-center w-8 h-8 bg-orange-400 shadow-md hover:text-lg transition-transform duration-300 ease-in-out ${
              index + 1 === currentPage ? "animate-bounce" : ""
            } hover:shadow-lg rounded-md font-semibold mt-4 mx-3`}
            key={index}
            onClick={() => handlePagination(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DialerReportDownload;
