import React, { useState, useEffect } from "react";
import { getCollectionsData } from "../../../api/airtel";
import Select from "react-select";

// CSV Utils
import { CSVLink } from "react-csv";
import { AirtelAgentHeaders } from "../CSVHeaders/Airtel_Agent_Report_Headers";

function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const EmployeeSelection = ({ data, onSelect }) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);

  // Extract employee numbers from the data
  const employeeNumbers = data.map((item) => ({
    value: item.Agent_Number,
    label: item.Agent_Number,
  }));

  const handleSelectChange = (selectedOptions) => {
    const numbers = selectedOptions.map((option) => option.value);
    setSelectedNumbers(numbers);
    onSelect(numbers);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Select Employee Numbers:</h2>
      <Select
        options={employeeNumbers}
        isMulti
        value={employeeNumbers.filter((option) =>
          selectedNumbers.includes(option.value)
        )}
        onChange={handleSelectChange}
        className="mb-2"
        placeholder="Select employee numbers..."
        isSearchable
        isClearable
      />
    </div>
  );
};
const Agent_Report = ({ dataSource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [displayedData, setDisplayedData] = useState([]);

  const [filteredDataSource, setFilteredDataSource] = useState([]);

  const [grandTotal, setGrandTotal] = useState({
    pvtCalls: 0,
    gstCalls: 0,
    totalCalls: 0,
    totalDuration: 0,
  });
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSelect = (selectedEmployeeNumbers) => {
    // Filter the dataSource based on the selected employee numbers
    const filteredData = dataSource.filter((item) =>
      selectedEmployeeNumbers.includes(item.Agent_Number)
    );
    setFilteredDataSource(filteredData);
  };

  const formatDataForCsv = () => {
    const formattedData = filteredDataSource.map((item) => {
      return {
        Agent_Number: item.Agent_Number,
        Agent_Name: item.Agent_Name,
        inboundCalls:
          item.PVT.INBOUND.totalCalls +
          item.GST.INBOUND.totalCalls +
          item.UNKNOWN.INBOUND.totalCalls,
        inboundDuration: secondsToHMS(
          item.PVT.INBOUND.totalDuration +
            item.GST.INBOUND.totalDuration +
            item.UNKNOWN.INBOUND.totalDuration
        ),
        outboundCalls:
          item.PVT.OUTBOUND.totalCalls +
          item.GST.OUTBOUND.totalCalls +
          item.UNKNOWN.OUTBOUND.totalCalls,
        outboundDuration: secondsToHMS(
          item.PVT.OUTBOUND.totalDuration +
            item.GST.OUTBOUND.totalDuration +
            item.UNKNOWN.OUTBOUND.totalDuration
        ),
        totalCalls:
          item.PVT.totalCalls + item.GST.totalCalls + item.UNKNOWN.totalCalls,
        totalDuration: secondsToHMS(
          item.PVT.totalDuration +
            item.GST.totalDuration +
            item.UNKNOWN.totalDuration
        ),
      };
    });
    return formattedData;
  };

  function downloadCsv() {
    document.getElementById("agent-resport-download").click();
  }

  useEffect(() => {
    console.log(filteredDataSource);
    const sortedData = [...filteredDataSource].sort(
      (a, b) => b.totalDuration - a.totalDuration
    );

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedData.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );

    let gTotal = {
      inboundCalls: 0,
      inboundDuration: 0,
      outboundCalls: 0,
      outboundDuration: 0,
      totalCalls: 0,
      totalDuration: 0,
    };

    currentRecords.forEach((item) => {
      if (item.Agent_Name !== null) {
        gTotal.inboundCalls +=
          item.PVT.INBOUND.totalCalls +
          item.GST.INBOUND.totalCalls +
          item.UNKNOWN.INBOUND.totalCalls;
        gTotal.inboundDuration +=
          item.PVT.INBOUND.totalDuration +
          item.GST.INBOUND.totalDuration +
          item.UNKNOWN.INBOUND.totalDuration;
        gTotal.outboundCalls +=
          item.PVT.OUTBOUND.totalCalls +
          item.GST.OUTBOUND.totalCalls +
          item.UNKNOWN.OUTBOUND.totalCalls;
        gTotal.outboundDuration +=
          item.PVT.OUTBOUND.totalDuration +
          item.GST.OUTBOUND.totalDuration +
          item.UNKNOWN.OUTBOUND.totalDuration;
        gTotal.totalCalls +=
          item.PVT.totalCalls + item.GST.totalCalls + item.UNKNOWN.totalCalls;
        gTotal.totalDuration +=
          item.PVT.totalDuration +
          item.GST.totalDuration +
          item.UNKNOWN.totalDuration;
      }
    });

    gTotal.inboundDuration = secondsToHMS(gTotal.inboundDuration);
    gTotal.outboundDuration = secondsToHMS(gTotal.outboundDuration);
    gTotal.totalDuration = secondsToHMS(gTotal.totalDuration);

    setGrandTotal(gTotal);
    setDisplayedData(currentRecords);
  }, [filteredDataSource, currentPage, recordsPerPage]);

  return (
    <div>
      <div className="justify-center mt-5 mb-5">
        <h1 className="p-1 text-center text-3xl font-semibold">
          <span className="border-black  p-1 rounded">Airtel Report</span>
        </h1>
      </div>

      <EmployeeSelection data={dataSource} onSelect={onSelect} />

      <CSVLink
        id="agent-resport-download"
        data={formatDataForCsv(filteredDataSource)}
        headers={AirtelAgentHeaders}
        filename={`AirtelAgentReport.csv`}
      />
      <button className="text-blue" onClick={downloadCsv}>
        download
      </button>
      <p className="text-red-500 text-center font-semibold">
        ***** NOTE: HERE THE NAMES MAPPING COULD BE DIFFRENT *****
      </p>
      <div className="flex justify-center">
        <table className="m-auto" id="Collections">
          <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
            <tr className="font-bold mb-5">
              <th className="mb-5 border-2 border-black p-1">Sr. No</th>
              <th className="mb-5 border-2 border-black p-1">Employee Name</th>
              <th className="mb-5 border-2 border-black p-1">
                Employee Number
              </th>

              <th className="mb-5 border-2 border-black p-1">INBOUND CALLS</th>
              <th className="mb-5 border-2 border-black p-1">INBOUND D.</th>

              <th className="mb-5 border-2 border-black p-1">OUTBOUND CALLS</th>
              <th className="mb-5 border-2 border-black p-1">OUTBOUND D.</th>
              <th className="mb-5 border-2 border-black p-1">TotalCalls</th>
              <th className="mb-5 border-2 border-black p-1">TotalDuration</th>
            </tr>
          </thead>
          <tbody className="pt-5">
            {/* {dataSource && dataSource.map((item, index) => ( */}
            {displayedData.map((item, index) => (
              <tr key={index}>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {recordsPerPage * (currentPage - 1) + (index + 1)}
                </td>

                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {item.Agent_Name}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {item.Agent_Number}
                </td>

                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black `}
                >
                  <div className="flex flex-col items-center">
                    <span>
                      {item.PVT.INBOUND.totalCalls +
                        item.GST.INBOUND.totalCalls +
                        item.UNKNOWN.INBOUND.totalCalls}
                    </span>
                  </div>
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black flex-col`}
                >
                  <div className="flex flex-col items-center">
                    <span>
                      {secondsToHMS(
                        item.PVT.INBOUND.totalDuration +
                          item.GST.INBOUND.totalDuration +
                          item.UNKNOWN.INBOUND.totalDuration
                      )}
                    </span>
                  </div>
                </td>

                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  <span>
                    {item.PVT.OUTBOUND.totalCalls +
                      item.GST.OUTBOUND.totalCalls +
                      item.UNKNOWN.OUTBOUND.totalCalls}
                  </span>
                </td>

                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {secondsToHMS(
                    item.PVT.OUTBOUND.totalDuration +
                      item.GST.OUTBOUND.totalDuration +
                      item.UNKNOWN.OUTBOUND.totalDuration
                  )}
                </td>

                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {item.PVT.totalCalls +
                    item.GST.totalCalls +
                    item.UNKNOWN.totalCalls}
                </td>
                <td
                  className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
                >
                  {secondsToHMS(
                    item.PVT.totalDuration +
                      item.GST.totalDuration +
                      item.UNKNOWN.totalDuration
                  )}
                </td>
              </tr>
            ))}
            <tr className="bg-[#9BC1E6]">
              <td
                colSpan={3}
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                GRAND TOTAL
              </td>

              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.inboundCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.inboundDuration}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.outboundCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.outboundDuration}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.totalCalls}
              </td>
              <td
                className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-1 font-bold border-black`}
              >
                {grandTotal.totalDuration}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pagination flex justify-center">
        {Array.from({
          length: Math.ceil(filteredDataSource.length / recordsPerPage),
        }).map((_, index) => (
          <button
            className={`text-center align-center w-8 h-8 bg-orange-400 shadow-md hover:text-lg transition-transform duration-300 ease-in-out ${
              index + 1 === currentPage ? "animate-bounce" : ""
            } hover:shadow-lg rounded-md font-semibold mt-4 mx-3`}
            key={index}
            onClick={() => handlePagination(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Agent_Report;
