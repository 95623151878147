import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import { useNavigate } from "react-router-dom";
import Incoming1800 from "./Incoming1800";
import Chennai from "./Chennai";
import Broadcast from "./BroadcastIncoming";
import Disporeport from "./Disporeport";

const Incoming = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const items = [
    {
      key: "1",
      label: "1800 Incoming",
      children: <Incoming1800 activeTab={activeTab} />,
    },
    {
      key: "2",
      label: "Chennai",
      children: <Chennai activeTab={activeTab} />,
    },
    {
      key: "3",
      label: "Broadcast",
      children: <Broadcast activeTab={activeTab} />,
    },
    {
      key: "4",
      label: "Dipso",
      children: <Disporeport activeTab={activeTab} />,
    },
  ];

  const handleOnChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="container mx-auto mt-5">
      <Tabs defaultActiveKey="1" items={items} onChange={handleOnChange} />
    </div>
  );
};

export default Incoming;
