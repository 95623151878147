import React, { useState } from "react";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { getRecording } from "../../../api/airtel"; // Check the import path

const Recording = () => {
    const [formData, setFormData] = useState({ mobileNo: "" });
    const [recordings, setRecordings] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!/^\d{10}$/.test(formData.mobileNo)) {
            toast.error("Please enter a valid 10-digit mobile number.");
            setLoading(false);
            return;
        }

        try {
            const data = await getRecording(formData.mobileNo);
            if (data.success) {
                console.log("Data fetched successfully:", data.results);
                toast.success(data.message);
                setRecordings(data.results); // Update state with fetched recordings
            } else {
                toast.error("No recordings found for this mobile number.");
            }
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const downloadCSV = () => {
        const headers = ["Recording URL", "Destination Number", "Caller Number", "Date"];
        const rows = recordings.map(recording => [
            recording.Recording,
            recording.Destination_Number,
            recording.Caller_Number,
            recording.Date
        ]);

        const csvContent = [
            headers.join(","), 
            ...rows.map(row => row.join(","))
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", `recordings_${formData.mobileNo}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Mobile No.
                    </label>
                    <input
                        type="text"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={handleChange}
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter 10-digit mobile number"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Submit
                </button>
            </form>

            {recordings.length > 0 && (
                <div className="mt-4">
                    <button
                        onClick={downloadCSV}
                        className="bg-green-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Download CSV
                    </button>
                </div>
            )}

            {!loading && recordings.length > 0 ? (
                <div className="recordings-container p-4 bg-gray-100 rounded-lg shadow-md mt-4">
                    <h2 className="text-2xl font-bold text-center ml-3 mb-4">Recordings:</h2>
                    <ol className="list-decimal list-inside">
                        <li className="mb-2 flex items-center" >
                            <span className="ml-5 font-bold">Recording</span>
                            <span className="ml-5 font-bold">Destination_Number</span>
                            <span className="ml-5 font-bold">Caller_Number</span>
                            <span className="ml-5 font-bold">Date</span>
                        </li>
                    </ol>
                    <ol className="list-decimal list-inside">
                        {recordings.map((recording, index) => (
                            <li className="mb-2 flex items-center" key={index}>
                                <span className="mr-3 text-lg font-semibold">{index + 1}.</span> {/* Added numbering */}
                                <a
                                    href={recording.Recording}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800 underline"
                                >
                                    🎙️ Recording {index + 1}
                                </a>
                                <span className="ml-3 font-bold">{recording.Destination_Number}</span>
                                <span className="ml-3 font-bold">{recording.Caller_Number}</span>
                                <span className="ml-3 font-bold">{recording.Date}</span>
                            </li>
                        ))}
                    </ol>
                </div>
            ) : loading ? (
                <div className="flex justify-center items-center mt-4">
                    <Spin />
                </div>
            ) : null}
        </div>
    );
};

export default Recording;
