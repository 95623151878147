import React from "react";
import { Tabs } from "antd";
import ReportDropDown from "./ReportDropDown";
import ReportDashboard from "../ReportPython/ReportDashboard";
import Disporeport from "./DispoReport";

const { TabPane } = Tabs;

const Tab_Dashboard = () => {
  return (
    <Tabs defaultActiveKey="1" className="p-10">
      <TabPane tab="ReportDashboard" key="1">
        <ReportDropDown />
      </TabPane>
      <TabPane tab="PythonReport" key="2">
        {/* <AnotherComponent /> */}
        <ReportDashboard />
      </TabPane>
      <TabPane tab="DispoReport" key="3">
        <Disporeport />
      </TabPane>
    </Tabs>
  );
};

export default Tab_Dashboard;
