import React, { useState } from "react";
import { CSVLink } from "react-csv";
import "tailwindcss/tailwind.css";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import Table from "./Table/CallHippoTable";
import ReportTable from "./Table/ReportTable";
import { ConfigProvider, DatePicker, Space } from "antd";
import { getCallerStatistics, getCalls } from "../../api/callhippo";

const CallHippo = () => {
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [agentStates, setAgentStates] = useState([]);
  const [table, setTable] = useState(false);

  let headers = [
    { label: "Call Id", key: "callid" },
    { label: "Call From", key: "call_from" },
    { label: "Call To", key: "call_to" },
    { label: "Status", key: "status" },
    { label: "Caller Name", key: "callerName" },
    { label: "Duration", key: "duration" },
    { label: "Call Type", key: "callType" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Country Name", key: "countryName" },
    { label: "recordingUrl", key: "recordingUrl" },
  ];

  //fetch the data from the backend
  const fetchDataFromBackend = async () => {
    if (!startDateTime) {
      toast.error("select start datetime");
      return;
    }
    if (!endDateTime) {
      toast.error("select end datetime");
      return;
    }

    const data = await getCalls(startDateTime, endDateTime);

    if (data.success) {
      setTable(false);

      setTableData(data.data);
    } else {
      toast(data.error);
    }
  };

  const getAgentWiseData = async () => {
    handleSetTable();
    if (!startDateTime) {
      toast.error("select start date");
      return;
    }
    if (!endDateTime) {
      toast.error("select end date");
      return;
    }
    const data = await getCallerStatistics(startDateTime, endDateTime);

    if (data.success) {
      setAgentStates(data.data);
    } else {
      toast.error(data.error);
    }
  };

  const handleSetTable = () => {
    setTable(true);
  };

  const handleDownload = () => {
    if (!startDateTime || !endDateTime) {
      // Show a toast error
      toast.error("Please select both start and end dates");
      return;
    }

    document.getElementById("csv-link").click();
  };
  return (
    <div className="m-5">
      <Toaster />
      <div className="flex justify-between mb-4">
        <div>
          <span className="mx-2">From</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  placeholder="Select start date"
                  showTime
                  onChange={(_, datetime) => {
                    setStartDateTime(datetime);
                  }}
                />
              </Space>
            </ConfigProvider>
          </Space>
          <span className="mx-2">to</span>
          <Space direction="vertical">
            <ConfigProvider>
              <Space direction="vertical">
                <DatePicker
                  showTime
                  onChange={(_, datetime) => {
                    setEndDateTime(datetime);
                  }}
                  placeholder="Select end date"
                />
              </Space>
            </ConfigProvider>
          </Space>
          <button
            className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105"
            onClick={fetchDataFromBackend}
          >
            View
          </button>
          {tableData?.length > 0 && (
            <button
              className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
              onClick={handleDownload}
              disabled={tableData?.length > 0 ? false : true}
            >
              Download CSV
            </button>
          )}
          <button
            className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
            onClick={getAgentWiseData}
          >
            View Report
          </button>
        </div>

        <CSVLink
          id="csv-link"
          data={tableData}
          headers={headers}
          filename={`canada_data_${moment().format("YYYYMMDDHHmmss")}.csv`}
        />
      </div>
      <div className=" mx-2 p-4">
        {!table && <Table data={tableData} />}
        {table && <ReportTable data={agentStates} />}
      </div>
    </div>
  );
};

export default CallHippo;
